.pagination-bar{
    font-size: calc(14rem/16);
    border: 1px solid var(--color-grey);
    border-radius: calc(3rem/16);
    padding: calc(5rem/16) calc(20rem/16);
    margin-bottom: calc(30rem/16);

    @media screen and (min-width: 768px){
       height: auto;
    }
}

.pagination-bar select.form-control:not([size]):not([multiple]) {
    height: auto;
    padding-bottom: calc(3rem/16);
    padding-top: calc(3rem/16);
}