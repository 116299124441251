html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content in IE*/
}

body {
    position: relative;
}

body:after {
    content: '';
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
    background-color: #000;
    opacity: 0;
    transition: all 350ms ease;
    visibility: hidden;
}

body:before {
    content: '';
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2147483649;
    background-color: #000;
    opacity: 0;
    transition: all 350ms ease;
    visibility: hidden;
}

body.cart-open:before {
    visibility: visible;
    opacity: 0.5;
}

body.nav-open {
    @media screen and (max-width: 767px) {
        overflow: hidden;
    }
}

body.nav-open:after {
    opacity: 0.5;
    visibility: visible;
}

.nav-open [id^="trustbadge-container"]{
    display: none;
}
@media screen and (max-width: 767px) {
    body {
        min-width: calc(320rem / 16);
        max-width: 100%;
        overflow-x: hidden;
    }
}

.container.container {
    max-width: calc(1536rem / 16); /* 1496px container-breite + 40px padding */
    width: 100%;
}

.container-only-md {
    @media screen and (min-width: 768px) {
        max-width: calc(1536rem / 16); /* 1496px container-breite + 40px padding */
        width: 100%;
        margin: 0 auto;
        padding-left: calc(20rem / 16);
        padding-right: calc(20rem / 16);
    }
}

.container-medium {
    max-width: calc(1280rem / 16); /* 1240 container-breite + 40px padding */
    width: 100%;
    margin: 0 auto;
    padding-left: calc(20rem / 16);
    padding-right: calc(20rem / 16);

}

.container-narrow {
    max-width: calc(1025rem / 16); /* 985 container-breite + 40px padding */
    width: 100%;
    margin: 0 auto;
    padding-left: calc(20rem / 16);
    padding-right: calc(20rem / 16);

}

.container-small {
    max-width: calc(768rem / 16); /* 728 container-breite + 40px padding */
    width: 100%;
    margin: 0 auto;
    padding-left: calc(20rem / 16);
    padding-right: calc(20rem / 16);
}

.container-xsmall {
    max-width: calc(618rem / 16); /* 578 container-breite + 40px padding */
    width: 100%;
    margin: 0 auto;
    padding-left: calc(20rem / 16);
    padding-right: calc(20rem / 16);
}

.main-content {
    overflow: hidden;
    padding-top: calc(64rem / 16);

    @media screen and (min-width: 768px) {
        padding-top: calc(140rem / 16);
    }
}

.main-content--checkout {
    @media screen and (min-width: 768px) {
        padding-top: calc(150rem / 16);
    }
}

/* mobile gutter auf 20px */
@media screen and (max-width: 767px) {
    .row {
        margin-left: calc(-10rem / 16);
        margin-right: calc(-10rem / 16);
    }

    .row > [class^="col"],
    .row > [class*=" col"] {
        padding-left: calc(10rem / 16);
        padding-right: calc(10rem / 16);
    }
}
