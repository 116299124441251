@font-face {
    font-family: "iconfont";
    src: url('/cache-buster-1742307268896/static/build/icons/font/iconfont.eot?#iefix') format('eot'),
    url('/cache-buster-1742307268896/static/build/icons/font/iconfont.woff') format('woff'),
    url('/cache-buster-1742307268896/static/build/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-45 {transform: rotate(45deg);}
.icon-rotate-90 {
    transform: rotate(90deg);
}
.icon-rotate-180 {
    transform: rotate(180deg);
}
.icon-rotate-270 {
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none;
}

.icon-addthis:before { content: "\EA01" }
.icon-arrow-down:before { content: "\EA02" }
.icon-arrow-left:before { content: "\EA03" }
.icon-arrow-right:before { content: "\EA04" }
.icon-arrow:before { content: "\EA05" }
.icon-attention:before { content: "\EA06" }
.icon-bin:before { content: "\EA07" }
.icon-bonus:before { content: "\EA08" }
.icon-bus:before { content: "\EA09" }
.icon-calendar:before { content: "\EA0A" }
.icon-car:before { content: "\EA0B" }
.icon-career-clock:before { content: "\EA0C" }
.icon-cart-warning:before { content: "\EA0D" }
.icon-cart:before { content: "\EA0E" }
.icon-celebration:before { content: "\EA0F" }
.icon-check:before { content: "\EA10" }
.icon-circle-filled:before { content: "\EA11" }
.icon-clock:before { content: "\EA12" }
.icon-close:before { content: "\EA13" }
.icon-commodity-group:before { content: "\EA14" }
.icon-compare:before { content: "\EA15" }
.icon-consulting:before { content: "\EA16" }
.icon-customercard:before { content: "\EA17" }
.icon-delivery:before { content: "\EA18" }
.icon-download-intranet:before { content: "\EA19" }
.icon-download:before { content: "\EA1A" }
.icon-dragndrop:before { content: "\EA1B" }
.icon-elements:before { content: "\EA1C" }
.icon-exit:before { content: "\EA1D" }
.icon-eye:before { content: "\EA1E" }
.icon-facebook:before { content: "\EA1F" }
.icon-filter:before { content: "\EA20" }
.icon-go-to:before { content: "\EA21" }
.icon-graduate-hat:before { content: "\EA22" }
.icon-heart-outline:before { content: "\EA23" }
.icon-heart:before { content: "\EA24" }
.icon-house:before { content: "\EA25" }
.icon-ic_arrows-split:before { content: "\EA26" }
.icon-ic_clickcollect:before { content: "\EA27" }
.icon-ic_consulting:before { content: "\EA28" }
.icon-ic_delivery:before { content: "\EA29" }
.icon-ic_garantie:before { content: "\EA2A" }
.icon-ic_geldsparen:before { content: "\EA2B" }
.icon-ic_kabel:before { content: "\EA2C" }
.icon-ic_leaf-settings:before { content: "\EA2D" }
.icon-ic_power:before { content: "\EA2E" }
.icon-ic_shield-check:before { content: "\EA2F" }
.icon-info:before { content: "\EA30" }
.icon-instagram:before { content: "\EA31" }
.icon-intranet-logo:before { content: "\EA32" }
.icon-jobs:before { content: "\EA33" }
.icon-joe:before { content: "\EA34" }
.icon-lamp:before { content: "\EA35" }
.icon-link:before { content: "\EA36" }
.icon-linkedin:before { content: "\EA37" }
.icon-location:before { content: "\EA38" }
.icon-logout:before { content: "\EA39" }
.icon-magnifier:before { content: "\EA3A" }
.icon-mail:before { content: "\EA3B" }
.icon-menu:before { content: "\EA3C" }
.icon-minus:before { content: "\EA3D" }
.icon-mobile:before { content: "\EA3E" }
.icon-newsletter:before { content: "\EA3F" }
.icon-oe:before { content: "\EA40" }
.icon-package:before { content: "\EA41" }
.icon-pause:before { content: "\EA42" }
.icon-pdf:before { content: "\EA43" }
.icon-pen-outline:before { content: "\EA44" }
.icon-pen:before { content: "\EA45" }
.icon-percent:before { content: "\EA46" }
.icon-phone-store:before { content: "\EA47" }
.icon-phone:before { content: "\EA48" }
.icon-pin:before { content: "\EA49" }
.icon-pinterest:before { content: "\EA4A" }
.icon-play-big:before { content: "\EA4B" }
.icon-play:before { content: "\EA4C" }
.icon-plus:before { content: "\EA4D" }
.icon-poi:before { content: "\EA4E" }
.icon-print:before { content: "\EA4F" }
.icon-product:before { content: "\EA50" }
.icon-profile:before { content: "\EA51" }
.icon-register:before { content: "\EA52" }
.icon-release:before { content: "\EA53" }
.icon-sheet:before { content: "\EA54" }
.icon-shield-lock:before { content: "\EA55" }
.icon-star-outline:before { content: "\EA56" }
.icon-star:before { content: "\EA57" }
.icon-team:before { content: "\EA58" }
.icon-three-sixty:before { content: "\EA59" }
.icon-twitter:before { content: "\EA5A" }
.icon-warning:before { content: "\EA5B" }
.icon-whatsapp:before { content: "\EA5C" }
.icon-xing:before { content: "\EA5D" }
.icon-youtube:before { content: "\EA5E" }


:root {
--icon-addthis: "\EA01";
    --icon-arrow-down: "\EA02";
    --icon-arrow-left: "\EA03";
    --icon-arrow-right: "\EA04";
    --icon-arrow: "\EA05";
    --icon-attention: "\EA06";
    --icon-bin: "\EA07";
    --icon-bonus: "\EA08";
    --icon-bus: "\EA09";
    --icon-calendar: "\EA0A";
    --icon-car: "\EA0B";
    --icon-career-clock: "\EA0C";
    --icon-cart-warning: "\EA0D";
    --icon-cart: "\EA0E";
    --icon-celebration: "\EA0F";
    --icon-check: "\EA10";
    --icon-circle-filled: "\EA11";
    --icon-clock: "\EA12";
    --icon-close: "\EA13";
    --icon-commodity-group: "\EA14";
    --icon-compare: "\EA15";
    --icon-consulting: "\EA16";
    --icon-customercard: "\EA17";
    --icon-delivery: "\EA18";
    --icon-download-intranet: "\EA19";
    --icon-download: "\EA1A";
    --icon-dragndrop: "\EA1B";
    --icon-elements: "\EA1C";
    --icon-exit: "\EA1D";
    --icon-eye: "\EA1E";
    --icon-facebook: "\EA1F";
    --icon-filter: "\EA20";
    --icon-go-to: "\EA21";
    --icon-graduate-hat: "\EA22";
    --icon-heart-outline: "\EA23";
    --icon-heart: "\EA24";
    --icon-house: "\EA25";
    --icon-ic_arrows-split: "\EA26";
    --icon-ic_clickcollect: "\EA27";
    --icon-ic_consulting: "\EA28";
    --icon-ic_delivery: "\EA29";
    --icon-ic_garantie: "\EA2A";
    --icon-ic_geldsparen: "\EA2B";
    --icon-ic_kabel: "\EA2C";
    --icon-ic_leaf-settings: "\EA2D";
    --icon-ic_power: "\EA2E";
    --icon-ic_shield-check: "\EA2F";
    --icon-info: "\EA30";
    --icon-instagram: "\EA31";
    --icon-intranet-logo: "\EA32";
    --icon-jobs: "\EA33";
    --icon-joe: "\EA34";
    --icon-lamp: "\EA35";
    --icon-link: "\EA36";
    --icon-linkedin: "\EA37";
    --icon-location: "\EA38";
    --icon-logout: "\EA39";
    --icon-magnifier: "\EA3A";
    --icon-mail: "\EA3B";
    --icon-menu: "\EA3C";
    --icon-minus: "\EA3D";
    --icon-mobile: "\EA3E";
    --icon-newsletter: "\EA3F";
    --icon-oe: "\EA40";
    --icon-package: "\EA41";
    --icon-pause: "\EA42";
    --icon-pdf: "\EA43";
    --icon-pen-outline: "\EA44";
    --icon-pen: "\EA45";
    --icon-percent: "\EA46";
    --icon-phone-store: "\EA47";
    --icon-phone: "\EA48";
    --icon-pin: "\EA49";
    --icon-pinterest: "\EA4A";
    --icon-play-big: "\EA4B";
    --icon-play: "\EA4C";
    --icon-plus: "\EA4D";
    --icon-poi: "\EA4E";
    --icon-print: "\EA4F";
    --icon-product: "\EA50";
    --icon-profile: "\EA51";
    --icon-register: "\EA52";
    --icon-release: "\EA53";
    --icon-sheet: "\EA54";
    --icon-shield-lock: "\EA55";
    --icon-star-outline: "\EA56";
    --icon-star: "\EA57";
    --icon-team: "\EA58";
    --icon-three-sixty: "\EA59";
    --icon-twitter: "\EA5A";
    --icon-warning: "\EA5B";
    --icon-whatsapp: "\EA5C";
    --icon-xing: "\EA5D";
    --icon-youtube: "\EA5E";
    
}

:root{
    --icon-arrow-left: "\EA03";
    --icon-arrow-right: "\EA04";
    --icon-close: "\EA13";
}