.cart-joe-banner-simple {
    border-radius: calc(3rem/16);
    background-color: var(--color-joe);
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 768px) {
        flex-direction: row;
    }
}

.cart-joe-banner-simple__content {
    padding: calc(20rem/16) 0 calc(20rem/16) calc(20rem/16);
    color: var(--color-white);

    @media screen and (min-width: 768px) {
        padding: calc(24rem/16) 0 calc(24rem/16) calc(32rem/16);
    }
}

.cart-joe-banner-simple__headline {
    font-family: var(--font-default-medium);
    font-size: calc(18rem/16);
    line-height: calc(22rem/18);
    margin-bottom: calc(4rem/16);
    
    @media screen and (min-width: 768px) {
        font-size: calc(20rem/16);
        line-height: calc(24/20);
    }
}

.cart-joe-banner-simple__btn {
    padding: calc(10rem/16) calc(28rem/16);
    background-color: var(--color-white);
    color: var(--color-default);
    border-radius: calc(3rem/16);
    margin-top: calc(4rem/16);
}

.cart-joe-banner-simple__img {
    position: relative;

    & img {
        border-radius: calc(3rem/16);
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100%);
        background: linear-gradient(0deg, rgba(221,182,103,0) 50%, var(--color-joe) 100%);
    }

    @media screen and (min-width: 768px) {
        &:after {
            background: linear-gradient(-90deg, rgba(221,182,103,0) 50%, var(--color-joe) 100%);
        }
    }
}

.cart-joe-banner-simple--no-img .cart-joe-banner-simple__content {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-areas: 'info icon'
                        'btn btn';
    gap: calc(20rem/16) calc(30rem/16);
    width: 100%;
    padding-right: calc(20rem/16);

    @media screen and (min-width: 768px) {
        grid-template-columns: repeat(3, auto);
        grid-template-areas: 'icon info btn';
        align-items: center;
        gap: calc(28rem/16);
        padding: calc(20rem/16) calc(32rem/16);
    }
}

.cart-joe-banner-simple--no-img .cart-joe-banner-simple__info {
    grid-area: info;
}

.cart-joe-banner-simple--no-img .cart-joe-banner-simple__icon {
    grid-area: icon;
    font-size: calc(50rem/16);
    margin-left: auto;

    @media screen and (min-width: 768px) {
        font-size: calc(80rem/16);
        margin-left: 0;
    }
}

.cart-joe-banner-simple--no-img .cart-joe-banner-simple__btn {
    grid-area: btn;
}

.cart-joe-banner-simple--no-img .cart-joe-banner-simple__text {
    margin-bottom: 0;
}