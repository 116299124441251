.top-brand-area {
    margin-bottom: 3rem;
}
.top-brand__item {
    border: 2px solid #efefef;
    border-radius: 3px;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(106rem/16);
}
.top-brand__item:hover {
    border-color: #ffed00;
    border-color: var(--color-primary);
}