.product-main{
    @media screen and (min-width: 768px){
       font-size: calc(16rem/16);
    }
}

.product-main__title{
    @media screen and (min-width: 768px){
       margin-top: calc(15rem/16);
    }
}

.product-main__brand-img{
    max-height: calc(24rem/16);

}

.product-detail-swiper__nav-icon{
    font-size: calc(40rem/16);
}

.product-main__desc-list{
    padding-left: calc(18rem/16);
    margin-top: calc(15rem/16);
}

.product-main__desc-details{
    @media screen and (max-width: 767px){
       font-size: calc(16rem/16);
        margin-bottom: calc(6rem/16);
        display: inline-block;
    }
}


.product-main__additional-info{
    margin-top: calc(30rem/16);
    font-size: calc(14rem/16);
    @media screen and (min-width: 1050px){
        margin-top: calc(32rem/16);
    }
}

.product-main__additional-info > li+li{
    margin-top: calc(10rem/16);
}

.product-main__additional-info .icon{
    vertical-align: calc(-3rem/16);
    font-size: calc(17rem/16);
}

.product-main .price-badge__price{
    @media screen and (min-width: 1200px){
       font-size: calc(48rem/16);
    }

    font-size: calc(40rem/16);

}

.product-main .price-badge__price-cent {
    font-size:  calc(20rem/16);
    top: calc(-14rem/16);
    @media screen and (min-width: 1200px){
        font-size:  calc(23rem/16);
        top: calc(-18rem/16);
    }
}

.product-main__small-info{
    font-size: calc(11rem/16);
    margin-top: calc(22.5rem/16);
    line-height: 1;

    @media screen and (min-width: 768px){
        font-size: calc(14rem/16);
    }
}

.product-main__open-info{
    padding-left: calc(5rem/16);
}

.product-main__open-info .icon-in-text-equal{
    font-size: calc(14rem/16);
    width: calc(13rem/16);
}

.product-main__open-info-text{
    font-size: calc(13rem/16);
    line-height: 1.1;
}

.product-main .store-select-wrapper{
    padding-left: calc(30rem/16);
}

.product-main__amount{
    
    @media screen and (min-width: 768px){
       margin-right: calc(20rem/16);
    }

    @media screen and (min-width: 1400px){
        margin-right: calc(40rem/16);
    }
}

.product-main__badge-list>li.list-inline-item{
    margin-top: calc(5rem/16);
    @media screen and (max-width: 767px){
        margin-right: 0;
        margin-top: 0;
        margin-bottom: calc(5rem/16);

    }
}

.product-main__badge-list img{
    @media screen and (min-width: 768px){
       max-width: calc(60rem/16);
    }
}

.product-main__price-wrapper{
    display: flex;
    align-items: flex-end;
    @media screen and (max-width: 1399px){
       flex-direction: column-reverse;
    }
}

.product-main__price-wrapper .product-main__small-info{
    margin-top: calc(3rem/16);

    @media screen and (min-width: 768px){
        margin-top: calc(8rem/16);
    }

    @media screen and (min-width: 1400px){
       margin-right: calc(10rem/16);
        margin-top: 0;
    }
}

.product-main__hl{
    font-family: var(--font-default-bold);
    font-size: calc(18rem/16);
}

.product-main__bottom{
    margin-top: calc(25rem/16);

    @media screen and (min-width: 1050px){
        margin-top: calc(48rem/16);
    }
}

.product-main__action-btn{
    margin-left: calc(5rem/16);

}

.product-main__action-icon{
    width: calc(30rem/16);
    height: calc(30rem/16);
    border-radius: 50%;
    background-color: var(--color-grey);
    font-size: calc(16rem/16);
    display: inline-flex;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 768px){
        width: calc(32rem/16);
        height: calc(32rem/16);
    }
}

.is-active .product-main__action-btn .product-main__action-icon{
    background-color: var(--color-primary);
    color: var(--color-secondary);
}


.product-main__action-icon .icon-heart-outline{
    font-size: calc(13rem/16);
}

.is-active .product-main__action-icon .icon-heart-outline:before{
    content: var(--icon-heart);
}

.product-main__action-icon:hover{
    background-color: var(--color-primary);
}

.product-main__action-icon.facebook{
    background-color: #4267B2;
    color: #fff;
}

.product-main__action-list{
    margin-top: calc(20rem/16);

    @media screen and (min-width: 768px){
        margin-top: calc(40rem/16);
    }

}

.product-main__action-list>li{
    margin-bottom: calc(15rem/16);
}

.product-main__action-list .list-inline-item:not(:last-child){
    margin-right: calc(6rem/16);

    @media screen and (min-width: 768px){
        margin-right: calc(40rem/16);
    }
}

.product-main__set{
    margin-bottom: 2rem;
}

.product-main__set-button{
    margin-top: 0.75rem;
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: var(--font-block);
    text-transform: uppercase;
    font-size: calc(20rem/16);
    padding: .44rem 1.25rem;

    @media screen and (max-width: 767px){
        width: 100%;
        font-size: calc(16rem/16);
    }
}

.product-main__set .dropdown-menu{
    width: 50%;

    @media screen and (max-width: 767px){
        width: 100%;
        padding: 1rem 0;
    }
}

.product-main__set-button__icon{
    font-size: 0.5rem;
}

.product-main__set.product-main__set--variant .dropdown-menu{
    width: 100%;
}

.product-main__set--variant-btn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (min-width: 768px){
       width: auto;
    }
}

.product-main__set--variant .dropdown-menu{
    padding: 15px;
    
    @media screen and (max-width: 767px){
        width: 100%;
    }
}

.product-main__set .dropdown-item {
    white-space: normal;
}

.product-main__set--variant .dropdown-item{
    padding-left: calc(10rem/16);
    padding-right: calc(10rem/16);
    font-size: calc(13rem/16);
    white-space: normal;
}


.product-main__set--variant .product-main__set-button__icon{
    margin-left: calc(10rem/16);
}

.manufacturer-tab{
    display: none;
}

.manufacturer-tab.show{
    display: list-item;
}

.product-main__dynamic-price{
    padding-right: 1.25rem;
    padding-left: 0;
    width: 50%;
    @media(max-width: 767px){
        padding-right: 0;
        width: 100%;
    }
}