.img-quote-area__content {
    background-color: var(--color-primary);
    padding: calc(119rem/16) calc(20rem/16) calc(60rem/16) calc(20rem/16);
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}
.img-quote-area__content.img-quote-area__content--padding-top-sm{
    padding-top: calc(60rem/16);
}
.img-quote-area__wysiwyg {
    font-size: calc(14rem/16);
    line-height: calc(21/14);
    font-family: var(--font-default-medium);
    padding-bottom: calc(30rem/16);
}
.img-quote-area__text-container {
    padding-top: calc(13rem/16);
    border-top: 1px solid #2A292A;
}
.img-quote-area__text {
    font-family: var(--font-default);
    font-size: calc(14rem/16);
    line-height: calc(21/14);
}
.img-quote-area__text--light {
    color: #949494;
    margin-left: calc(10rem/16);
}
.img-quote-area__img-container-round {
    border: 4px solid var(--color-primary);
    border-radius: 50%;
    width: calc(158rem/16);
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
}
.img-quote-area__img--round {
    border: 4px solid var(--color-primary);
    border-radius: 50%;
}
.img-quote-area__img{
    height: 100%;
}
.img-quote-area__img-img{
    height: 100%;
    object-fit: cover;
}
@media (min-width: 768px){
    .img-quote-area__grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        max-height: calc(540rem/16);
    }
    .img-quote-area__content {
        padding: calc(32rem/16) calc(64rem/16) calc(32rem/16) calc(127rem/16);
    }
    .img-quote-area__content.img-quote-area__content--padding-top-sm{
        padding: calc(32rem/16)  calc(64rem/16);
    }
    .img-quote-area__wysiwyg {
        font-size: calc(16rem/16);
        line-height: calc(27/16);
        padding-bottom: calc(40rem/16);
    }
    /*.img-quote-area__text-container {*/
    /*    margin-top: calc(10rem/16);*/
    /*}*/
    .img-quote-area__text {
        font-size: calc(16rem/16);
        line-height: calc(27/16);
    }
    .img-quote-area__img-container-round {
        top: 50%;
        left: 0;
    }
    .img-quote-area__img-container{
        background: var(--color-primary);
    }
}
@media (min-width: 1100px){
    .img-quote-area__img-container-round {
        top: 50%;
        width: calc(216rem/16);
    }
}
@media (min-width: 1700px){
    .img-quote-area__content {
        padding: calc(16rem/16) calc(276rem/16);
    }
    .img-quote-area__content.img-quote-area__content--padding-top-sm{
        padding: calc(80rem/16)  calc(276rem/16);
    }
}
.img-quote-area__img-container--bg-primary{
    background-color: var(--color-primary);
}