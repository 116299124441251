.slide {
    padding-top: calc(30rem/16);
    padding-bottom: calc(30rem/16);

    @media screen and (min-width: 768px){
        padding-top: calc(50rem/16);
        padding-bottom: calc(50rem/16);
    }
}

.slide-content-block {
    margin-top: calc(30rem/16);
    margin-bottom: calc(30rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(80rem / 16);
        margin-bottom: calc(80rem / 16);
    }
}
.pimcore_area_content + .pimcore_area_content > .slide-content-block-sm,
.slide-content-block-sm {
    margin-top: calc(30rem/16);
    margin-bottom: calc(30rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(60rem / 16);
        margin-bottom: calc(60rem / 16);
    }
}


/* slides with background color / image */
.slide-content-block-sm[class*='bg-'] {
    margin: 0;
    padding-top: calc(30rem / 16);
    padding-bottom: calc(30rem / 16);
    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (min-width: 768px) {
        padding-top: calc(60rem / 16);
        padding-bottom: calc(60rem / 16);
    }
}

.slide-content-block[class*='bg-'] {
    margin: 0;
    padding-top: calc(30rem / 16);
    padding-bottom: calc(30rem / 16);
    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (min-width: 768px) {
        padding-top: calc(80rem / 16);
        padding-bottom: calc(80rem / 16);
    }
}