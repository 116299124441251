.benefits-list--multicol>li {
    text-align: left;
    /* bugy! */
    /*columns: 2;
    column-gap:2rem;*/

    @media screen and (min-width: 1200px){
        float:left;
        width:50%;
        margin-left:0;
        margin-right:0;
    }
}
.benefits-list--multicol-space>li:nth-child(even) {
    @media screen and (min-width: 768px) {
        margin-left: calc(20rem/16);
        width: calc(50% - 20rem/16);
    }
}
.benefits-list--multicol:after {
    content:'';
    display: block;
    clear: both;
}
.benefits-list>li {
    position: relative;
    padding-left:calc(40rem/16);
    margin-bottom:.875rem;
}
.benefits-list>li:before {
    content:'';
    display: block;
    width: calc(30rem/16);
    height: calc(30rem/16);
    border-radius: 100%;
    background: var(--color-primary);
    position: absolute;
    left:0;
    top:calc(-6rem/16);
}
.benefits-list>li:after {
    content:'';
    width:calc(10rem/16);
    height:calc(6rem/16);
    border:2px solid #000;
    border-width:2px 2px 0 0;
    transform: rotate(131deg);
    position: absolute;
    left:calc(10rem/16);
    top:calc(4rem/16);
    z-index: 1;
}
.benefits-list>li.benefits-joe-item:before {
    content: var(--icon-joe);
    font-family: 'iconfont';
    font-size: calc(30rem/16);
    color: var(--color-joe);
    line-height: 1;
    background: none;
}
.benefits-list>li.benefits-joe-item:after {
    content: none;
}