.range-slider .noUi-target {
    background: var(--color-grey);
    border-radius: 0;
    border: none;
    box-shadow: none;
}

.range-slider .noUi-horizontal {
    height: calc(5rem/16);
    border-radius: calc(3rem/16);
}

.range-slider .noUi-connect{
    background: var(--color-secondary);
}

.range-slider .noUi-horizontal .noUi-handle {
    width: calc(20rem/16);
    height: calc(20rem/16);
    border-radius: 50%;
    border: none;
    background-color: var(--color-primary);
    box-shadow: 0 0 10px 0 rgba(42,41,42,0.15);
    right: -10px;
    top: -8px;
}

.range-slider .noUi-tooltip{
    border: none;
    font-size: calc(14rem/16);
}

.range-slider .noUi-handle:before,
.range-slider .noUi-handle:after{
    display: none;
}

/*
.range-slider .noUi-horizontal .noUi-handle {
    width: calc(19rem/16);
    height: calc(19rem/16);
    border-radius: 50%;
    left: -17px;
    top: -6px;
    border: calc(2rem/16) solid #3D3D3D;
    box-shadow: none;
}

.range-slider .noUi-handle:after,
.range-slider .noUi-handle:before{
    display: none;
}

.range-slider__values{
    color: var(--color-light-grey);
    margin-top: calc(10rem/16);
}*/


.form-control.range-slider__min,
.form-control.range-slider__max{
    max-width: calc(70rem/16);
    margin-top: calc(24rem/16);
    height: 2.5rem;
    padding-top: 0;
    padding-bottom: 0;
}

.range-slider__max{
    text-align: right;
}

input.range-slider__min::-webkit-outer-spin-button,
input.range-slider__min::-webkit-inner-spin-button,
input.range-slider__max::-webkit-inner-spin-button,
input.range-slider__max::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input.range-slider__min[type=number],
input.range-slider__max[type=number]{
    -moz-appearance: textfield;
}