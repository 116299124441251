.cart-overlay{
    background-color: #fff;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 1.25rem;
    z-index: 2147483648!important; /* because of trust badge */
    transform: translateX(100%);
    transition: all 250ms ease;
    padding-top: 0;
    overflow-y: auto;
    display: block;
    @media screen and (min-width: 768px){
        left: auto;
       width: calc(520rem/16);
        padding-top: calc(20rem/16);
    }
}

.cart-overlay.is-open{
    transform: translateX(0);
}

.cart-overlay__close-btn{
    font-size: calc(16rem/16);
    margin-top: calc(6rem/16);
}

.cart-overlay__body{
    padding: calc(20rem/16);

    @media screen and (min-width: 768px){
        padding: calc(40rem/16);
    }
}

.cart-overlay__title{
    font-size: calc(20rem/16);
    font-family: var(--font-default-bold);
    display: flex;
    align-items: center;
}

.cart-overlay__counter__circle{
    width: calc(23rem/16);
    height: calc(23rem/16);
    background-color: var(--color-secondary);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: var(--color-primary);
    font-family: var(--font-default);
    font-size: 14px;
    margin-left: calc(10rem/16);
}

.cart-overlay__counter{
    transform: translateX(calc(-0.2rem/16));
}

.cart-overlay__hl{
    font-size: calc(16rem/16);
    font-family: var(--font-default-bold);
    padding-bottom: calc(3rem/16);
    border-bottom: 1px solid var(--color-grey);
    margin-bottom: calc(10rem/16);
}

.cart-product-list>li:not(:last-child){
    margin-bottom: calc(10rem/16);
}

.cart-overlay__block+.cart-overlay__block{
    margin-top: calc(30rem/16);

    @media screen and (min-width: 768px){
        margin-top: calc(40rem/16);
    }
}

.cart-overlay__summary{
    margin-top: calc(35rem/16);

    @media screen and (min-width: 768px){
        margin-top: calc(55rem/16);
    }
}

.cart-overlay__footer{
    padding: calc(40rem/16) calc(64rem/16);
    background-color: var(--color-light-grey);
}

.cart-overlay__footer .btn+.btn{
    margin-top: calc(20rem/16);
}

.cart-overlay__progress {
    border:1px solid var(--color-grey);
    border-radius:calc(3rem/16);
    padding:calc(6rem/16) calc(20rem/16);
    font-family:var(--font-default-bold);
    font-size:calc(12rem/16);
    text-align:center;
    margin-top:calc(20rem/16);
    display:block;
    text-decoration:none;
}

.cart-overlay__progress span.green {
    color:var(--color-green);
}

.cart-overlay__progress .icon {
    font-size:calc(10rem/16);
    margin-right:5px;
    vertical-align:middle;
}