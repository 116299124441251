.product-order-item{
    margin: 0;
    @media screen and (max-width: 767px){
       font-size: calc(14rem/16);
        padding: calc(15rem/16);
    };
}

.product-order-item .cart-table-item__total-price{
    @media screen and (min-width: 768px){
        margin-top: 0;
    }
}

.product-order-item__hl{
    font-size: calc(14rem/16);
    margin-bottom: calc(8rem/16);
    @media screen and (min-width: 768px){
       display: block;
    }
}

.list-product-order-items>li{
    margin-bottom: calc(20rem/16);
}