.cart-product-teaser{
    border: 1px solid var(--color-grey);
    border-radius: calc(3rem/16);
    
    @media screen and (max-width: 767px){
       font-size: calc(14rem/16);
    }
}

.cart-product-teaser.is-highlight{
    border-color: var(--color-primary);
}

.cart-product-teaser__body{
    padding: calc(16rem/16) 0;

    @media screen and (min-width: 768px){
        padding: calc(16rem/16);
    }
}

.cart-product-teaser__small-info{
    font-size: calc(12rem/16);
}

.cart-product-teaser__title{
    line-height: calc(16/14);
}

.cart-product-teaser__title--bold {
    font-family: var(--font-default-bold);
}

.cart-product-teaser__description {
    color: var(--color-dark-grey);
    line-height: 1.1;
    margin-top: calc(8rem/16);
}

.cart-product-teaser__img{
    padding: calc(12rem/16);
}

.cart-product-teaser--additional{
    @media screen and (max-width: 767px){
       padding: 0 calc(16rem/16);
    }
}

.cart-product-teaser__price-instead {
    font-family: var(--font-block);
    font-size: calc(13rem/16);
}
.cart-product-teaser__price-instead--bigger {
    font-size: calc(16rem/16);
}
.cart-product-teaser__price-info-text {
    font-size: calc(12rem/16);
    margin-top: calc(2rem/16);
}