.consent-overlay__wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    width: 100%;
    transform: translate(-50%, -50%);
    background-size: cover;
    background-repeat: no-repeat;
}

.consent-overlay__overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: 100%;
    justify-content: center;
    gap: calc(16rem/16);
    background: rgba(255, 255, 255, 0.8);
}

.consent-overlay__wrapper--image .consent-overlay__overlay {
    background: rgba(255, 255, 255, 0.4);
}

.consent-overlay__text {
    width: 99%;
}

/* store finder input overlay */
.store-locator__header .consent-overlay__wrapper {
    position: relative;
    top: 0;
    left: 0;
    height: auto;
    width: auto;
    transform: none;
}

.store-locator__header .consent-overlay__overlay {
    text-align: left;
    background: none;

    @media screen and (min-width: 768px) {
        display: grid;
        grid-template-columns: 2fr 1fr;
    }
}

.store-locator__header .consent-overlay__text {
    position: relative;
    width: 100%;
    background-color: var(--color-white);
    padding: calc(4rem/16) calc(8rem/16) calc(4rem/16) calc(40rem/16);

    &:after {
        content: var(--icon-poi);
        font-family: 'iconfont';
        font-size: calc(15rem/16);
        position: absolute;
        left: calc(8rem/16);
        top: 50%;
        transform: translateY(-50%);
    }
    
    @media screen and (min-width: 768px) {
        &:after {
          font-size: calc(17rem/16);
        }
    }
}

.store-locator__header .consent-overlay__overlay .btn {
    background-color: var(--color-default);
    color: var(--color-primary);
    border-color: var(--color-default);
    border-radius: calc(3rem/16);
    font-size: calc(14rem/16);
}