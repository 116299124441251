.navbar-parent{
    z-index: 50;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    @media screen and (min-width: 768px){
        position: absolute;
        z-index: 5000; /* because of trust badge */
    }
}

.modal-open .navbar-parent{
    @media screen and (min-width: 768px){
        z-index: 1000!important;
    }
}

.navbar-parent.is-affix{
    position: fixed;
}

.navbar-main{
    background-color: var(--color-primary);
    box-shadow: 0 0 40px 0 rgba(42,41,42,0.2);
    height: calc(64rem/16);
    position: relative;
    z-index: 100;
    left: 0;
    right: 0;
    
    @media screen and (min-width: 768px){
       height: calc(80rem/16);
        padding-right: 0;
        padding-left: 0;
        background-color: #fff;
    }
}

.navbar-checkout{
    background-color: var(--color-primary);
    height: calc(64rem/16);
    position: relative;
    z-index: 100;
    left: 0;
    right: 0;

    @media screen and (min-width: 768px){
        position: fixed;
        height: calc(80rem/16);
        padding-right: 0;
        padding-left: 0;
        font-size: calc(16rem/16);
    }
}

/*.navbar-main.affix{
    @media screen and (min-width: 768px){
        top: calc(0rem/16)!important;
    }
}*/

.navbar-brand{
    @media screen and (min-width: 768px){
        margin-right: calc(10rem/16);
    }

    @media screen and (min-width: 1200px){
       margin-right: calc(25rem/16);
    }

    @media screen and (min-width: 1400px){
        margin-right: calc(85rem/16);
    }
    
    @media screen and (max-width: 767px){
       margin-right: 0;
    }
}

.navbar-brand img, .navbar-brand svg{
    @media screen and (min-width: 768px) and (max-width: 1199px){
        height: calc(25rem/16);
    }
    
    @media screen and (max-width: 767px){
       height: calc(20rem/16);
    }
}


.navbar-search__form .form-control{
    height: calc(40rem/16);
    padding-right: calc(32rem/16);
    padding-left: calc(11rem/16);
    color: var(--color-dark-grey);
    font-size: calc(14rem/16);

    @media screen and (min-width: 768px){
        height: calc(32rem/16);
        border-color: transparent;
    }

}

.navbar-search__icon{
    position: absolute;
    right: calc(11rem/16);
    cursor: pointer;
    color: var(--color-dark-grey);
    top: 50%;
    transform: translateY(-50%);
}

.navbar-search__remove{
    position: absolute;
    right: 32px;
    font-size: 10px;
    color: var(--color-dark-grey);
    cursor: pointer;
}

.navbar-nav{
    @media screen and (max-width: 767px){
       display: block;
    }
}

.navbar-expand-md .navbar-nav .nav-link-main{
    @media screen and (min-width: 768px){
        padding: calc(28rem/16) clamp(10px, 1.25vw, 18px);
        font-size: clamp(14px, 1.2vw, 16px);
        position: relative;
        z-index: 5;
        overflow: hidden;
        font-family: var(--font-default);
        color: var(--color-default);
    }

    @media screen and (min-width: 1250px){
        /*padding: calc(28rem/16) calc(28rem/16);*/
        /*font-size: calc(15rem/16);*/
        padding: calc(28rem/16) clamp(18px, 1.8vw, 34px);
    }
}

.nav-link-main:after{
    @media screen and (min-width: 768px){
        content: '';
        position: absolute;
        bottom: 0;
        left: calc(16rem/16);
        right: calc(16rem/16);
        height: calc(2rem/16);
        background-color: var(--color-default);
        transform: translateY(100%);
        transition: all 250ms ease;
    }
}
.navbar-expand-md .navbar-nav .nav-link-main:focus{
    outline: none;
}

.nav-item .nav-link-main:after{ background-color: var(--color-primary-dark); }


.navbar-nav .nav-item:hover .nav-link-main:after,
.navbar-nav .nav-item.active .nav-link-main:after{
    display: block;
    transform: translateY(0);
}

.nav-item--btn {
    padding: calc(21rem/16) 0;
    display: inline-block;

    @media screen and (min-width: 768px){
        display: block;
        padding: calc(28rem/16) calc(9rem/16);
    }
}

.nav-item--btn +.nav-item--btn {
    margin-left: calc(5rem/16);

    @media screen and (min-width: 1250px){
        margin-left: calc(10rem/16);
    }
}

.nav-link__btn {
    padding: calc(12rem/16) calc(22rem/16);
    border-radius: calc(4rem/16);
    font-size: calc(16rem/16);
    transition: all 150ms ease;


    @media screen and (min-width: 768px){
        padding: calc(9rem/16) calc(11rem/16);;
        font-size: clamp(14px, 1.2vw, 16px);    
    }
    
    @media screen and (min-width: 1300px){
        padding: calc(10rem/16) calc(20rem/16);
    }
}

.navbar-dropdown-item{
    display: flex;
    align-items: center;
}

.navbar-dropdown-item+.navbar-dropdown-item{
    margin-top: calc(20rem/16);
}


.navbar-dropdown-item__circle{
    width: calc(32rem/16);
    min-width: calc(32rem/16);
    height: calc(32rem/16);
    color: var(--color-secondary);
    background-color: var(--color-grey);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: calc(14rem/16);
    margin-right: calc(10rem/16);
    position: relative;
}

.navbar-dropdown-item__joe{
    color: var(--color-joe);
    display: inline-flex;
    font-size: calc(32rem/16);
    margin-right: calc(10rem/16);
    position: relative;
}
.navbar-dropdown-item__count{
    width: calc(14rem/16);
    height: calc(14rem/16);
    border-radius: 50%;
    background-color: var(--color-secondary);
    color: var(--color-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: calc(-3rem/16);
    right: calc(-3rem/16);
    font-size: calc(10rem/16);
}

/* navbar-right */
.navbar-right{
    margin-bottom: 0;
}
.navbar-right .list-inline-item:not(:last-child){
    margin-right: calc(3rem/16);

    @media screen and (min-width: 1400px){
        margin-right: calc(15rem/16);
    }
}

.navbar-right__cart-count{
    width: calc(18rem/16);
    height: calc(18rem/16);
    border-radius: 50%;
    background: var(--color-default);
    color: var(--color-primary);
    font-size: calc(10rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    position: absolute;
    bottom: calc(-3rem/16);
    right: calc(-5rem/16);
}

.navbar-right__joe{
    width: calc(18rem/16);
    height: calc(18rem/16);
    border-radius: 50%;
    background: var(--color-white);
    color: var(--color-joe);
    font-size: calc(18rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    position: absolute;
    bottom: calc(-3rem/16);
    right: calc(-5rem/16);
}

.navbar-right .navbar-dropdown-item.disabled{
    cursor: not-allowed;
    pointer-events: none;
    color: var(--color-dark-grey);
}
.navbar-right .navbar-dropdown-item.disabled .navbar-dropdown-item__circle{
   opacity: 0.5;
}

.navbar-right .navbar-dropdown-item.disabled .tooltip-btn{
    pointer-events: auto;
    cursor: pointer;
}

.navbar-left{
    width: calc(12rem/16);
    height: calc(12rem/16);
    border-radius: 50%;
    background: var(--color-success);
    font-size: calc(15rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0;
    position: absolute;
    bottom: calc(-1rem/16);
    left: calc(0.5rem/16);
}

@media screen and (max-width: 767px){

    .navbar-title{
        text-transform: uppercase;
        color: var(--color-text-muted);
        font-family: var(--font-default-bold);
        margin-bottom: calc(5rem/16);
    }

    .navbar-toggler{
        padding: 0;
        width: calc(40rem/16);
        height: calc(40rem/16);
        background-color: transparent;
        border-radius: 0;
        margin-left: calc(10rem/16);
    }
    .navbar-toggler__bar {
        display: block;
        width: calc(22rem/16);
        height: calc(2rem/16);
        background-color: var(--color-default);
        transition: transform .25s ease-in-out,opacity .25s ease-in-out;
        border-radius: calc(2rem/16);
        margin: 0 auto;
    }

    .navbar-toggler__bar+.navbar-toggler__bar {
        margin-top: calc(5rem/16);
    }

    .navbar-toggler.is-open .navbar-toggler__bar:first-child {
        transform: rotate(-45deg) translateX(-4px) translateY(7px);
        width: calc(27rem/16);
    }

    .navbar-toggler.is-open .navbar-toggler__bar:nth-child(2) {
        opacity: 0;
    }

    .navbar-toggler.is-open .navbar-toggler__bar:nth-child(3) {
        transform: rotate(45deg) translateX(-3px) translateY(-6px);
        width: calc(27rem/16);
    }
    .navbar-overlay {
        position: fixed;
        background-color: #fff;
        color: var(--color-default);
        z-index: 20;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .navbar-overlay__inner{
        padding: calc(20rem/16) calc(18rem/16);
        overflow-y: auto;
        height: 100%;
    }

    .navbar-overlay--second-level .navbar-overlay__inner{
        padding-top: calc(86rem/16);
    }

    .navbar-overlay.subnav{
        display: block;
        transform: translateX(-110%);
        transition: transform .25s ease;
    }

    .navbar-overlay-container{
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: calc(64rem/16);
        background-color: #fff;
        color: var(--color-default);
        transform: translateX(-110%);
        transition: transform .25s ease;
        z-index: 20;
    }

    .navbar-overlay-container.is-open,
    .navbar-overlay.subnav.is-open{
        transform: translateX(0%);
    }

    .navbar-overlay.subnav{
        top: 0;
        right: 0;
        overflow-y: auto;
    }

    .navbar-overlay .nav-link{
        padding: calc(11rem/16) 0;
        font-size: calc(18rem/16);
        width: 100%;
        display: flex;
    }

    .nav-link__arrow {
        font-size: calc(13rem/16);
        position: relative;
        top: calc(6rem/16);
    }

    .navbar-right .dropdown--icon .dropdown-toggle{
        background-color: transparent;
        font-size: calc(21rem/16);
    }

    .navbar-right .dropdown--icon .dropdown-toggle:active,
    .navbar-right .dropdown--icon.show .dropdown-toggle{
        background-color: var(--color-default);
        color: var(--color-primary);
        outline: 0;
        box-shadow: none;
    }

    .navbar-right .dropdown--icon .dropdown-toggle:focus{
        outline: none;
        border: none;
    }
    
    .navbar-overlay__go-back{
        display: flex;
        align-items: center;
        padding: calc(21rem/16) calc(20rem/16) calc(14rem/16);
        width: 100%;
        border-bottom: 1px solid #efefef;
        font-size: calc(15rem/16);
        position: fixed;
        background-color: #fff;
        z-index: 1;
    }

    .navbar-overlay__go-back .icon{
        font-size: calc(13rem/16);
        margin-right: calc(10rem/16);
    }

    .navbar-circle-links{
        width: calc(72rem/16);
        height: calc(72rem/16);
        border-radius: 50%;
        background-color: var(--color-primary);
        font-size: calc(10rem/16);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .navbar-circle-links__text{
        text-align: center;
    }

    .navbar-circle-links .icon{
        font-size: calc(20rem/16);
        margin-bottom: calc(5rem/16);
    }

    .navbar-overlay__fixed-btn{
        position: fixed;
        bottom: 0;
        left: 0;
        right: calc(-20rem/16);
        z-index: 60;
        padding: calc(15rem/16) calc(15rem/16) calc(12rem/16);
        font-size: calc(14rem/16);
        border-radius: 0;
    }
    
    .navbar-overlay__fixed-btn .icon{
        font-size: calc(21rem/16);
        vertical-align: calc(-5rem/16);
        margin-right: calc(6rem/16);
    }

    .btn.btn-go-to-top{
        box-shadow: 0 0 20px 0 rgba(42,41,42,0.2);
        background-color: #fff;
        color: var(--color-dark-grey);
        font-size: calc(10rem/16);
        position: fixed;
        right: calc(24rem/16);
        bottom: calc(24rem/16);
        z-index: 1000;

        transition: transform .2s ease-in-out,opacity .2s ease-in-out;
        transform: translateY(100%);
        opacity: 0;
    }

    .btn.btn-go-to-store{
        background-color: var(--color-primary);
        color: var(--color-default);
        box-shadow: 0 0 20px 0 rgba(42,41,42,0.2);
        font-size: calc(25rem/16);
        position: fixed;
        left: calc(24rem/16);
        bottom: calc(24rem/16);
        z-index: 1000;

        transition: transform .2s ease-in-out,opacity .2s ease-in-out;
        transform: translateY(100%);
        opacity: 0;
    }


    .is-affix .btn.btn-go-to-top,
    .is-affix .btn.btn-go-to-store{
        transform: translateY(0);
        opacity: 1;
    }

    .nav-open .btn.btn-go-to-top,
    .nav-open .btn.btn-go-to-store{
        z-index: -1;
    }
    
    .navbar-bottom-list__link{
        font-size: calc(18rem/16);
        padding: calc(8rem/16) calc(8rem/16);
        display: flex;
        align-items: center;
    }

    .navbar-bottom-list__link.is-highlight{
        background-color: var(--color-primary);
    }

    .navbar-bottom-list__icon{
        margin-right: calc(3rem/16);
        width: calc(25rem/16);
        font-size: calc(19rem/16);
    }

}

.navbar-user-profile__btn.navbar-user-profile__btn--name{

    @media screen and (min-width: 768px){
        border: none;
        transform: none;
    }
}

.navbar-user-profile__name{
    font-size: calc(16rem/16);
    margin-top: 2px;

    @media screen and (min-width: 768px){
        font-size: calc(14rem/16);
        margin: 0;
    }
}
.navbar-overlay.subnav{
    display: block;
}