.cart-table__head{
    font-family: var(--font-default-bold);
    font-size: calc(14rem/16);
    border-bottom: 1px solid var(--color-grey);
}

.cart-table__body+.cart-table__body{
    margin-top: calc(30rem/16);
}

.cart-table-item{
    border: 1px solid var(--color-grey);
    border-radius: calc(3rem/16);
    margin-top: calc(10rem/16);
    padding: calc(20rem/16) calc(24rem/16);

    @media screen and (min-width: 768px){
        padding: calc(10rem/16);
        font-size: calc(18rem/16);
        line-height: calc(21/18);
    }
}

.cart-table-item.is-highlight{
    border-color: var(--color-primary);
}

.cart-table-item.not-available{
    border-color: var(--color-danger);
}

.cart-table-item__img{
    display: block;
    @media screen and (min-width: 768px){
       padding: calc(16rem/16);
    }
}

.cart-table-item__img .embed-responsive{
    overflow: visible;
}

.cart-table-item__img img{
    transform: scale(1);
    transition: transform 0.2s;
}

.cart-table-item__img:hover img{
    transform: scale(1.03);
}

.cart-table-item__title{
    text-transform: uppercase;
    font-size: calc(16rem/16);
    line-height: calc(21/18);
    font-family: var(--font-default-bold);
    margin-bottom: calc(8rem/16);
}

.cart-table-item__text{
    /*@media screen and (max-width: 767px){
       padding-right: calc(35rem/16);
    }
    */
    @media screen and (max-width: 767px){
       font-family: var(--font-default-bold);
    }
    @media screen and (min-width: 768px){
        font-size: calc(15rem/16);
    }
}

.modal .cart-table-item__text{
    padding-right: 0;
}

.btn.cart-table-item__remove{
    @media screen and (max-width: 767px){
        position: absolute;
        width: 2.1875rem;
        height: 2.1875rem;
        top: 1rem;
        right: 2rem;
        font-size: 1rem;
    }
    color: #d4d4d4;
    text-shadow: none;
    transition: color 0.2s, text-shadow 0.2s;
}

.btn.cart-table-item__remove:hover{
    color: var(--color-danger);
    text-shadow: 0 0 0 var(--color-danger);
}

.cart-table-item__total-price{
    font-size: calc(16rem/16);
    
    @media screen and (min-width: 768px){
       margin-top: calc(14rem/16);
        display: inline-block;
    }
}

.cart-table-item__amount{
    @media screen and (max-width: 767px){
       font-family: var(--font-default-bold);
    }
    @media screen and (min-width: 768px){
        font-size: calc(16rem/16);
    }
}

.cart-table-item__bottom{
    font-size: calc(14rem/16);
    @media screen and (max-width: 767px){
        border-top: 1px solid var(--color-grey);
        padding-top: calc(10rem/16);
        margin-top: calc(8rem/16);
    }
}


.cart-table__bottom{
    font-size: calc(12rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(14rem/16);
    }
}

.cart-table-item__info{
    font-size: calc(13rem/16);
    display: flex;
    margin-top: calc(10rem/16);

    @media screen and (min-width: 768px){
        align-items: center;
    }
}

.cart-table-item__info .icon{
    @media screen and (max-width: 767px){
       margin-top: calc(3rem/16);
    }
}

.cart-table-item__info .wysiwyg {
    font-size: calc(13rem/16);
    line-height: 1.2;
}

.table.cart-table-summary-table td{
    border: 0;
    padding: calc(3rem/16) 0;
}

.cart-table-summary-table__total,
.cart-table-summary-table__mwst{
    border-top: 1px solid var(--color-grey);
}
.cart-table-summary-table__mwst{
    border-bottom: 1px solid var(--color-grey);
}

.table .cart-table-summary-table__total td{
    vertical-align: middle;
    padding: calc(6rem/16) 0;
    white-space: nowrap;
}

.cart-table-summary-table__total-price{
    font-size: calc(20rem/16);
}

.table .cart-table-summary-table__mwst td{
    padding: calc(10rem/16) 0;
}

.cart-table-item__article-nr{
    font-size: calc(13rem/16);
    display: block;
    color: var(--color-dark-grey);
    margin-top: calc(-3rem/16);
    margin-bottom: calc(5rem/16);
}
.table.cart-table-summary-table .cart-table-summary-table__oes--new td{
    padding-top: calc(6rem/16);
}
.cart-table-summary-table__oes .icon{
    position: relative;
    top:calc(3rem/16);
    margin-left: .125rem;
}


/* cart-table-item--wishlist */

.cart-table-item.cart-table-item--wishlist{
    @media screen and (max-width: 767px){
       padding: calc(10rem/16);
    }
}


.cart-table-item--wishlist .cart-table-item__bottom{
    @media screen and (max-width: 767px){
        margin-top: calc(16rem/16);
    }
}

.cart-table-item--wishlist .cart-table-item__total-price{
    @media screen and (min-width: 768px){
       margin-top: 0;
    }
}

.cart-table-item__small-hl{
    font-size: calc(14rem/16);
    font-family: var(--font-default-bold);
    margin-bottom: calc(3rem/16);
}

.cart-table-item--wishlist .store-select .form-control{
    font-size: calc(12rem/16);
}

.cart-table-item__xsmall-info{
    font-size: calc(11rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(13rem/16);
    }
}

.cart-table-item__xsmall-info .icon-in-text-equal{
    font-size: calc(14rem/16);
}

.cart-table-item--wishlist .price-badge__price{
    @media screen and (max-width: 767px){
       font-size: calc(23rem/16);
    }
}


.modal--cart{
    padding-right: 0 !important;
}
