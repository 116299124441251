
.store-select-wrapper{
    position: relative;
    padding-left: calc(38rem/16);
    display: inline-block;
    cursor: pointer;
    transition: 0.15s;
}

.store-select-wrapper:hover {
    background-color: var(--color-light-grey);
}

.store-select__icon{
    position: absolute;
    left: calc(12rem/16);
    top: calc(15rem/16);
    font-size: calc(16rem/16);
}

.store-select .form-control{
    padding: 0;
    padding-right: calc(30rem/16);
    height: auto;
    cursor: pointer;
}

.store-select-wrapper select,
.store-select-wrapper select:focus{
    width: 100%;
    cursor: pointer;
    background: transparent;
    padding-left: 0;
}

.store-select__opening{
    margin-left: calc(12rem/16);
}