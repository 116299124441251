.flugblatt-swiper__nav img{
    max-width: none;
    height: 100%!important; /* for inline layout needed */
}

.flugblatt-swiper__main{
    max-width: calc(700rem/16);
    margin: 0 auto;
}

.flugblatt-swiper__nav .swiper-slide{
    border: 3px solid transparent;
    padding: calc(5rem/16);
    max-width: none;
}

.flugblatt-swiper__nav .swiper-slide.swiper-slide-thumb-active{
    border-color: var(--color-primary);
}

.flugblatt-swiper__main .swiper-slide{
    cursor: auto;
}

.flugblatt-swiper__main .swiper-slide{
    @media screen and (min-width: 768px){
        max-width: none;
    }
}

.js-flugblatt-hotspot__overlay {
    transition: opacity 0.2s ease-in-out;
    position: absolute;
    opacity: 0;
    pointer-events: none;
    z-index: 1;
}

.flugblatt-hotspot__overlay.visible {
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.5);
    transition: opacity 0.2s ease-in-out;
}
.flugblatt-hotspot__icon{
    pointer-events: none;
    position: absolute;
    z-index: 1;
    width: calc(32rem/16);
    height: calc(32rem/16);
    background-color: var(--color-primary);
    border: 1px solid var(--color-text-default);
    border-radius: 50%;
    padding: 0.3rem;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.5);
    transition: transform 0.1s ease-in-out;
}
.flugblatt-hotspot__icon.hover{
    transition: transform 0.1s ease-in-out;
    transform: translate(-50%, -60%);
}
.flugblatt-hotspot__icon-img{
    width: calc(16rem/16);
    height: calc(16rem/16);
}
@media (max-width: 767px) {
    .flugblatt-hotspot__icon{
        width: calc(20rem/16);
        height: calc(20rem/16);
        padding: 0.2rem;
    }
}