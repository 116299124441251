.wysiwyg-with-download__download{
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 767px) {
        padding-top: calc(16rem/16) ;
    }
}

.wysiwyg-with-download__download-text{
    text-transform: uppercase;
    font-family: var(--font-default-bold);
}

.wysiwyg-with-download__download-filename{
    margin-top: calc(5rem/16);
    display: flex;
    align-items: center;
}

.wysiwyg-with-download__pdf-icon{
    font-size: 1.3rem;
    margin-right: 0.5rem;
}

.wysiwyg-with-download__download-img{
    width: calc(180rem/16);
    margin-right: calc(30rem/16);
}