.banner{
    position: relative;
}

.banner:before{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: linear-gradient(135deg, rgba(255,237,0,0.8) 0%, rgba(255,237,0,0.6) 50.95%, #FFED00 100%);
}

.banner__content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 2;
}

.banner__title{
    font-family: var(--font-default-bold);
    text-transform: uppercase;

    @media screen and (min-width: 768px){
       font-size: calc(72rem/16);
    }
}