.job-teaser{
    min-height: calc(170rem/16);
    transition: background-color 0.2s;
    border: none;
    background-color: var(--color-light-grey);
}

.job-teaser:hover{
    background-color: #ececec !important;
}

.job-teaser.job-teaser--highlight{
    background-color: var(--color-primary);
}

.job-teaser.job-teaser--highlight:hover{
    background-color: var(--color-primary-light) !important;

}

.job-teaser__link-icon{
    font-size: 0.7rem;
    margin-right: 0.4rem;
}

.jobs-filter__label{
    font-family: var(--font-default-bold);
    font-weight: 400;
}

.job-teaser-no-pointerevts{
    pointer-events: none;
}

.job-teaser__location{
    padding: calc(3rem/16) calc(10rem/16) calc(2rem/16);
    border-radius: calc(3rem/16);
    background-color: var(--color-primary);
}

.job-teaser--highlight .job-teaser__location{
    background-color: #000;
    color: var(--color-primary);
}