.job-summary__card {
    padding: calc(16rem/16);
}
.job-summary__card-body{
    padding-top: calc(40rem/16);
    padding-bottom: calc(40rem/16);

    @media screen and (max-width: 768px) {
        padding: calc(32rem/16) 0;
    }
}

.job-summary__content{
    margin-top: calc(40rem/16);
    margin-bottom: calc(40rem/16);
}

.job-summary__pdf-icon{
    font-size: 1.3rem;
    margin-right: 0.5rem;
}

.job-summary__download{
    margin-top: calc(3rem/16);
    display: flex;
    align-items: center;
}

.job-summary__location{
    padding: calc(3rem/16) calc(10rem/16) calc(2rem/16);
    border-radius: calc(3rem/16);
    display: inline-flex;
}