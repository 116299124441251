.box{
    padding: calc(20rem/16);
    border-radius: calc(3rem/16);
}

.box--border{
    border: 1px solid var(--color-grey);
}

.box--lg{
    font-size: calc(16rem/16);
}

.box--xl{
    padding: calc(40rem/16);
}