.nav-tabs.nav-tabs--radios .nav-link{
    padding: 0;
}

.nav-tabs.nav-tabs--radios .nav-item:first-child .nav-link{
    padding-left: 0;
}

.nav-tabs.nav-tabs--radios .nav-item:not(:last-child){
    margin-right: calc(40rem/16);
}

.nav-tabs--radios .custom-radio__text{
    text-transform: none;
    font-size: calc(15rem/16);
    font-family: var(--font-default);

    @media screen and (min-width: 768px){
        font-size: calc(16rem/16);
    }
}

.nav-tabs.nav-tabs--radios .nav-link:after{
    display: none;
}

.tab-content--radios .tab-pane{
    padding: calc(20rem/16) 0;
}

.tab-content--grey{
    background-color: var(--color-light-grey);
}