.alert-notification-default{
    background: #fff;
    box-shadow: 0 2px 9px 0 rgba(119, 119, 119, 0.28);
}

.notification-box .alert-notification-danger{
    background: #fff;
    box-shadow: 0 2px 9px 0 rgba(119, 119, 119, 0.28);
    border: 2px solid #e80000;
}


.notification-box{
    position: fixed;
    top: calc(80rem/16);
    right: calc(10rem/16);
    left: calc(10rem/16);
    z-index: 100;

    @media screen and (min-width: 768px){
        top: calc(100rem/16);
        right: calc(50rem/16);
        left: auto;
        max-width: calc(350rem/16);
        width: 100%;
    }
}

.notification-box__content{
    padding-left: calc(45rem/16);
}

.notification-box__title{
    position: relative;
    font-size: calc(18rem/16);
    font-family: var(--font-default-bold);
    line-height: calc(22/18);
}

.notification-box__title-circle{
    width: calc(36rem/16);
    height: calc(36rem/16);
    border-radius: 50%;
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: calc(16rem/16);
    position: absolute;
    left: calc(-45rem/16);
    top: 0;
}

.notification-box__title > .icon-joe{
    color: var(--color-joe);
    justify-content: center;
    font-size: calc(32rem/16);
    position: absolute;
    left: calc(-45rem/16);
    top: 0;
}

.notification-box .btn-joe:hover{
    box-shadow: 0 0 10px 0 rgba(42,41,42,.35);
}

.notification-box .discount-text__icon{
    position: absolute;
    left: calc(-45rem/16);
    top: calc(-4rem/16);
}

.notification-box__icon{
    width: calc(30rem/16);
    height: calc(30rem/16);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-default);
    color: #fff;
    font-size: calc(13rem/16);
    margin-right: calc(12rem/16);
}

@media screen and (min-width: 768px){
    .notification-box.notification-box--supplier{
        top: calc(90rem/16);
        right: calc(20rem/16);
        position: absolute;
    }
}
