.footer{
    @media screen and (max-width: 767px){
       padding-bottom: calc(80rem/16);
    }
}
.footer__main{
    background-color: var(--color-light-grey);
    font-size: calc(14rem/16);
    padding: calc(30rem/16) calc(20rem/16) calc(40rem/16);

    @media screen and (min-width: 768px){
        padding: calc(60rem/16) 0;
    }
}

.footer__block+.footer__block{
    margin-top: calc(25rem/16);

    @media screen and (min-width: 768px){
        margin-top: calc(50rem/16);
    }
}

.footer-collapse{
    @media screen and (max-width: 767px){
       padding-bottom: calc(15rem/16);
    }
}

.footer__hl{
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    margin-bottom: calc(30rem/16);
    
    @media screen and (max-width: 767px){
        margin-top: calc(15rem/16);
        margin-bottom: calc(15rem/16);
    }
}

.footer__hl .icon-arrow-down {
    transform: rotate(-180deg);
    transition: transform 0.3s;
}

.footer__hl.collapsed .icon-arrow-down {
    transform: rotate(0deg);
}

.footer .list-links>li:not(:last-child){
    margin-bottom: calc(10rem/16);
}

.footer .list-links a:hover{
    text-decoration: underline;
}

.footer-small-info{
    font-size: calc(12rem/16);
    color: var(--color-dark-grey);
    line-height: calc(20/12);
}

.list-social__item{
    width: calc(32rem/16);
    height: calc(32rem/16);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    transition: all 150ms ease;
}

.list-social__item:hover{
    color: #fff;
    transform: translateY(-3px);
}

.list-social__item.facebook{
    background-color: #3b5998;
}
.list-social__item.instagram{
    background-color: #e95950;
}
.list-social__item.youtube{
    background-color: #FF0000;
}

.footer-radiospot{
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-radiospot__circle{
    width: calc(16rem/16);
    height: calc(16rem/16);
    border-radius: 50%;
    background-color: #d4d4d4;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(9rem/16);
    margin-right: calc(5rem/16);
}

.footer__bottom{
    font-size: calc(12rem/16);
    color: var(--color-dark-grey);
    padding: calc(33rem/16) 0;
    
    @media screen and (max-width: 767px){
       text-align: center;
        padding: calc(25rem/16) 0;
    }
}

.list-bottom-links{
    @media screen and (max-width: 767px){
       font-size: calc(10rem/16);
    }
}

.list-bottom-links .list-inline-item:not(:last-child){
    margin-right: calc(24rem/16);

    @media screen and (min-width: 768px){
        margin-right: calc(35rem/16);
    }
}

.footer__payment-list{
    @media screen and (max-width: 767px){
       margin-top: calc(-25rem/16);
    }
}

.footer__payment-list .list-inline-item{
    @media screen and (max-width: 767px){
        margin-top: calc(25rem/16);
        margin-right: calc(14rem/16);
    }
}
