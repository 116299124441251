.table-small th,
.table-small td,
.table-small.table-primary th,
.table-small.table-primary td{
    font-size: calc(14rem/16);
    padding: calc(4rem/16) calc(10rem/16);
}

.bg-light .table-striped tbody tr:nth-of-type(odd){
    background-color: #fff;
}

.table-primary thead th{
    background-color: var(--color-primary);
    vertical-align: middle;
    padding: calc(16rem/16) calc(20rem/16);
    line-height: calc(16/14);
    border-color: transparent;
}

.table-primary tbody td{
    padding: calc(18rem/16) calc(20rem/16);
    vertical-align: middle;
    border-color: transparent;
}

.table-simple thead th{
    background-color: var(--color-grey);
}

.table td.td-vertical-align-middle{
    vertical-align: middle;
}