.einhell-product-advisor .container-0-0-11 .answers-wrapper {
    @media (max-width: 767px) {
        flex-flow: row wrap !important;
        row-gap: calc(16rem/16);
        column-gap: calc(24rem/16);
    }
}

.einhell-product-advisor .questions-wrapper .answers-wrapper .answer {
    @media (max-width: 767px) {
        margin: 0;
        width: calc(50% - 12rem/16) !important;
    }
}

.einhell-product-advisor .questions-wrapper .answers-wrapper .answer:last-child:nth-child(odd) {
    @media (max-width: 767px) {
        margin-left: calc(24rem/16);
    }
}