.business-card-infobox {
    display: flex;
    gap: calc(16rem/16);
    align-items: center;
    background: var(--color-white);
    padding: calc(16rem/16);
    text-align: left;
    margin-bottom: calc(16rem/16);

    @media (min-width: 768px){
        margin-bottom: calc(30rem/16);
    }
}

.business-card-infobox.business-card-infobox--profile {
    width: fit-content;
    background: var(--color-light-grey);
    margin-bottom: calc(30rem/16);

    @media (min-width: 768px) {
        margin-bottom: 0;
    }
}

.business-card-infobox__icon-wrapper {
    min-height: calc(36rem/16);
    min-width: calc(36rem/16);
    position: relative;
    background: var(--color-primary);
    border-radius: 50%;
}

.business-card-infobox__icon-wrapper .icon {
    font-size: calc(20rem/16);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -44%);
}

.business-card-infobox__text {
    font-size: calc(14rem/16);
    line-height: calc(21/14);
}