.product-review__card-body{
    @media screen and (max-width: 767px){
        padding: calc(16rem/16);
    }
}


.product-review__rating{
    margin-top: calc(16rem/16);
}

.product-review__rating-stars{
    position: relative;
    display: flex;

    @media screen and (max-width: 767px){
        margin-bottom: calc(22rem/16);
    }
}

.product-review__star-input{
     display: none;
 }

.product-review__star-icon{
    font-size: calc(30rem/16);
    color: var(--color-primary);
    width: calc(35rem/16);
    height: calc(31rem/16);
    cursor: pointer;
    transition: opacity 0.3s;
}

.product-review__star-icon::after{
    content: var(--icon-star-outline);
}

.product-review__chooser{
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    font-size: calc(30rem/16);
    color: var(--color-primary);
    cursor: pointer;
    transition: width 0.2s linear, color 0.15s ease-in-out;
    overflow: hidden;
}

.product-review__chooser-star{
    width: calc(35rem/16);
    height: calc(31rem/16);
}

.product-review__star-icon-1.is-selected ~ .product-review__chooser{
    width: calc(35rem/16);
}

.product-review__star-icon-2.is-selected ~ .product-review__chooser{
    width: calc(70rem/16);
}

.product-review__star-icon-3.is-selected ~ .product-review__chooser{
    width: calc(105rem/16);
}

.product-review__star-icon-4.is-selected ~ .product-review__chooser{
    width: calc(140rem/16);
}

.product-review__star-icon-5.is-selected ~ .product-review__chooser{
    width: calc(175rem/16);
}


.product-review__star-icon-1:hover ~ .product-review__chooser{
    width: calc(35rem/16) !important;
}

.product-review__star-icon-2:hover ~ .product-review__chooser{
    width: calc(70rem/16) !important;
}

.product-review__star-icon-3:hover ~ .product-review__chooser{
    width: calc(105rem/16) !important;
}

.product-review__star-icon-4:hover ~ .product-review__chooser{
    width: calc(140rem/16) !important;
}

.product-review__star-icon-5:hover ~ .product-review__chooser{
    width: calc(175rem/16) !important;
}

.product-review__chooser.is-clicked{
    color: var(--color-primary-dark);
}