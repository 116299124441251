/* Product Grid Sidebar */

.product-sidebar{
    display: block;
    @media screen and (min-width: 768px){
       margin-top: calc(-60rem/16);
    }
}

.product-sidebar__link-list>li+li{
    margin-top: calc(10rem/16);
}

.product-sidebar__link-list a:hover{
    color: #838283;
}

.product-grid-filter__counter{
    font-size: calc(14rem/16);
    color: #d4d4d4;
    border: 1px solid #d4d4d4;
    border-radius: calc(3rem/16);
    padding: calc(3rem/16) calc(6rem/16);
    margin-left: calc(5rem/16);
    line-height: 1;
}

.product-grid-filter__checkbox{
    display: flex;
    align-items: center;
}

.product-grid-filter__checkbox .product-grid-filter__counter{
    position: relative;
    margin-left: calc(10rem/16);
    top: calc(-3rem/16);
}

.product-grid-quick-filter__item{
    background-color: var(--color-light-grey);
    border: none;
    border-radius: 3px;
    padding: calc(3rem/16) calc(7rem/16);
}

.product-grid-quick-filter__item:hover{
    background-color: var(--color-grey);
}

.product-grid-quick-filter__remove{
    color: var(--color-dark-grey);
    font-size: calc(10rem/16);
    margin-left: calc(6rem/16);
}

.product-grid-quick-filter__remove-all{
    color: var(--color-primary);
    font-size: calc(10rem/16);
    margin-right: calc(4rem/16);
}

.product-grid__remove-all{
    background-color: var(--color-secondary);
    color: #FFF;
    border: none;
    border-radius: 3px;
    padding: calc(3rem/16) calc(7rem/16);
}

.product-grid__remove-all:hover{
    background-color: #686868;
}

.accordion--product-sidebar{
    font-size: calc(16rem/16);
}

.accordion--product-sidebar .card+.card{
    margin-top: calc(40rem/16);
}

.accordion--product-sidebar .accordion__header-link{
    padding: calc(20rem/16);
    font-size: calc(18rem/16);
    font-family: var(--font-default-medium);
}

.accordion.accordion--product-sidebar>.card:first-of-type,
.accordion.accordion--product-sidebar>.card:not(:first-of-type):not(:last-of-type){
    border-bottom: 1px solid var(--color-grey);
    border-radius: calc(3rem/16);
}

.accordion.accordion--product-sidebar>.card:last-of-type {
    border-radius: calc(3rem/16);
}

.accordion--product-sidebar .card-body>ul:not(.product-sidebar__link-list){
    @media screen and (min-width: 768px){
       max-height: calc(610rem/16);
        overflow: auto;
    }
}

.accordion--product-sidebar .card-body>ul::-webkit-scrollbar {
    width: calc(4rem/16);
    border-radius: calc(3rem/16);
}

/* Track */
.accordion--product-sidebar .card-body>ul::-webkit-scrollbar-track {
    background: #e9e9e9;
    border-radius: calc(3rem/16);
}

/* Handle */
.accordion--product-sidebar .card-body>ul::-webkit-scrollbar-thumb {
    background: #d4d4d4;
    border-radius: calc(3rem/16);
    transition: all 250ms ease;
}

/* Handle on hover */
.accordion--product-sidebar .card-body>ul::-webkit-scrollbar-thumb:hover {
    background: #c0c0c0;
}

.product-grid__sort-select::before {
    background-color: transparent;
    padding: 0.5rem;
    right: 0.25rem;
    border-radius: 50%;
    transform: translateY(-50%);
    width: 23px;
    height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-grid__sort-select:hover::before{
    background-color: var(--color-light-grey);
}

.product-grid-filter__title{
    font-size: calc(16rem/16);
    margin-bottom: calc(12rem/16);
    display: none;
}

.product-grid-filter__title.show{
    display: block;
}

.product-grid__loading .loading-overlay--loading-anim{
    margin-top: 25vh;
}

.product-grid__loading.loading-overlay{
    @media screen and (max-width: 767px){
       left: -15px;
       right: -15px;
    }
}