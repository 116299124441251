.usps-slider{
    position: relative;
}

.usp-item__img{
    max-width: calc(133rem/16);
    margin: 0 auto;

    @media screen and (min-width: 768px){
        max-width: calc(200rem/16);
    }
}

.usp-item__title{
    
    @media screen and (min-width: 768px){
       font-size: calc(17rem/16);
    }
    @media screen and (min-width: 1200px){
       font-size: calc(20rem/16);
    }
}
.js-swiper-usps .swiper-slide{
    @media screen and (min-width: 768px){
       max-width: none;
    }
}
