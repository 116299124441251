.checkout-address-cards {
    display: flex;
    flex-direction: column;
    gap: calc(16rem/16);

    @media screen and (min-width: 768px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        gap: calc(20rem/16);
    }
}

.checkout-address-cards--reservation {
    @media screen and (min-width: 768px) {
        display: block;
        padding: 0 calc(40rem/16);
    }
}

.checkout-address-cards__delivery-tabs {
    display: flex;
    flex-direction: column;
    gap: calc(16rem/16);
    padding-bottom: calc(16rem/16);
    border-bottom: var(--color-grey) solid calc(1rem/16);

    @media screen and (min-width: 768px) {
        flex-direction: row;
        gap: calc(20rem/16);
        justify-content: space-around;
    }
}

.checkout-address-cards__delivery-tabs--3 {
    @media screen and (min-width: 768px) {
        justify-content: space-between;
    }
}

.checkout-address-cards__delivery-tabs .checkout-address--delivery {
    margin: 0;
}

.checkout-address-card__info {
    font-size: calc(14rem/16);
    line-height: calc(21/14);

    @media screen and (min-width: 768px) {
        font-size: calc(16rem/16);
        line-height: calc(27/16);
    }
}

.checkout-address-card__info:not(.checkout-address-card__info--multi-col) > li:not(:first-child) {
    margin-top: calc(8rem/16);
}

.checkout-address-card__info--multi-col {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: calc(8rem/16);
}