.brand-area{
    padding: calc(50rem/16) 0;
}


.text-color-theme-dark .brand-area__content{
    color: var(--color-text-default);
}

.text-color-theme-light .brand-area__content{
    color: #fff;
}

.brand-area__toptitle{
    font-size: calc(14rem/16);
    text-transform: uppercase;
    margin-bottom: calc(5rem/16);
    font-family: var(--font-default-bold);

    @media screen and (min-width: 768px){
        font-size: calc(16rem/16);
    }
}

.brand-area__title{
    font-size: calc(28rem/16);
    margin-bottom: calc(20rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(38rem/16);
    }
    
    @media screen and (max-width: 767px){
       font-family: var(--font-default-bold);
    }
}

.brand-area__wysiwyg{
    margin-bottom: calc(20rem/16);
}

.brand-area__btn{
    border-width: 1px;
}