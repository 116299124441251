.subnav{
    position: absolute;
    left: 0;
    right: 0;
    background-color: #fff;
    padding: 0;
    z-index: 1;
    overflow: hidden;
    font-size: calc(16rem/16);

    @media screen and (min-width: 768px){
        opacity: 0;
        visibility: hidden;
        transition: opacity 200ms ease, visibility 200ms ease;
        transition-delay: 120ms;
        padding: calc(30rem/16) 0;
    }
    
    @media screen and (min-width: 1200px){
        padding: calc(50rem/16) 0;
    }

    @media screen and (max-height: 800px){
        max-height: calc(100vh - 76.9px);
        overflow-y: auto;
    }
}


.nav-item.is-open .subnav{
    opacity: 1;
    visibility: visible;
    transition: opacity 200ms ease, visibility 200ms ease;
}

.subnav:before {
    content: '';
    box-shadow: 0 0 40px 0 rgba(42,41,42,.2);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: calc(50rem/16);
    z-index: 3;
    transform: translateY(-100%);
    pointer-events: none;
}

.subnav__title{
    text-transform: uppercase;
    font-family: var(--font-default-medium);
    margin-bottom: calc(25rem/16);
    
    @media screen and (max-width: 767px){
        color: var(--color-text-muted);
        text-transform: uppercase;
        font-size: calc(14rem/16);
        margin-bottom: calc(13rem/16);
    }
    
}

.subnav__col{
    height: 100%;
    display: flex;
    flex-direction: column;
}

.subnav__link {
    padding: calc(3rem/16) 0 calc(6rem/16);
    display: inline-block;
    overflow: hidden;
    position: relative;

    @media screen and (min-width: 768px) and (max-width: 1199px){
       font-size: calc(15rem/16);
    }

   @media screen and (max-width: 767px){
       padding: calc(7rem/16) 0;
   }
}

.subnav__link-text{
    position: relative;
    padding: calc(3rem/16) 0 calc(6rem/16);
}

.subnav__link:after,
.subnav__link-text:after {
    content: '';
    position: absolute;
    height: calc(2rem/16);
    background-color: var(--color-primary-dark);
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transform: translateY(100%);
    transition: all 350ms ease;
}

.subnav__link:not(.subnav__link--img):hover:after,
.subnav__link--img:hover .subnav__link-text:after{
    opacity: 1;
    transform: translateY(-5px);
    @media screen and (max-height: 800px){
        transform: translateY(-2px);
    }
}

.subnav__item--img .subnav__link{
    display: inline-flex;
    align-items: center;
    padding-bottom: calc(3rem/16);
}

.subnav__item.subnav__item--img:not(:last-child){
    margin-bottom: 0;
}

.subnav__img{
    border-radius: calc(3rem/16);
    margin-right: calc(10rem/16);
}

.subnav__all-link{
    @media screen and (min-width: 768px){
        font-size: calc(18rem/16);
    }
}
