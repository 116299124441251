.list-downloads .list-inline-item:not(:last-child){
    margin-right: calc(30rem/16);
}

.tooltip-btn{
    width: calc(17rem/16);
    height: calc(17rem/16);
    background-color: var(--color-secondary);
    color: var(--color-primary);
    border-radius: 50%;
    border: none;
    padding: 0;
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.tooltip{
    z-index: 2147483647!important; /* because of trust-badge */
    font-size: calc(13rem/16);
    font-family: var(--font-default);
}

/*.tooltip-btn__inner{
    position: relative;
    left: -1px;
}*/


.back-link{
    font-size: calc(16rem/16);
}

.back-link .icon-arrow-down{
    font-size: 6px;
}

.back-link--steps {
    position: absolute;
    top: calc(8rem/16);
    left: calc(24rem/16);
    display: none;

    @media screen and (min-width: 768px) {
        display: block;
    }
}

/* list-check-bullets */


.list-check-bullets>li{
    display: flex;
    align-items: center;
    margin-bottom: calc(10rem/16);
}

.list-check-bullets__bullet{
    width: calc(30rem/16);
    height: calc(30rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: var(--color-default);
    background-color: var(--color-primary);
    margin-right: calc(10rem/16);
    font-size: calc(12rem/16);
}

.circle-icon{
    width: calc(30rem/16);
    height: calc(30rem/16);
    border-radius: 50%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(12rem/16);
    line-height: 1;
}

.box .wysiwyg{
    line-height: calc(20/14);
}

.notification-fixed{
    position: fixed;
    bottom: 0;
    max-width: 300px;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999999999;

    @media screen and (min-width: 768px){
        max-width: 500px;
    }
}

.date-label{
    background-color: var(--color-primary);
    letter-spacing: calc(2.1rem/16);
    font-family: var(--font-default-bold);
    padding: 0 calc(10rem/16);
    border-radius: calc(3rem/16);
}

.oeffi-symbol{
    width: calc(17rem/16);
    height: calc(16rem/16);
    border-radius: calc(3rem/16);
    background-color: #2E3192;
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-default-bold);
    font-size: calc(9rem/16);
    line-height: 1;
    vertical-align: calc(1rem/16);
}

.discount-text{
    position: relative;
}

.discount-text__price{
    font-size: calc(22rem/16);
    color: #ef3340;
    font-family: var(--font-block);
    line-height: 1;
    display: inline-block;
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: #fff;
    vertical-align: -2px;
}

.discount-text__bold{
    font-size: calc(16rem/16);
    color: var(--color-default);
    font-family: var(--font-block);
    line-height: 1;
}


.discount-text__icon{
    width: calc(36rem/16);
    height: calc(36rem/16);
    border-radius: 50%;
    color: var(--color-default);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: calc(25rem/16);
    background: var(--color-primary);
}

.cookie-bar{
    z-index: 9999999999;
}

.forgot-password{
    cursor: pointer;
}

.forgot-password:hover{
    text-decoration: none;
}

.voucher-code{
    font-size: calc(16rem/16);
    display: flex;
    align-items: center;
    margin-bottom: 0;
}
.voucher-code.form-group{

    margin-bottom: 0;
}

.voucher-code__remove{
    font-size: calc(14rem/16);
    margin-top: calc(-1rem/16);
}

.voucher-code__remove .icon{
    font-size: calc(16rem/16);
    vertical-align: calc(-3rem/16);
    margin-right: calc(3rem/16);
}