.login-box {
    @media screen and (max-width: 767px){
        margin-top: 3.5rem;
    }
}

.btn-facebook{
    background-color: #3b5999;
    color: #FFF;
}

.btn-facebook:hover {
    color: #FFF;
    background-color: #2f477a;
}

.btn-google{
    background-color: #dc4e41;
    color: #FFF;
}

.btn-google:hover {
    color: #FFF;
    background-color: #b54136;
}

.login-box__privacy-policy {
    font-size: var(--font-size-default);
    padding-top: 2rem;
}
.login-box__divider {
    margin: 1.875rem auto;
    border: calc(1rem/16) solid #e9e9e9;
}

/* Benefits */
.login-box__box__block--benefits {
    margin:0 auto 1.875rem auto;
    text-align: left;
}


/* JÖ */
.login-box__box__block--joe{
    width:100%;
    max-width:20rem;
    margin: 1.875rem auto;
    text-align: left;
}