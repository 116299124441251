.title-block{
    margin-bottom: calc(20rem/16);
}

.title-block__title{
    margin-bottom: calc(5rem/16);
    font-size: calc(28rem/16);
    @media screen and (min-width: 768px){
       font-size: calc(38rem/16);
    }

    @media screen and (max-width: 767px){
       font-family: var(--font-default-bold);
    }
}

.title-block--small .title-block__title{
    @media screen and (min-width: 768px){
       font-size: calc(30rem/16);
    }
}

.title-block__sub-title{
    font-family: var(--font-default);
    font-size: calc(14rem/16);
    @media screen and (min-width: 768px){
       font-size: calc(16rem/16);
    }

    @media screen and (max-width: 767px){
       margin-bottom: calc(5rem/16);
    }
}

.portal-title-block__title{
    font-family: var(--font-default);
    margin-bottom: calc(20rem/16);

}

.portal-title-block__sub-title{
    font-family: var(--font-default);
}

.portal-title-block__wysiwyg{
    margin-top: calc(10rem/16);
    font-size: calc(16rem/16);
    line-height: calc(24rem/16);
}

.title-block--big .title-block__title{
    margin-top: calc(20rem/16);

    @media screen and (min-width: 768px){
       font-size: calc(40rem/16);
        margin-top: calc(32rem/16);
    }
}

.title-block--center-strong{
    text-align: center;
    margin-bottom: calc(20rem/16);

    @media screen and (min-width: 768px){
       margin-bottom: calc(40rem/16);
    }
}

.title-block--center-strong .title-block__title{
    font-family: var(--font-default-bold);
    font-size: calc(21rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(30rem/16);
    }
}

.title-block--upper-strong{
    margin-bottom: calc(20rem/16);

    @media screen and (min-width: 768px){
       margin-bottom: calc(40rem/16);
    }
}

.title-block--upper-strong .title-block__title{
    font-size: calc(28rem/16);
    margin-bottom: calc(20rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(38rem/16);
    }

    @media screen and (max-width: 767px){
        font-family: var(--font-default-bold);
    }
}

.title-block--upper-strong .title-block__sub-title{
    font-size: calc(14rem/16);
    text-transform: uppercase;
    margin-bottom: calc(5rem/16);
    font-family: var(--font-default-bold);

    @media screen and (min-width: 768px){
        font-size: calc(16rem/16);
    }
}