.delivery-status{
    font-size: calc(12rem/16);
    line-height: 1;
    @media screen and (min-width: 768px){
        font-size: calc(14rem/16);
    }
}

.delivery-status.available{
    color: var(--color-success);
}

.delivery-status__circle{
    width: calc(10rem/16);
    height: calc(10rem/16);
    border-radius: 50%;
    display: inline-block;
    margin-right: calc(3rem/16);
}

.available .delivery-status__circle{
    background-color: var(--color-success);
}