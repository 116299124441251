.detail-hero__content{
    background-color: var(--color-light-grey);
    position: relative;
    padding: calc(40rem/16) calc(24rem/16);
}
.detail-hero__subtitle {
    color: #949494;
    font-size: calc(18rem/16);
    line-height: calc(22/18);
    font-family: var(--font-default-medium);
}
.detail-hero__title {
    font-size: calc(25rem/16);
    line-height: calc(30/25);
    font-family: var(--font-default-medium);
}
.detail-hero__detail-grid {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: calc(20rem/16);
}
.detail-hero__btn {
    position: absolute;
    bottom: calc(-24rem/16);
}
.detail-hero__detail-title{
    font-size: calc(16rem/16);
    line-height: calc(27/16);
    font-family: var(--font-default-medium);
}
.detail-hero__detail-text{
    font-size: calc(16rem/16);
    line-height: calc(27/16);
    font-family: var(--font-default);
}
.detail-hero__detail-text--highlighted {
    width: fit-content;
    padding: 0.2rem calc(10rem/16);
    background-color: var(--color-primary);
}
@media (min-width: 768px){
    .detail-hero__content{
        padding: calc(60rem/16) calc(40rem/16);
        border-radius: calc(3rem/16);
    }
    .detail-hero__content--top{
        top: calc(-60rem/16);
    }
    .detail-hero__subtitle {
        font-size: calc(20rem/16);
        line-height: calc(24/20);
    }
    .detail-hero__title {
        font-size: calc(32rem/16);
        line-height: calc(39/32);
    }
    .detail-hero__detail-grid {
        grid-template-columns: 3fr 3fr 3fr 3fr;
        margin-top: calc(40rem/16);
        grid-gap: calc(20rem/16);
    }
    .detail-hero__detail-stores{
        margin-left: calc(20rem/16);
    }
}
@media (max-width: 767px){
    .detail-hero {
        background-color: var(--color-light-grey);
    }
    .detail-hero__detail-stores{
        margin-bottom: calc(12rem/16);
    }
}

@media (max-width: 767px){
    .detail-hero__content {
        position: relative;
        padding-bottom: 0.4rem;
    }
    .detail-hero__btn {
        position: relative;
        bottom: -1.5rem;
        width: 100%;
    }
}
.detail-hero__stores-list{
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
}