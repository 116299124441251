.product-teaser{
    border: 1px solid var(--color-grey);
    border-radius: calc(3rem/16);
    background-color: #fff;
    height: 100%;
    position: relative;
    font-size: calc(12rem/16);
    line-height: calc(19/16);

    @media screen and (min-width: 768px){
        font-size: calc(16rem/16);
    }
}

/*.product-teaser:hover{
    box-shadow: 0 0 40px 0 rgba(42,41,42,0.2);
}*/

.product-teaser__body{
    padding: calc(15rem/16) calc(10rem/16) calc(10rem/16);
    display: flex;
    flex-direction: column;
    height: 100%;
    
    @media screen and (min-width: 1200px){
       padding: calc(20rem/16);
    }
}

.product-teaser__title{

    @media screen and (min-width: 768px) and (max-width: 1199px){
       font-size: calc(16rem/16);
    }

    @media screen and (max-width: 767px){
       font-size: calc(12rem/16);
    }
}

.product-teaser--brand .product-teaser__title{
    font-family: var(--font-default);
}

.product-teaser__toptitle{
    text-transform: uppercase;
    font-family: var(--font-default-bold);
    margin-bottom: calc(5rem/16);
    font-size: calc(20rem/16);
}

.product-teaser__subtitle{
    margin-top: calc(10rem/16);

    @media screen and (min-width: 768px){
        margin-top: calc(7rem/16);
    }
}

.product-teaser__info{
    margin-top: calc(10rem/16);
    color:var(--color-dark-grey);
    font-size:calc(12rem/16);
    @media screen and (min-width: 768px){
        margin-top: calc(7rem/16);
        font-size:calc(14rem/16);
    }
}



.product-teaser__img{
    position: relative;
    padding: calc(10rem/16);
    /*  @media screen and (min-width: 768px){
         height: 100%;
      }*/
}

.product-teaser__img .embed-responsive{
    overflow: visible;
    @media screen and (min-width: 768px){
        height: 100%;
    }
}


@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .product-teaser__img{
        position: relative;
        padding: calc(10rem/16);
        height: 100%;
        overflow: hidden;
    }

    .product-teaser__img .embed-responsive{
        overflow: visible;
        height: auto;

    }
}




.product-teaser__img img{
    transition: transform .25s ease-in-out;
    transform-origin: center;
}

.product-teaser:not(.product-teaser--joe):hover .product-teaser__img img{
    transform: scale(1.03);
}

.product-teaser__img .price-badge{
    position: absolute;
    bottom: 0;
    right: 0;
}

.product-teaser .btn-cart {
    font-size: calc(12rem / 16);
    height: calc(40rem / 16);
    padding: calc(5rem / 16);
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 768px) {
        height: calc(48rem / 16);
        font-size: calc(14rem / 16);
    }


    @media screen and (min-width: 768px) and (max-width: 1499px) {
        font-size: calc(12rem / 16);
        padding: calc(5rem / 16);
    }
}

.product-teaser .btn-cart .icon{
    @media screen and (min-width: 768px) and (max-width: 1199px){
       display: none;
    }
}

.product-teaser .btn-compare{
    border: calc(3rem/16) solid var(--color-secondary);
    @media screen and (min-width: 768px) and (max-width: 1199px){
        width: calc(35rem/16);
    }
}
.product-teaser .btn-compare.is-active{
    border-color: var(--color-primary);
    background-color: #696969;
}


.product-teaser{
    display: flex;
    flex-direction: column;
}

.product-teaser__bottom{
    margin-top: auto;
}

.product-teaser .btn-icon-text__icon{
    font-size: calc(16rem/16);
    vertical-align: calc(-3rem/16);

    @media screen and (max-width: 370px){
       display: none;
    }
}

.product-teaser__no-delivery{
    text-align: center;
    color: var(--color-danger);
    background-color: var(--color-light-grey);
    border: 1px solid var(--color-danger);
    font-family: var(--font-default-bold);
    padding: calc(10rem/16);
    margin-top: calc(20rem/16);
    font-size: calc(12rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(14rem/16);
        padding: calc(10rem/16) calc(20rem/16);
    }
}


/* JÖ */
.product-teaser--joe .is-added-to-cart .btn-cart {
    pointer-events: none;
    cursor:not-allowed;
    opacity: .75;
}
.product-teaser--joe .is-added-to-cart .btn-cart > .icon:before {
    content:var(--icon-check);
}
.product-teaser.product-teaser--joe .product-teaser__img {
    padding:0;
}

/* SHIMMER */
.product-teaser--shimmer {
    animation: shimmer 4s infinite linear;
    animation-fill-mode: forwards;
    background: #ddd;
    background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
    background-size: 1200px 100%;
    height: clamp(17.375rem, 45.663vw + 6.673rem, 28.563rem);
    width: 100%;

    @media (min-width: 768px) {
        height: clamp(20.438rem, 30.324vw + 5.882rem, 28.625rem);
    }
}
@keyframes shimmer {
    0% {
        background-position: -1200px 0;
    }
    100% {
        background-position: 1200px 0;
    }
}