.topic-teaser{
    border-radius: calc(3rem/16);
    overflow: hidden;
    position: relative;
    @media screen and (min-width: 768px){
       height: 100%;
    }
}

.topic-teaser__img{
    @media screen and (min-width: 768px){
       height: 100%;
    }
}

.topic-teaser__body{
    padding: calc(10rem/16) calc(10rem/16) calc(10rem/16) 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media screen and (min-width: 768px){
       padding: calc(10rem/16) calc(40rem/16) calc(40rem/16) 0;
    }
}

.topic-teaser__title{
    font-size: calc(18rem/16);
    display: inline-block;
    background-color: var(--color-primary);
    padding: calc(8rem/16) calc(15rem/16);
    border-top-right-radius: calc(3rem/16);
    border-bottom-right-radius: calc(3rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(32rem/16);
        max-width: 70%;
        padding: calc(10rem/16) calc(20rem/16);
    }
}