.file-drop-area{
    border: 2px dashed var(--color-grey);
    width: 100%;
    padding: calc(20rem/16);
    color: #d4d4d4;
    font-size: calc(12rem/16);
    cursor: pointer;
    border-radius: calc(3rem/16);
    text-align: center;
    background-color: var(--color-white);
}

.has-error .file-drop-area{
    border-color: var(--color-danger);
}

.file-drop-area--highlight {
    border-color: var(--color-primary);
}

.file-drop-area--error {
    border-color: var(--color-danger);
}

.file-drop-area--readonly{
    border: 2px solid var(--color-light-grey);
    background-color: var(--color-light-grey);
    text-align: left;
    color: var(--color-default);
    cursor: auto;
}

.file-drop-area__icon {
    font-size: calc(32rem/16);
    color: #d4d4d4;
}

.file-drop-area__text{
    color: var(--color-default);
    margin-top: calc(10rem/16);
    font-size: calc(16rem/16);
}