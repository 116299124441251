.dropdown .dropdown-toggle{
    padding: calc(5rem/16);
}

.dropdown-menu{
    border-radius: calc(3rem/16);
    border: 0;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.2);
    padding: calc(25rem/16);
}

.dropdown--icon .dropdown-toggle{
    width: calc(40rem/16);
    height: calc(40rem/16);
    border-radius: 50%;
    background-color: var(--color-light-grey);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 150ms ease;
}

.dropdown--icon .dropdown-toggle:focus{
    outline: none;
    border: 1px solid #000;
    border-radius: 50%;
}

.dropdown--icon .dropdown-toggle:hover {
    @media (hover: hover){
        background-color: var(--color-default);
        color: var(--color-primary);
    }
}

.dropdown--icon.show .dropdown-toggle{
    background-color: var(--color-default);
    color: var(--color-primary);
}

.dropdown--icon .dropdown-toggle:after{
    display: none;
}

.dropdown--icon .dropdown-menu{
    transform: translateX(-50%);
    left: 50%;
    margin-top: calc(20rem/16);
    padding: calc(25rem/16) calc(20rem/16);
    
    @media screen and (min-width: 768px){
       min-width: calc(270rem/16);
        padding: calc(45rem/16) calc(40rem/16);
    }
}

.dropdown--arrow .dropdown-menu:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 20px;
    height: 20px;
    box-shadow: 0 0 20px 0 rgba(0,0,0,0.2);
    transform: rotate(45deg) translateX(-50%);
    background-color: #fff;

    @media screen and (min-width: 768px){
        left: 63.5%;
    }

    @media screen and (min-width: 1550px){
        left: 50%;
    }
}
.dropdown--arrow .dropdown-menu:after {
    content: '';
    position: absolute;
    height: calc(24rem/16);
    left: 0;
    right: 0;
    background-color: #fff;
    top: 0;
}

.dropdown--search .dropdown-menu{
    @media screen and (max-width: 767px){
        position: fixed;
        top: calc(45rem/16);
        left: 0;
        right: 0;
        transform: none;
    }
}

.profile-dropdown.dropdown-menu{
    @media screen and (min-width: 768px){
        left: -50%;
    }

    @media screen and (min-width: 768px){
        left: -50%;
        padding: calc(35rem/16) calc(32rem/16);
    }

    @media screen and (min-width: 1550px){
        left: 50%;
        padding: calc(45rem/16) calc(40rem/16);
    }
}