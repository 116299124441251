.floating-label {
    position: absolute;
    left: calc(12rem/16);
    top: 50%;
    transform: translateY(-50%);
    transition: transform 200ms ease-in-out;
    transform-origin: left top;
    pointer-events: none;
    z-index: 1;
    color: #d4d4d4;
}


.form-group__white .floating-label{
    color: #fff;
}

textarea.form-control.has-value ~ .floating-label,
textarea.form-control:focus ~ .floating-label{
    transform: translateY(-106%) scale(.7);
    font-size: calc(14rem/16);
}
.form-control:focus ~ .floating-label,
select ~ .floating-label,
.form-control.has-value ~ .floating-label,
.form-group.has-value .floating-label {
    transform: translateY(-106%) scale(.7);
    font-size: calc(14rem/16);

}

.form-control:-internal-autofill-selected ~ .form-control-label,
.form-control:-webkit-autofill ~ .form-control-label,
.form-control:-webkit-autofill:focus ~ .form-control-label {
    transform: translateY(calc(-106%)) scale(.7);
    font-size: calc(14rem/16);

}

.form-group__search .floating-label{
    left: calc(40rem/16);
}