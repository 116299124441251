.content-block + .content-block,
.content-block + style + .content-block,
.content-block-small + .content-block,
.content-block-small + style + .content-block,
.content-block + .pimcore_area_content > .content-block,
.content-block + style + .pimcore_area_content > .content-block,
.pimcore_area_content + style + .pimcore_area_content > .content-block,
.pimcore_area_content + .pimcore_area_content > .content-block {
    margin-top: calc(30rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(80rem/16);
    }
}

.content-block-small + .content-block-small,
.content-block-small + style + .content-block-small,
.content-block + .content-block-small,
.content-block + style + .content-block-small,
.content-block-small + .pimcore_area_content > .content-block-small,
.content-block-small + style + .pimcore_area_content > .content-block-small,
.content-block + .pimcore_area_content > .content-block-small,
.content-block + style + .pimcore_area_content > .content-block-small {
    margin-top: calc(30rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(60rem/16);
    }
}
.content-block-padding{
    padding:calc(80rem/16) 0 calc(120rem/16) 0;
}