.embed-responsive-topic-teaser:before{
    padding-top: 47.2527%;
}
.embed-responsive-cover{
    height: 100%;
}

.embed-responsive-cover .embed-responsive-item{
    width: auto;
    min-height: 100%;
    left: 50%;
    transform: translateX(-50%);
}

@media screen and (min-width: 768px){
    .embed-responsive-cover-md{
        height: 100%;
    }

    .embed-responsive-cover-md .embed-responsive-item{
        width: auto;
        min-height: 100%;
        left: 50%;
        transform: translateX(-50%);
    }
}

.embed-responsive-md-4by3:before{
    @media screen and (min-width: 768px){
       padding-top: 75%;
    }
}

.embed-responsive-full-width-teaser:before {
    padding-top: 50%;

    @media screen and (min-width: 768px){
        padding-top: 33.33333%;
    }
}

.embed-responsive-7by2:before{
    @media screen and (min-width: 768px) {
        padding-top: 28.571428571%;
    }
}
.embed-responsive-15by8-xs:before{
    @media screen and (max-width: 767px) {
        padding-top: 53.3333333%;
    }
}

.embed-responsive-3by1:before{
    @media screen and (min-width: 768px) {
        padding-top: 33.333333333%;
    }
}
.embed-responsive-11by8:before{
    @media screen and (min-width: 768px) {
        padding-top: 137.5%;
    }
}
.embed-responsive-13by7-xs:before{
    @media screen and (max-width: 767px) {
        padding-top: 53.846153846%;
    }
}