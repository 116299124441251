.image-thumb-swiper-nav{
    margin-top: calc(20rem/16);
}

.image-thumb-swiper-nav .swiper-slide-thumb-active{
    position: relative;
}

/*.image-thumb-swiper-nav .swiper-slide:before,
.image-thumb-swiper-nav .swiper-slide.swiper-slide-thumb-active:before{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(225deg,rgba(255,237,0,0),#ffed00);
    opacity: 0.3;
    z-index: 1;
}*/

.image-thumb-swiper-nav .swiper-slide:before{
    opacity: 0;
    transition: all 250ms ease;
}

.image-thumb-swiper-nav .swiper-slide:hover:before{
    opacity: 0.3;
}

.image-thumb-swiper-main{
    position: relative;
}

.image-thumb-slider-area img{
    border-radius: calc(3rem/16);
}