.cart-joe-banner {
    background-color: var(--color-white);
    border: var(--color-joe) solid calc(1rem/16);
    border-radius: calc(3rem/16);
}

.cart-joe-banner__content {
    @media screen and (min-width: 768px) {
        padding: calc(24rem/16) calc(32rem/16) calc(16rem/16);
    }
}

.cart-joe-banner__header {
    padding: calc(24rem/16) calc(20rem/16);
    border-bottom: var(--color-grey) solid calc(1rem/16);

    @media screen and (min-width: 768px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0 calc(16rem/16) 0;
    }
}

.cart-joe-banner__headline {
    font-family: var(--font-default-medium);
    font-size: calc(16rem / 16);
    line-height: calc(27 / 16);
    display: flex;
    align-items: center;
    gap: calc(10rem / 16);
}

.cart-joe-banner__headline .icon-joe {
    font-size: calc(27rem/16);
    color: var(--color-joe);
}

.cart-joe-banner__text {
    margin-top: calc(14rem/16);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: var(--font-default);
    font-size: calc(16rem/16);
    line-height: calc(27/16);
    color: var(--color-dark-grey);

    @media screen and (min-width: 768px) {
        margin-top: 0;
        justify-content: left;
        gap: calc(8rem/16);
    }
}

.cart-joe-banner__body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: calc(20rem/16);
    margin-top: calc(20rem/16);
}

.cart-joe-banner__discount-item + .cart-joe-banner__discount-item {
    margin-top: calc(10rem/16);
}

.cart-joe-banner__discount {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    font-size: calc(14rem/16);
    line-height: calc(21/14);
    width: 100%;
    padding: calc(12rem/16) calc(20rem/16) calc(12rem/16) calc(54rem/16);
    background-color: var(--color-light-grey);
    border: var(--color-grey) solid calc(1rem/16);
    border-radius: calc(3rem/16);

    &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: calc(20rem/16);
        width: calc(20rem/16);
        height: calc(20rem/16);
        border: var(--color-text-default) solid calc(1rem/16);
        border-radius: calc(3rem/16);
        transform: translateY(-50%);
    }

    &.is-active:before {
        background-color: var(--color-joe);
        border-color: var(--color-joe);
    }

    &.is-active:after {
        content: var(--icon-check);
        font-family: 'iconfont';
        font-size: calc(12rem/16);
        color: var(--color-white);
        position: absolute;
        top: 50%;
        left: calc(24rem/16);
        transform: translateY(-50%);
    }

    &:disabled {
        cursor: default;
        color: var(--color-dark-grey);
    }

    &:disabled:before {
        border-color: var(--color-dark-grey);
    }
}

.cart-joe-banner__discount-validity {
    font-size: calc(12rem/16);
    line-height: calc(18/12);
    color: var(--color-dark-grey);
}

.cart-joe-banner__discount-list {
    display: flex;
    flex-direction: column;
    grid-column: 1/3;
    padding-bottom: calc(20rem/16);
    border-bottom: .0625rem solid var(--color-grey);
}

.cart-joe-banner__discount-list .cart-joe-banner__text {
    justify-content: space-between;
}

.cart-joe-banner__value {
    display: flex;
    align-items: center;
    background-color: var(--color-light-grey);
    padding: calc(4rem/16) calc(4rem/16) calc(4rem/16) calc(12rem/16);
    border-radius: calc(14rem/16);
    height: fit-content;
    margin-left: calc(20rem/16);

    @media screen and (min-width: 768px) {
        margin-left: 0;
    }
}

.cart-joe-banner__value .cart-joe-banner__point-icon {
    margin-left: calc(4rem/16);
}

.cart-joe-banner__added-value {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: calc(4rem/16);
    font-size: calc(16rem/16);
    line-height: calc(27/16);
    margin-right: calc(20rem/16);

    @media screen and (min-width: 768px) {
        margin-right: 0;
        height: fit-content;
    }
}

.cart-joe-banner__points {
    font-family: var(--font-default-medium);
    font-size: calc(16rem/16);
    line-height: calc(27/16);
    color: var(--color-text-default);
    display: flex;
    align-items: center;
    gap: calc(4rem/16);
}

.cart-joe-banner__discount:disabled .cart-joe-banner__points {
    color: var(--color-dark-grey);
}

.cart-joe-banner__point-icon {
    font-size: calc(20rem/16);
    border-radius: 50%;
    color: var(--color-joe);
}

.cart-joe-banner__footer {
    font-family: var(--font-default-medium);
    font-size: calc(16rem/16);
    line-height: calc(27/16);
    color: var(--color-white);
    margin-top: calc(20rem/16);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: calc(16rem/16) calc(20rem/16);
    background-color: var(--color-joe);
    border-radius: 0 0 calc(2rem/16) calc(2rem/16);
    
    @media screen and (min-width: 768px) {
        margin-top: 0;
        padding: calc(16rem/16) calc(32rem/16);
        display: grid;
        grid-template-columns: 1fr auto;
        column-gap: calc(104rem/16);
        text-align: right;
    }
}

.cart-joe-banner__footer .cart-joe-banner__points {
    color: var(--color-white);
    margin-left: auto;
}

.cart-joe-banner__footer .cart-joe-banner__point-icon {
    color: var(--color-white);
}

.cart-joe-banner--not-editable .cart-joe-banner__body {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(2, auto);
    justify-content: space-between;
    align-items: baseline;
    padding: 0 calc(20rem/16);

    @media screen and (min-width: 768px) {
        padding: 0;
    }
}

.cart-joe-banner--not-editable .cart-joe-banner__value {
    width: fit-content;
    margin-left: 0;
}

.cart-joe-banner--not-editable .cart-joe-banner__added-value {
    margin-right: 0;
}

.cart-joe-banner--not-editable .cart-joe-banner__footer {
    @media screen and (min-width: 768px) {
        text-align: left;
    }
}