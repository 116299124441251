.btn-action-list{
    margin-top: calc(20rem/16);

    @media screen and (min-width: 768px){
        margin-top: calc(40rem/16);
    }
}

.btn-action-list>li{
    margin-bottom: calc(15rem/16);
}

.btn-action-list .list-inline-item:not(:last-child){
    margin-right: calc(6rem/16);

    @media screen and (min-width: 768px){
        margin-right: calc(40rem/16);
    }
}

.btn-action-list__btn{
    margin-left: calc(5rem/16);
    vertical-align: calc(-3rem/16);
}

.btn-action-list__btn-icon{
    width: calc(30rem/16);
    height: calc(30rem/16);
    border-radius: 50%;
    background-color: var(--color-grey);
    font-size: calc(16rem/16);
    display: inline-flex;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 768px){
        width: calc(32rem/16);
        height: calc(32rem/16);
    }
}

.btn-action-list__btn-icon .icon-mail{
    font-size: calc(12rem/16);
}

.is-active .btn-action-list__btn .btn-action-list__btn-icon{
    background-color: var(--color-primary);
    color: var(--color-secondary);
}


.btn-action-list__btn-icon .icon-heart-outline{
    font-size: calc(13rem/16);
}

.is-active .btn-action-list__btn-icon .icon-heart-outline:before{
    content: var(--icon-heart);
}

.btn-action-list__wrapper:hover:not(.btn-action-list__wrapper--disabled) .btn-action-list__btn-icon{
    background-color: var(--color-primary);
}

.btn-action-list__btn-icon.facebook{
    background-color: #4267B2;
    color: #fff;
}
