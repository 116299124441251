.badge{
    font-size: calc(12rem/16);
    font-weight: 400;
    font-family: var(--font-block);
    padding: .5em .5em .3em;
    line-height: 1;
    border-radius:calc(3rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(20rem/16);
        padding: calc(6em/20) calc(10em/20) calc(4em/20);
    }
}

.badge--new{
    background-color: var(--color-primary);
    color: var(--color-secondary);
    text-transform: uppercase;
}

.badge--sale--uppercase{
    background-color: var(--color-secondary);
    color: var(--color-primary);
    text-transform: uppercase;
}

.badge--sale {
    background-color: var(--color-secondary);
    color: var(--color-primary);
}

.badge--variants {
    background-color: var(--color-grey);
    color: var(--color-secondary);
}

.badge--teaser.is-top-left{
    position: absolute;
    top: calc(10rem/16);
    left: 0;
    z-index: 2;
    border-radius: 0 calc(3rem/16) calc(3rem/16) 0;
}

.badge--teaser.is-top-right{
    position: absolute;
    top: calc(10rem/16);
    right: 0;
    z-index: 2;
    border-radius: calc(3rem/16) 0 0 calc(3rem/16);
}

.product-teaser .badge{
    @media screen and (min-width: 768px) and (max-width: 1199px){
        font-size: calc(15rem/16);
    }
}

.badge--size-lg {
    font-size: calc(18rem/16);

    @media screen and (min-width: 768px){
        font-size: 30px;
    }
}