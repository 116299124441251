.joe-benefits-teaser {
    font-size:calc(15rem/16);
    padding:calc(20rem/16);
    border: 1px solid var(--color-grey);
    border-radius: calc(3rem/16);
    background-color: #F6F6F6;

    @media screen and (min-width: 768px){
        height:100%;
        font-size:calc(16rem/16);
        padding:calc(30rem/16);
    }
}
.joe-benefits-teaser__title {
    margin-bottom: calc(20rem/16);
    font-family: var(--font-default-medium);
    font-size:calc(17rem/16);
    @media screen and (min-width: 768px){
        font-size:calc(20rem/16);
    }
}
.joe-benefits-teaser__title--cart {
    font-size:calc(15rem/16);
    font-family: var(--font-default);
    @media screen and (min-width: 768px){
        font-size:calc(17rem/16);
    }
}
.joe-benefits-teaser--cart .joe-benefits-teaser__card {
    margin-top: -1.5rem;
}