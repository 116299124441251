html:not(.is-advert) {
    /* Table scaling */
    @media screen and (min-width: 768px) and (max-width: 991px) {
        font-size: calc(16 / (991 / 100) * 1vw);
    }
}
body {
    color: var(--color-text-default);
    font-family: var(--font-default);
    font-size: calc(13rem/16);
    @media screen and (min-width: 768px){
        font-size: var(--font-size-default);
        overflow-x: hidden;
    }
}
.font-default {
    font-family: var(--font-default);
    font-weight: var(--font-default-weight);
}
b,
strong,
.strong {
    font-family: var(--font-default-bold);
    font-weight: normal;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    display: block;
    color: inherit;
    font-weight: normal;
}

h1, .h1 {
    font-size: calc(25rem/16);
    line-height: calc(39/32);
    font-family: var(--font-default-medium);
}
h2, .h2 {
    font-size: calc(22rem/16);
    line-height: calc(48/40);
    font-family: var(--font-default);
}
h3, .h3 {
    font-size: calc(18rem/16);
    line-height: calc(29/24);
    font-family: var(--font-default-medium);
}
h4, .h4 {
    font-size: calc(18rem/16);
    line-height: calc(24/20);
    font-family: var(--font-default-medium);
}
h5, .h5 {
    font-size: calc(18rem/16);
    line-height: calc(21/18);
    font-family: var(--font-default-medium);
}
h6, .h6 {
    font-size: 1rem;
    font-family: var(--font-default-medium);
}

@media screen and (min-width: 768px){
    h1, .h1 {
        font-size: calc(32rem/16);
    }
    h2, .h2 {
        font-size: calc(40rem/16);
    }
    h3, .h3 {
        font-size: calc(24rem/16);
    }
    h4, .h4 {
        font-size: calc(20rem/16);
    }
}
