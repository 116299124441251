.img-text-area{
    padding: 25px 0;
}

.img-text-area.text-color-theme-dark{
    color: var(--color-text-default);
}

.img-text-area.text-color-theme-light{
    color: #fff;
}

.img-text__title{
    font-family: var(--font-default-bold);
    @media screen and (min-width: 768px){
       font-size: calc(38rem/16);
    }
}

.img-text__toptitle{
    font-family: var(--font-default-bold);
    font-size: calc(15rem/16);
    text-transform: uppercase;
    margin-bottom: calc(5rem/16);
}

.img-text__wysiwyg{
    margin: calc(15rem/16) 0;

    @media screen and (min-width: 768px){
       margin: calc(20rem/16) 0;
    }
}

.img-text-swiper{
    position: relative;
    padding-bottom: calc(35rem/16);

    @media screen and (min-width: 768px){
        padding-bottom: calc(55rem/16);
    }
}

.img-text--big .img-text__title{
    font-size: calc(32rem/16);
    @media screen and (min-width: 768px){
       font-size: calc(48rem/16);
    }
}

.img-text--big .img-text__toptitle{
    margin-bottom: calc(15rem/16);

    @media screen and (min-width: 768px){
        margin-bottom: calc(20rem/16);
    }
}

.img-text--big .img-text__wysiwyg{
    margin-bottom: calc(20rem/16);

    @media screen and (min-width: 768px){
        margin-bottom: calc(30rem/16);
    }
}


.img-text--big .img-text__content{
    @media screen and (min-width: 768px){
       padding-top: calc(40rem/16);
    }
    
    padding-top: calc(30rem/16);
}

.img-text-swiper .swiper-button{
    bottom: 0;
    top: auto;
    transform: translateY(0);
    background-color: transparent;
    color: var(--color-text-default);
    font-size: calc(16rem/16);
    
    @media screen and (min-width: 768px){
        font-size: calc(20rem/16);
    }
}

.img-text-swiper .swiper-button--next{
    right: 0;

    @media screen and (min-width: 768px){
        right: calc(7rem/16);
    }
}


.img-text-swiper .swiper-button--prev{
    right: calc(38rem/16);
    left: auto;

    @media screen and (min-width: 768px){
        right: calc(45rem/16);
    }
}

.img-text-swiper .swiper-button.swiper-button-disabled {
    display: flex;
    opacity: 0.4;
    cursor: auto;
}

.img-text-swiper .swiper-pagination{
    bottom: calc(10rem/16);
}

.img-text-swiper .swiper-pagination-bullet{
    margin-right: calc(15rem/16);
    position: relative;
}

.img-text-swiper .swiper-pagination-bullet:hover{
    background-color: var(--color-primary-dark);
    opacity: 1;
}

.img-text-swiper .swiper-pagination-bullet-active:before {
    content: '';
    position: absolute;
    top: -4px;
    left: -4px;
    right: -4px;
    bottom: -4px;
    border-radius: 50%;
    background-color: var(--color-primary-dark);
    z-index: 7;
}

.img-text-swiper .swiper-start-stop{
    position: absolute;
    bottom: calc(-4rem/16);
    right: calc(67rem/16);
    color: var(--color-text-default);

    @media screen and (min-width: 768px){
        bottom: calc(1rem/16);
        right: calc(90rem/16);
    }
}

.img-text-swiper .swiper-start-stop.is-paused .icon:before{
    content: var(--icon-play-big);
}

.img-text-swiper .swiper-counter{
    font-family: var(--font-default-bold);
    position: absolute;
    top: 0;
}