

.reservation__select-wrapper{
    border: 1px solid var(--color-default);
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    height: calc(37rem/16);

    @media screen and (min-width: 768px){
        height: calc(46rem/16);
    }
    
}

select.form-control:not([size]):not([multiple]).reservation__select{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: calc(12rem/16);
    color: var(--color-default);
    
    @media screen and (max-width: 767px){
       height: calc(37rem/16);
    }
}
.form-control:not([size]):not([multiple]).reservation__select option:disabled {
    color: var(--color-middle-grey);
}

.reservation__btn.btn{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: 1px solid var(--color-default);
    border-left: 0;
}

.reservation__btn.btn:disabled{
    opacity: 1;
    background-color: #e9e9e9;
    color: #b4b4b4;
}

.reservation .store-select__icon{
    left: calc(10rem/16);
    font-size: calc(13rem/16);
    top: calc(10rem/16);

    @media screen and (min-width: 768px){
        left: calc(12rem/16);
        top: calc(17rem/16);
    }
}

.reservation__checkbox label{
    color: var(--color-danger);
    font-size: calc(13rem/16);

    font-family: var(--font-default-medium);
}

.reservation__checkbox .custom-checkbox__box{
    width: calc(14rem/16);
    height: calc(13rem/16);
    border-radius: 0;
}

.reservation__checkbox .custom-checkbox__box:before{
    font-size: calc(8rem/16);
}

.reservation__checkbox.custom-checkbox {
    padding-left: calc(26rem/16);
}