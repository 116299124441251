.card-primary{
    border: none;
    border-radius: calc(3rem/16);
    padding: calc(18rem/16) calc(20rem/16);
}

.card-primary .card-header{
    background: var(--color-primary);
    border: none;
}

.card-primary .card-body{
    background-color: var(--color-light-grey);
}

.card.bg-light{
    border-color: var(--color-light-grey);
}

.card-lg{
    font-size: calc(16rem/16);
}

.card-body--small {
    @media screen and (min-width: 768px) {
        padding-left: calc(88rem/16);
        padding-right: calc(88rem/16);
    }
}

.card-small-content {
    @media screen and (min-width: 768px) {
        padding-left: calc(40rem/16);
        padding-right: calc(40rem/16);
    }
}