.img-text-teaser {
    margin-bottom: calc(40rem/16);
}
.img-text-teaser__img {
    border-radius: 3px;
    overflow: hidden;
    margin-bottom: calc(20rem/16);
}
.img-text-teaser__title {
    font-size: calc(28rem/16);
    font-family: var(--font-default-medium);
    line-height: calc(34/30);
    margin-bottom: calc(10rem/16);
}
.img-text-teaser__content {
    font-size: 1rem;
    line-height: calc(27/16);
    color: #949494;
    font-family: var(--font-default);
}
@media (min-width: 768px){

    .img-text-teaser__img {
        margin-bottom: calc(30rem/16);
    }
    .img-text-teaser__title {
        font-size: calc(30rem/16);
        font-family: var(--font-default);
        line-height: calc(36/30);
        margin-bottom: calc(15rem/16);
    }
    .img-text-teaser__content {
        line-height: calc(26/16);
    }
}