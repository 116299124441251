.blog-sharing__title {
    font-size: 1rem;
    color: #949494;
    font-family: var(--font-default);
    line-height: calc(24/16);
}
.blog-sharing__container {
    border-top: 1px solid #e9e9e9;
    padding-top: calc(59rem/16);
}
@media (max-width: 767px){
    .blog-sharing {
        margin-top: 0!important;
    }
}