.service-area .title-block--center-strong{
    @media screen and (max-width: 767px){
       text-align: left;
    }
}

.service{
    display: flex;
    align-items: center;
    font-size: calc(15rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(16rem/16);
    }
}

.service__icon{
    font-size: calc(40rem/16);
    color: var(--color-primary);
    margin-right: calc(15rem/16);
}

.service__title{
    font-family: var(--font-default-bold);
}