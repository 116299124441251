.navbar-top{
    background-color: var(--color-primary);
    font-size: calc(14rem/16);
    position: relative;
    left: 0;
    right: 0;
    z-index: 101;
    @media screen and (min-width: 768px){
        display: flex;
        align-items: center;
       height: calc(60rem/16);
    }
}

.navbar-top.is-affix{
    position: fixed;
}

.navbar-top.is-transitioning {
    position: absolute;
}

.navbar-top-right{
    margin-bottom: 0;
}

.navbar-top-right .list-inline-item:not(:last-child){
    margin-right: calc(12rem/16);
    @media screen and (min-width: 1200px){
        margin-right: calc(34rem/16);
    }

    @media screen and (min-width: 1050px){
        margin-right: calc(22rem/16);
    }
}

.navbar-top-right__item .icon{
    margin-right: calc(5rem/16);
    font-size: calc(17rem/16);
    vertical-align: calc(-3rem/16);
    @media screen and (min-width: 768px){
       width: calc(18rem/16);
        display: inline-block;
    }
}

.navbar-top-right__item:hover{
    text-decoration: underline;
}

.navbar-top a{
    @media screen and (min-width: 768px){
       color: var(--color-default);
        font-family: var(--font-default);
    }
}