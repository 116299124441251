.accordion--orders .card{
    display: block;
}

.accordion--orders>.card:first-of-type{
    border: 1px solid var(--color-grey);
}
.accordion--orders .card-body{
    padding: calc(20rem/16);

    @media screen and (max-width: 767px){
        padding: calc(10rem/16);
        font-size: calc(14rem/16);
    }
}

.accordion--orders .accordion__header-link{
    font-family: var(--font-default);
    font-size: calc(14rem/16);
}
.accordion--orders .accordion__toggle-circle {
    background-color: #fff;
}

.accordion--orders .card--order-address .card-body{
    @media screen and (max-width: 767px){
       padding: calc(15rem/16);
    }
}
