.img-text-article__title {
    font-family: var(--font-default-medium);
    font-size: 2rem;
}
.img-text-article__toptitle {
    font-family: var(--font-default-medium);
    font-size: 1rem;
    margin-bottom: 0.5rem;
}
.img-text-article__wysiwyg {
    font-family: var(--font-default-medium);
    margin-bottom: 1.5rem;
}
.img-text-article__btn {
    font-family: var(--font-default-medium);
}