.info-banner__content-container {
    background-color: var(--color-primary);
    padding: calc(26rem/16) calc(30rem/16);
    border-radius: calc(3rem/16);
    width: 100%;
    flex-wrap: wrap;
}
.info-banner__content {
    font-size: calc(20rem/16);
    font-family: var(--font-default-medium);
    line-height: calc(24/20);
    margin-bottom: calc(20rem/16);
}
.info-banner__btn-wrapper{
    width: 100%;
}
.info-banner__btn {
    font-size: 1rem;
    width: 100%;
}
@media (min-width: 768px){
    .info-banner__content-container {
        padding: calc(35rem/16) calc(50rem/16);
    }
    .info-banner__content {
        margin-bottom: 0;
    }
    .info-banner__btn-wrapper,
    .info-banner__btn{
        width: auto;
    }
}