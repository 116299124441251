.quote{
    position: relative;
    margin-bottom: calc(40rem/16);
}

.quote:after{
    content: '“';
    position: absolute;
    bottom: calc(-65rem/16);
    left: 50%;
    transform: translateX(-50%);
    font-size: calc(400rem/16);
    color: var(--color-grey);
    line-height: 0;
    font-family: var(--font-default-bold);
    z-index: -1;
}

.quote__text{
    font-family: var(--font-default-bold);
    font-size: calc(20rem/16);
    line-height: calc(24/20);
    color: var(--color-dark-grey);
}

.quote__author{
    font-size: calc(20rem/16);
    margin-top: calc(20rem/16);
}