.mt-32{
    margin-top: calc(32rem/16);
}

.mb-40{
    margin-bottom: calc(30rem/16);
    @media screen and (min-width: 768px){
       margin-bottom: calc(40rem/16);
    }
}

.mt-40{
    margin-top: calc(20rem/16);
    @media screen and (min-width: 768px){
        margin-top: calc(40rem/16);
    }
}

.fs-20{
    font-size: calc(20rem/16);
}

.fs-18{
    font-size: calc(18rem/16);
}

.fs-16{
    font-size: calc(16rem/16);
}

.fs-14{
    font-size: calc(14rem/16);
}

.fs-12{
    font-size: calc(12rem/16);
}

/* Text Helper */
.text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Text Color Helper */
.text-white { color: #ffffff; }
.text-color-default { color: var(--color-text-default); }
.text-dark-grey { color: var(--color-dark-grey); }

.text-color-theme-dark .text-color-theme-container {
    color: var(--color-text-default);
}

.text-color-theme-light .text-color-theme-container{
    color: #fff;
}


/* Background Color Helper */
.bg-light-grey{
    background-color: var(--color-light-grey);
}

.bg-grey {
    background-color: var(--color-grey);
}

/* JÖ */
.bg-joe{
    background-color: var(--color-joe);
}
.text-joe{
    color: var(--color-joe);
}

/* Additional */
.increased-click-area {
    padding: calc(8rem/16);
    margin: calc(-8rem/16);
}

.vertical-align-middle ,
.table--compare td.vertical-align-middle{
    vertical-align: middle;
}


.font-bold{
    font-family: var(--font-default-bold);
}
.font-medium{
    font-family: var(--font-default-medium);
}

.font-default{
    font-family: var(--font-default);
}

.link-with-arrow{
    white-space: nowrap;
    display: inline-block;
    font-size: calc(14rem/16);
    
    @media screen and (min-width: 768px){
       font-size: calc(16rem/16);
    }
}

.link-with-arrow .icon{
    font-size: calc(11rem/16);
    margin-right: calc(4rem/16);
}

.js-go-to-link{
    cursor: pointer;
}

.text-small{
    font-size: calc(14rem/16);
}
.text-xsmall{
    font-size: calc(12rem/16);
}

@media screen and (max-width: 767px){
    .sr-only-xs {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        white-space: nowrap;
        border: 0;
    }
}


.text-underline{
    text-decoration: underline;
}

.vh-70 {
    height: 70vh;
}

.vh-md-100 {
    @media screen and (min-width: 768px){
        height: 100vh;
    }
}

.w-xs-100{
    @media screen and (max-width: 767px){
       width: 100%;
    }
}

.w-md-50 {
    @media screen and (min-width: 768px) {
        width: 50%!important;
    }
}

.mt-auto-xs{
    @media screen and (max-width: 767px){
       margin-top: auto!important;
    }
}

.h-100-xs{
    @media screen and (max-width: 767px){
       height: 100%;
    }
}

.small, small{
    @media screen and (max-width: 767px){
       font-size: calc(12rem/16);
    }
}

.cursor-not-allowed{
    cursor: not-allowed;
}

.isCursor{
    cursor: pointer;
}

select:hover,
.select:hover{
    cursor: pointer;
}

.ajax-form-map__map button:not(.gm-control-active){
    @media screen and (max-width: 767px){
        font-size: 14px!important;
        padding: 0 14px!important;
        height: 30px!important;
    }

}
.object-fit-cover {
    object-fit:cover;
}
.object-fit-contain {
    object-fit:contain;
}

.top-0 {
    top: 0;
}

.left-0 {
    left: 0;
}

.g-2 {
    gap: calc(8rem/16) !important;
}

.g-3 {
    gap: calc(16rem/16) !important;
}

.w-md-40 {
    @media (min-width: 768px) {
        width: 40%!important;
    }
}