/*Base-Styling for HTML-Elements*/

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
}
th {
    font-family: var(--font-default-bold);
    font-weight: normal;
}
textarea {
    resize: vertical;
}

label {
    font-weight: normal;
    margin-bottom: 0;
}
ul,
ol,
dl {
    margin-bottom: 0;
}
dt {
    font-weight: normal;
}

*::-moz-selection {
    color: #ffffff;
    background-color:  var(--color-primary);
}
*::selection {
    color: #ffffff;
    background-color: var(--color-primary);
}
video {
    background: #000000;
}
.lg-actions .lg-next.lg-icon,
.lg-actions .lg-prev.lg-icon{
    padding: 11px 10px 9px 10px;
}
.lg-actions .lg-prev:after {
    content: var(--icon-arrow-left);
    font-family: 'iconfont';
}
.lg-actions .lg-next:before {
    content: var(--icon-arrow-right);
    font-family: 'iconfont';
}
.lg-toolbar .lg-close:after {
    content: var(--icon-close);
    font-family: 'iconfont';
}