.file-upload__item{
    color: var(--color-default);
    text-align: left;
    position: relative;
    display: block;
    margin-bottom: calc(10rem/16);
}

.file-upload__item img{
    height: calc(60rem/16);
    width: auto;
}

.file-upload__item--text{
    width: calc(230rem/16);
    margin: 0 auto calc(10rem/16);
    border: 1px solid var(--color-light-grey);
    padding: 0 calc(5rem/16);
    border-radius: calc(3rem/16);
    margin-right: calc(15rem/16);
    display: inline-block;
    word-break: break-word;
}

.file-upload__item--img{
    display: inline-block;
}

.file-upload__item--img:not(:last-child){
    margin-right: calc(20rem/16);
}

.file-upload__remove .icon{
    vertical-align: calc(-3rem/16);
}

.file-upload__preview-img{
    width: calc(100rem/16);
    border: 1px solid var(--color-grey);
    border-radius: 3px;
    padding: calc(10rem/16);
    background-color: #fff;
    margin-bottom: calc(10rem/16);
}

.file-upload__item--img{
    border: 1px solid var(--color-grey);
    padding: calc(10rem/16);
    border-radius: calc(3rem/16);
}