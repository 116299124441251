.table--compare{
    border-bottom: 1px solid #dee2e6;
}

.table--compare th {
    background-color: var(--color-light-grey);
    vertical-align: top;
    text-align: center;
    padding: calc(15rem/16);
    width: 16%;
    border-left: 1px solid #dee2e6;
}

.table--compare td {
    text-align: center;
    width: 21%;
    min-width: 21%;
    max-width: 21%;
    border-right: 1px solid #dee2e6;
    position: relative;
    
    @media screen and (min-width: 768px){
        width: calc(314rem/16);
    }
}

.table--compare .price-badge{
    margin: 0 auto;
}