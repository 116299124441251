.benefit-item{
    text-align: center;
    padding: calc(8rem/16) calc(16rem/16);

    @media screen and (min-width: 768px){
        padding: calc(16rem/16) calc(42rem/16);

    }
}
.benefit-item__icon{
    font-size: 3rem;
    color: var(--color-primary);
}