.cart-item {
    padding: calc(10rem/16);
    background-color: var(--color-white);
    border: var(--color-grey) solid calc(1rem/16);
    border-radius: calc(3rem/16);

    &:hover .cart-item__img img {
        transform: scale(1.05);
    }

    @media screen and (min-width: 768px) {
        padding: calc(16rem/16);
    }
}

.cart-item.is-highlight {
    border-color: var(--color-primary);
}

.cart-item__form {
    display: grid;
    column-gap: calc(10rem/16);
    row-gap: calc(20rem/16);
    grid-template-columns: 2fr 4fr;
    grid-template-areas: 'img info'
                        'amount amount'
                        'singlePrice singlePrice'
                        'totalPrice totalPrice';

    @media screen and (min-width: 768px) {
        gap: calc(20rem/16);
        grid-template-columns: 1.3fr 4fr 2fr 1.2fr 2.5fr;
        grid-template-areas: 'img info amount singlePrice totalPrice';
        align-items: center;
    }
}

.cart-item--simple .cart-item__form {
    grid-template-areas: 'img info'
                        'totalPrice totalPrice';

    @media screen and (min-width: 768px) {
        grid-template-areas: 'img info info totalPrice totalPrice';
        align-items: flex-end;
    }
}

.cart-item__img {
    grid-area: img;

    & .embed-responsive {
        overflow: visible;
    }

    & img {
        transition: transform 0.2s ease-in-out
    }

    &:hover img{
        transform: scale(1.05);
    }

    &:has( ~ .cart-item__amount:not(.cart-item__amount--gift):hover) img {
        transform: scale(1);
    }
}

.cart-item__info {
    grid-area: info;
    display: flex;
    flex-direction: column;
    justify-content: left;
}

.cart-item--simple .cart-item__info {
    @media screen and (min-width: 768px) {
        padding-bottom: calc(10rem/16);
    }
}

.cart-item__amount {
    grid-area: amount;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (min-width: 768px) {
        justify-content: left;
    }
}

.cart-item__amount--gift {
    margin-left: calc(40rem/16);
    gap: calc(8rem/16);
}

.cart-table__remove-btn.btn.btn-icon {
    color: var(--color-grey);
    padding-left: 0;
    border: 0;
    width: auto;

    &:hover {
        color: var(--color-danger);
    }
}

.cart-table__remove-btn .icon {
    margin-right: calc(13rem/16);
}

.cart-item__price {
    font-size: calc(14rem/16);
    line-height: calc(21/14);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 calc(10rem/16);
    padding-bottom:  calc(10rem/16);
    border-bottom: var(--color-grey) solid calc(1rem/16);

    @media screen and (min-width: 768px) {
        justify-content: flex-end;
        border-bottom: none;
        margin: 0;
        padding-bottom: 0;
    }
}

.cart-item--simple .cart-item__price {
    @media screen and (min-width: 768px) {
        justify-content: right;
    }
}

.cart-item__price-tag {
    @media screen and (min-width: 768px) {
        display: none;
    }
}

.cart-item__price.cart-item__price--single {
    grid-area: singlePrice;
    justify-content: space-between;
}

.cart-item__price--total {
    grid-area: totalPrice;
}


.cart-item--simple .cart-item__price--total {
    @media screen and (min-width: 768px) {
        padding-bottom: calc(10rem/16);
        padding-right: calc(18rem/16);
    }
}

.cart-item--gift .cart-item__form {
    grid-template-areas: 'img info'
                        'amount totalPrice';
}

.cart-item--gift .cart-item__form,
.cart-item--gift.cart-item--simple .cart-item__form {
    @media (min-width: 768px) {
        grid-template-areas: 'img info amount totalPrice totalPrice';
        align-items: center;
    }
}

.cart-item--gift .cart-item__amount {
    margin-left: calc(10rem/16);
    justify-content: flex-start;
    align-items: flex-end;
    padding-bottom: calc(10rem/16);
}

.cart-item--gift.cart-item--simple .cart-item__price--total {
    margin-right: calc(10rem/16);
    padding-bottom: calc(10rem/16);
}