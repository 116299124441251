.accordion .card.bg-light{
    border-color: transparent;
}

.accordion>.card+.card{
    margin-top: calc(4rem/16);
}

.accordion .card-header{
    padding: 0;
    border: none;
    background-color: transparent;
}

.accordion__header-link{
    padding: calc(14rem/16) calc(20rem/16) calc(18rem/16);
    display: block;
    font-family: var(--font-default-bold);
    font-size: calc(16rem/16);
}

.accordion--content .accordion__header-link{
    border-top: calc(4rem/16) solid var(--color-primary);
}

.accordion--content .accordion__header-link.collapsed{
    border-color: transparent;
}

.accordion__toggle-circle{
    width: calc(23rem/16);
    height: calc(23rem/16);
    border-radius: 50%;
    background-color: var(--color-light-grey);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(6rem/16);
}

.accordion__toggle-icon{
    transform: translateY(-1px) rotate(180deg);
    font-size: calc(10rem/16);
}

.collapsed .accordion__toggle-icon{
    transform: translateY(1px) rotate(0deg);
}

.accordion__toggle-icon--down {
    transform: translateY(1px) rotate(0deg);
}

.collapsed .accordion__toggle-icon--down {
    transform: translateY(1px) rotate(180deg);
}

.accordion__toggle-circle .accordion__toggle-icon{
    font-size: calc(6rem/16);
}

.accordion .card-body{
    padding-top: 0;
}

.accordion__toggle-small{
    width: calc(23rem/16);
    height: calc(23rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: calc(24rem/16);
    padding-right: calc(16rem/16);
}

.collapsed .accordion__toggle-icon__small{
    transform: translateY(1px) rotate(270deg);
}

.accordion__toggle-small .accordion__toggle-icon__small{
    font-size: calc(6rem/16);
}