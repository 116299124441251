.joe-bar {
    position: relative;
    padding:1rem 0;
    border:1px solid #e9e9e9;
    border-width: 1px 0;
}
.joe-bar--checkout {
    padding-top:0;
    border-width: 0 0 1px 0;
}
.joe-bar__title {
    position: relative;
    padding-left:calc(48rem/16);
    font-family: var(--font-default-medium);
    font-size:calc(17rem/16);
    @media screen and (min-width: 768px){
        padding-left:calc(55rem/16);
        font-size:calc(20rem/16);
    }
}
.joe-bar__title > .icon{
    position: absolute;
    left:0;
    top: -.25rem;
    color:var(--color-joe);
    font-size:calc(36rem/16);
}
.joe-bar__oes {
    position: relative;
    padding-right:calc(26rem/16);
    font-size:calc(12rem/16);
}
.joe-bar__oes > .icon{
    position: absolute;
    right:0;
    top: calc(1rem/16);
    color:var(--color-joe);
    font-size:calc(20rem/16);
}
.joe-bar__oes-txt {
    margin-right:.125rem;
    color:var(--color-dark-grey);
}
.joe-bar__oes-nr {
    font-family: var(--font-default-medium);
    font-size:calc(15rem/16);
    @media screen and (min-width: 768px){
        font-size:calc(17rem/16);
    }
}
.joe-bar__oes-nr--shimmer {
    animation: oes-shimmer-mobile 4s infinite linear;
    animation-fill-mode: forwards;
    background: #ddd;
    background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
    background-size: 200px 100%;
    height: calc(18rem/16);
    width: calc(42rem/16);
    border-radius: calc(3rem/16);
    border: calc(1rem/16) solid var(--color-grey);
    display: inline-block;
    margin-bottom: calc(-4rem/16);

    @media (min-width: 768px) {
        animation-name: oes-shimmer;
        background-size: 300px 100%;
        height: calc(20rem/16);
    }
}
@keyframes oes-shimmer {
    0% {
        background-position: -300px 0;
    }
    100% {
        background-position: 300px 0;
    }
}
@keyframes oes-shimmer-mobile {
    0% {
        background-position: -200px 0;
    }
    100% {
        background-position: 200px 0;
    }
}

.oe-info {
    position: relative;
    display: inline-block;
    padding:.125rem 1.625rem .125rem .5rem;
    font-size:calc(14rem/16);
    border-radius:10rem;
    background-color: #F6F6F6;
    margin:calc(10rem/16) 0;
}
.oe-info .icon {
    position: absolute;
    right:.25rem;
    top:50%;
    color:var(--color-joe);
    transform: translateY(-50%);
    font-size:calc(16rem/16);
}

.oe-nr {
    font-size:calc(17rem/16);
}
.oe-nr .icon {
    position: relative;
    top:calc(5rem/16);
    color:var(--color-joe);
    font-size:calc(21rem/16);
    margin-left:.25rem;
}

.oe-nr--small {
    font-size:calc(15rem/16);
}
.oe-nr--small .icon {
    top:calc(4rem/16);
    font-size:calc(17rem/16);
    margin-left:.25rem;
}

/* cart */
.joe-cart-benefit {
    display: block;
    position: relative;
    border: 1px solid var(--color-grey);
    border-radius: calc(3rem/16);
    background-color: #F6F6F6;
    font-size:1rem;
    padding:calc(10rem/16) calc(10rem/16) calc(10rem/16) calc(56rem/16);
    margin:calc(10rem/16) 0;
    @media screen and (min-width: 768px){
        padding:calc(12rem/16) calc(12rem/16) calc(12rem/16) calc(56rem/16);
    }
}

.joe-cart-benefit-not-available {
    opacity: .6;
}

.joe-cart-benefit--active {
    background-color: #fff;
}
.js-cart-benefits__item--disabled {
    pointer-events: none;
    opacity: .5;
    cursor: not-allowed;
}
.joe-cart-benefit--checkout {
    cursor:default;
    padding:calc(10rem/16);
    @media screen and (min-width: 768px){
        padding:calc(12rem/16);
    }
}
.joe-cart-benefit__info {
    color:var(--color-dark-grey);
    font-size:calc(12rem/16);
    @media screen and (min-width: 768px){
        font-size:calc(14rem/16);
    }
}
/*.joe-cart-benefit__oes {
    display: none;
}
.joe-cart-benefit--active .joe-cart-benefit__oes {
    display: block;
}*/



.joe-cart-benefit__fake-checkbox {
    position: absolute;
    left: 1.25rem;
    top:50%;
    transform: translateY(-50%);
    border: 1px solid var(--color-default);
    border-radius: 2px;
    background: #FFF;
    width: 1em;
    height: 1em;
}
.joe-cart-benefit--checkout .joe-cart-benefit__fake-checkbox {
    display: none;
}
.joe-cart-benefit__fake-checkbox:before {
    content: var(--icon-check);
    font-family: "iconfont";
    font-size: calc(11rem/16);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--color-default);
    visibility: hidden;
}
.joe-cart-benefit--active .joe-cart-benefit__fake-checkbox {
    background-color: var(--color-primary);
    border-color:transparent;
}
.joe-cart-benefit--active .joe-cart-benefit__fake-checkbox:before {
    visibility: visible;
}