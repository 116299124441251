.product-content-teaser-hori{
    border: 1px solid var(--color-grey);
    border-radius: calc(3rem/16);
    background-color: #fff;
    transition: all 250ms ease;
    position: relative;
}

.product-content-teaser-hori:hover{
    box-shadow: 0 0 40px 0 rgba(42,41,42,0.2);
}

.swiper-container-initialized .product-content-teaser-hori:hover{
    box-shadow: none;
}

.product-content-teaser-hori__img{
    padding: calc(10rem/16);
}

.product-content-teaser-hori__img .embed-responsive{
    overflow: visible;
}

.product-content-teaser-hori__img img{
    transition: transform .25s ease-in-out;
    transform-origin: center;
}

.product-content-teaser-hori:hover .product-content-teaser-hori__img img{
    transform: scale(1.03);
}


.product-content-teaser-hori__body{
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: calc(10rem/16) 0;
}

.product-content-teaser-hori .price-badge__price{
    @media screen and (min-width: 1200px){
        font-size: calc(48rem/16);
    }
    @media screen and (min-width: 768px){
        font-size: calc(40rem/16);
    }
    font-size: calc(36rem/16);
}

.product-content-teaser-hori .price-badge__price-cent {
    font-size:  calc(20rem/16);
    top: calc(-12rem/16);
    @media screen and (min-width: 1200px){
        font-size:  calc(23rem/16);
        top: calc(-18rem/16);
        left: 0;
    }

    @media screen and (min-width: 768px){
        font-size:  calc(20rem/16);
        top: calc(-14rem/16);
        left: 0;
    }
}

.product-content-teaser-hori__content{
    padding-right: calc(25rem/16);
    
    margin-top: calc(40rem/16);
    @media screen and (min-width: 768px){
       margin-top: 23%;
        padding-right: calc(50rem/16);
    }
    @media screen and (min-width: 768px) and (max-width: 1199px){
        margin-top: calc(35rem/16);
    }
}

.product-content-teaser-hori__text{
    font-size: calc(14rem/16);
    margin-top: calc(20rem/16);

    @media screen and (min-width: 768px) and (max-width: 1199px){
        font-size: calc(13rem/16);
        margin-top: calc(10rem/16);
    }
}


.product-content-teaser-hori__title{
    @media screen and (max-width: 767px){
       font-size: calc(13rem/16);
    }

    @media screen and (min-width: 768px) and (max-width: 1199px){
       font-size: calc(20rem/16);
    }
}

.product-content-teaser-hori .price-badge{
    @media screen and (max-width: 767px){
       margin-top: calc(10rem/16);
    }
}