.my-account__store.store-select-wrapper{
    padding-left: calc(15rem/16);
}

.my-account__store .form-control{
    font-size: calc(14rem/16);
}
.my-account__store .store-select__icon{
    font-size: calc(17rem/16);
    margin-right: calc(-6rem/16);
    position: absolute;
    z-index: 1;
    top: calc(15rem/16);
}