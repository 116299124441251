.newsletter-slide{
    margin-top: calc(40rem/16);
    
    @media screen and (min-width: 768px){
       margin-top: calc(80rem/16);
    }
    
    @media screen and (max-width: 767px){
       padding: calc(20rem/16) 0;
        text-align: center;
    }
}
.newsletter-slide.newsletter-slide--no-margin-top{
    margin-top: 0;
}
.newsletter-slide__title{
    text-transform: uppercase;
    margin: 0;

    @media screen and (max-width: 767px){
       font-size: calc(16rem/16);
    }
}