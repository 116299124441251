.price-badge__main{
    padding: calc(5rem/16) calc(5rem/16) calc(3rem/16);
    display: inline-block;

    @media screen and (min-width: 768px){
        padding: calc(7rem/16) calc(7rem/16) calc(5rem/16);
    }
}

.price-badge__main.highlight{
    background-color: var(--color-primary);
}

.price-badge__price{
    font-size: calc(26rem/16);
    color: #EF3340;
    text-shadow: 0 0 4px rgba(0,0,0,0.2);
    font-family: var(--font-block);
    line-height: 1;

    @media screen and (min-width: 768px){
        font-size: calc(32rem/16);
    }
}

.price-badge__price-big{
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
}
.price-badge__price-symbol-big {
    font-weight: 600;
    font-size: 2.4rem;
}

.price-badge__price-cent{
    top: calc(-8rem/16);
    left: calc(2rem/16);
    font-size: calc(16rem/16);
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: white;

    @media screen and (min-width: 768px){
        font-size: calc(18rem/16);
        top: calc(-10rem/16);
        left: calc(3rem/16);
    }
}

.price-badge--big .price-badge__price{
    @media screen and (min-width: 768px){
        font-size: calc(40rem/16);
    }
}

.price-badge--big .price-badge__price-cent {
    @media screen and (min-width: 768px){
        font-size: calc(23rem/16);
        top: calc(-12rem/16);
        left: calc(-8rem/16);
    }
}

.price-badge--small .price-badge__price{
    @media screen and (min-width: 768px){
        font-size: calc(32rem/16);
    }
}

.cart-table-item .price-badge--small .price-badge__price{
    @media screen and (min-width: 768px){
       font-size: calc(27rem/16);
    }
}

.modal .cart-table-item .price-badge__price-cent {
    left: calc(2rem / 16);
}

.price-badge--small .price-badge__reduction{
    @media screen and (min-width: 768px){
        font-size: calc(9.6rem/16);
        padding-left: calc(5rem/16);
    }
}

.price-badge--small .price-badge__reduction .price-badge__reduction-number{
    @media screen and (min-width: 768px){
        font-size: calc(15rem/16);
    }
}

.product-teaser .price-badge__price{
    @media screen and (min-width: 768px) and (max-width: 1199px){
        font-size: calc(26rem/16);
    }
}

.price-badge__price-cent {
    @media screen and (min-width: 768px) and (max-width: 1199px){
        font-size: calc(15rem/16);
        top: calc(-8rem/16);
    }
}


.price-badge__discount{
    font-family: var(--font-block);
    font-size: calc(15rem/16);
    padding: calc(2rem/16) calc(8rem/16);
    -webkit-text-stroke-width: calc(0.4rem/16);
    -webkit-text-stroke-color: #fff;
    text-align: right;
}

.price-badge__discount-old-price{
    position: relative;
}
.price-badge__discount-old-price:after{
    content: '';
    position: absolute;
    left: calc(-1rem/16);
    right: calc(-1rem/16);
    height: .0625rem;
    background-color: #ef3340;
    transform: rotate(-20deg) translateY(-50%);
    top: 50%;
}

.price-badge__reduction{
    font-size: calc(11rem/16);
    font-family: var(--font-block);
    line-height: 1;
    margin-right: 0.6rem;
    margin-bottom: calc(4rem/16);
    padding-left: calc(10rem/16);
}

.product-main .price-badge__reduction,
.price-badge--portal .price-badge__reduction{
    margin-bottom: 0;
    @media screen and (min-width: 768px){
        margin-bottom: calc(6rem/16);
    }
    @media screen and (min-width: 1200px){
        margin-bottom: calc(8rem/16);
    }
}


.price-badge__reduction-number{
    font-size: calc(15rem/16);
    
}
.price-badge__reduction-number-commma{
    font-size: calc(12rem/16);
}


.price-badge{
    width: fit-content;
}

.price-badge__free{
    font-size: calc(18rem/16);
    color: #EF3340;
    text-shadow: 0 0 4px rgba(255,237,0,0.8);
    font-family: var(--font-block);
    line-height: 1;

    @media screen and (min-width: 768px){
        font-size: calc(24rem/16);
    }
}

.price-badge__free--small {
    font-size: calc(14rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(16rem/16);
    }
}