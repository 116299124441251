
/*notifications*/
.alert-danger,
.alert-info,
.alert-success{
    font-size: calc(16rem/16);
    background-color: var(--color-light-grey);
    border-radius: calc(3rem/16);
    font-family: var(--font-default-bold);
    border: none;
    display: flex;
    align-items: center;
    margin: calc(16rem/16) 0;
}

.alert-danger{
    color: var(--color-danger);
}

.alert-info{
    color: var(--color-info);
}

.alert-success{
    color: var(--color-success);
}

.bg-light-grey .alert-danger,
.alert-danger.alert-danger--bordered{
    border: 1px solid var(--color-danger);
}

.bg-light-grey .alert-info,
.alert-info.alert-info--bordered{
    border: 1px solid var(--color-info);
}

.bg-light-grey .alert-success,
.alert-success.alert-success--bordered{
    border: 1px solid var(--color-success);
}

.alert-danger::before,
.alert-info::before,
.alert-success::before{
    font-family: var(--font-default-bold);
    font-size: calc(14rem/16);
    margin-right: calc(10rem/16);
    width: calc(20rem/16);
    height: calc(20rem/16);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.alert-danger::before{
    content: '!';
    background-color: var(--color-danger);
    color: #FFF;
}

.alert-info::before{
    content: '!';
    background-color: var(--color-info);
    color: #FFF;
}

.alert-success::before{
    content: var(--icon-check);
    font-family: "iconfont";
    font-size: calc(11rem/16);
    background-color: var(--color-success);
    color: #FFF;
}

.alert-danger.alert-dismissible .close,
.alert-info.alert-dismissible .close,
.alert-success.alert-dismissible .close{
    top: 50%;
    right: 1.5%;
    transform: translateY(-53%);
    padding: 0;
    opacity: 1;
}


