.btn{
    @media screen and (max-width: 767px){
       padding: calc(7rem/16) calc(20rem/16);
    }
    @media screen and (max-width: 767px){
       font-size: calc(14rem/16);
    }

}

.btn-no-styling{
    border: none;
    background: none;
    text-align: left;
    padding: 0;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
}

.btn-link-disabled{
    pointer-events: none;
    cursor: not-allowed;
    text-decoration: none;
    opacity: 0.5;
}

.btn-grey{
    background-color: var(--color-dark-grey);
    color: var(--color-default);
}

.btn-grey:hover{
    background-color: #686868;
    color: var(--color-default);
}

.btn-joe{
    color:#fff;
    font-family: var(--font-default-medium);
    border-radius: calc(3rem/16);
    border:0;
    background: linear-gradient(96.44deg, #D6AB53 0%, #E3BF74 100%);
    @media screen and (min-width: 768px){
        padding-left:3rem;
        padding-right:3rem;
    }
}

.btn-joe:hover,
.btn-joe:focus{
    color:#fff;
    background: linear-gradient(96.44deg, #D6AB53 0%, #D6AB53 100%);
}

.btn-white{
    color:var(--color-joe);
    font-family: var(--font-default-medium);
    border-radius: calc(3rem/16);
    border:0;
    background: #fff;
}

.btn-white:hover,
.btn-white:focus{
    color: var(--color-primary);
    background: #000;
}

.btn-secondary.focus,
.btn-secondary:focus{
    color: var(--color-primary);
}

.btn-secondary-hover{
    background-color: var(--color-secondary);
    color: var(--color-primary);
}

.btn-secondary-hover:hover{
    background-color: var(--color-primary);
    color: var(--color-secondary);
}

.btn-default-outline{
    border: 1px solid var(--color-default);
    color: var(--color-default);
}

.btn-default-outline:hover{
    background-color: var(--color-light-grey);
}

.btn-icon-text__icon{
    font-size: calc(17rem/16);
    vertical-align: calc(-2rem/16);
    margin-right: calc(5rem/16);
}

.btn-icon-text__icon--lg{
    font-size: calc(18rem/16);
    vertical-align: calc(-4rem/16);
}


.btn-icon-text__icon--sm{
    font-size: calc(9rem/16);
    vertical-align: 0;
}


.btn.btn-icon{
    width: calc(36rem/16);
    height: calc(46rem/16);
    padding: calc(5rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(20rem/16);

    @media screen and (min-width: 768px){
        width: calc(48rem/16);
        height: calc(48rem/16);
    }
}

.btn.btn-circle{
    width: calc(22rem/16);
    height: calc(22rem/16);
    border-radius: 50%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(8rem/16);
}

.btn.btn-circle+.btn.btn-circle{
    margin-left: calc(5rem/16);
}

.btn-circle.btn-circle--arrow{
    font-size: 0.4rem;
}

.btn-circle.btn-circle--pen{
    font-size: 0.6rem;
}

.btn-circle.btn-circle--link{
    font-size: 0.7rem;
}

.btn.btn-circle-md{
    width: 2rem;
    height: 2rem;
}
.btn.btn-circle-md .icon{
    font-size: calc(14rem/16);
}

.btn.btn-circle-lg{
    width: calc(60rem/16);
    height: calc(60rem/16);
}


.btn.btn-edit{
    font-size: calc(14rem/16);
    display: flex;
    align-items: center;
    padding: calc(5rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(16rem/16);
    }
}

.btn-edit__icon{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-primary);
    width: calc(18rem/16);
    height: calc(18rem/16);
    border-radius: 50%;
    font-size: calc(10rem/16);
    margin-right: calc(8rem/16);

    @media screen and (min-width: 768px){
        width: calc(22rem/16);
        height: calc(22rem/16);
        font-size: calc(12rem/16);

    }
}

.btn-edit__not-edit,
.btn-edit.active .btn-edit__edit{
    display: none;
}

.btn-edit.active .btn-edit__not-edit{
    display: block;
}

.btn-search{
    height: calc(31rem/16);
    padding: calc(5rem/16);
    font-size: calc(14rem/16);
    @media screen and (max-width: 767px){
       padding: calc(5rem/16) calc(10rem/16);
    }
}

.btn-play {
    position:absolute;
    left:50%;
    top:50%;
    z-index:10;
    transform:translateX(-50%) translateY(-50%);
}
.btn-play.btn-lg .icon, .btn-play.btn-circle-lg .icon {
    font-size:1.5rem;
}

.btn.btn-arrow{
    position: relative;
    padding-left: calc(55rem/16);
    transition: all 150ms ease;
}

.btn.btn-arrow:hover{
    padding-left: calc(70rem/16);
}

.btn.btn-arrow .btn-arrow__arrow{
    position: absolute;
    left: calc(37rem/16);
    top: calc(20rem/16);
    
    @media screen and (min-width: 768px){
        top: calc(24rem/16);
    }
}

.btn.btn-arrow .btn-arrow__arrow:before{
    content: '';
    top: 50%;
    right: 31px;
    width: 8px;
    height: 8px;
    transform-origin: 50% 50%;
    transform: translate(0, -6px) rotate(45deg);
    box-shadow: inset -1px 1px 0 0 #222;
    border-radius: 0;
    display: block;
    transition: all 150ms ease;
    position: absolute;
    left: -7px;
}

.btn.btn-arrow:hover .btn-arrow__arrow:before{
    left: 0;
}


.btn.btn-arrow .btn-arrow__arrow:after{
    content: '';
    position: absolute;
    top: 2px;
    right: -0.5px;
    width: 16px;
    height: 1px;
    transform: translateY(-5px);
    background-color: #222;
    transition: all 150ms ease;
    transform-origin: center;
    
    @media screen and (max-width: 767px){
       top: 2.5px;
    }
}

.btn.btn-arrow:hover .btn-arrow__arrow:after{
    width: 25px;
    right: -7.5px;
}

.btn-border{
    border: 1px solid #efefef;
}
.btn-border:hover{
    border-color: var(--color-primary);
}