.cart-item-list__header {
    display: none;
    
    @media screen and (min-width: 768px) {
        display: grid;
        grid-template-columns: 5.8fr 2.2fr 1.3fr 2.5fr;
        gap: calc(20rem/16);
        font-family: var(--font-default-medium);
        padding: 0 calc(16rem/16);
        margin-bottom: calc(8rem/16);
    }
}

.cart-item-list__item + .cart-item-list__item {
    margin-top: calc(8rem/16);
}

.cart-item-list__headline--amount {
    @media (min-width: 768px) {
        margin-left: calc(40rem/16);
    }
}