
.subfooter__content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media screen and (max-width: 767px) {
        flex-wrap: nowrap;
        overflow-x: scroll;
    }
}

