@font-face {
    font-family: 'Swiss-Light';
    src: url('/static/fonts/Swiss721BT-Light.woff2') format('woff2'),
    url('/static/fonts/Swiss721BT-Light.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Swiss-Medium';
    src: url('/static/fonts/Swiss721BT-Medium.woff2') format('woff2'),
    url('/static/fonts/Swiss721BT-Medium.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}


@font-face {
    font-family: 'BlockBertholdBQ-Italic';
    src: url('/static/fonts/BlockBertholdBQ-Italic.woff2') format('woff2'),
    url('/static/fonts/BlockBertholdBQ-Italic.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}
