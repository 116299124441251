.lg-backdrop{
    z-index: 2147483646;
    background-color: rgba(0, 0, 0, 0.75);
}

.lg-outer{
    z-index: 2147483646;
}

.lightbox-img{
    position: relative;
}

.lightbox-img img{
    border-radius: calc(3rem/16);
}

/*.lightbox-img:after{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(225deg,rgba(255,237,0,0),#ffed00);
    opacity: 0;
    z-index: 1;
    transition: all 250ms ease;
}*/

.lightbox-img:hover:after{
    opacity: 0.6;
}

.lightbox-img--no-overlay:after{
    display: none;
}


.lightbox-img__btn{
    position: absolute;
    right: calc(-18rem/16);
    bottom: calc(-18rem/16);
    width: calc(60rem/16);
    height: calc(60rem/16);
    border: 2px solid var(--color-secondary);
    border-radius: 50%;
    content: '';
    opacity: 0;
    transform: scale3d(0.65,0.65,1);
    transform-origin: bottom right;
    transition: opacity 0.35s, transform 0.35s;
    background-color: var(--color-secondary);
    display: block;
    padding: 0;
    color: var(--color-primary);
    z-index: 2;
}

.lightbox-img:hover .lightbox-img__btn{
    opacity: 1;
    transform: scale3d(1,1,1);
}


.lightbox-img--three-sixty__badge{
    width: calc(68rem/16);
    height: calc(68rem/16);
    border-radius: 50%;
    background-color: rgba(255,255,255,0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(22rem/16);
    position: absolute;
    right: calc(-15rem/16);
    top: calc(-15rem/16);
    z-index: 3;
}

.lg-has-iframe .lg-video{
    @media screen and (min-width: 768px){
        width: 740px;
        padding-bottom: 25%;
        margin: 0 auto;
    }
}