.custom-radio{
    position: relative;
    padding-left: calc(20rem/16);
}

.custom-radio:before,
.custom-radio:after {
    content: " ";
    display: table;
}

.custom-radio:after {
     clear: both;
}

.custom-radio>label{
    cursor: pointer;
}

.custom-radio__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.custom-radio__box {
    position: absolute;
    left: 0;
    margin-right: calc(7rem/16);
    overflow: hidden;
    border: calc(1rem/16) solid var(--color-default);
    background: transparent;
    width: calc(16rem/16);
    height: calc(16rem/16);
    margin-top: .1em;
    border-radius: 50%;
}
.custom-radio__box:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 37.5%;
    height: 37.5%;
    border-radius: 50%;
    background: var(--color-default);
    visibility: hidden;
}
.custom-radio__input:checked ~ .custom-radio__box {
    background: var(--color-primary);
}
.custom-radio__input:checked ~ .custom-radio__box:before {
    visibility: visible;
}

.custom-radio__input:focus ~ .custom-radio__box {
    border-color: var(--color-primary);
}
.has-error .custom-radio__box {
    border-color: var(--color-danger)
}
.custom-radio__text {
    display: block;
    overflow: hidden;
}


/* btn style */
.custom-radio--btn-style {
    padding: 0;
    display: inline-block;
}
.custom-radio--btn-style .custom-radio__box {
    display: none;
}
.custom-radio--btn-style .custom-radio__text{
    display: inline-block;
    font-size: 1rem;
    font-family: var(--font-default);
    background: #fff;
    border: 2px solid #E9E9E9;
    border-radius: 3px;
    padding:.625rem 1.25rem;
    min-width:calc(150rem / 16);
}
.custom-radio__input:checked ~ .custom-radio__text {
    border-color: var(--color-primary);
}