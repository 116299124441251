.hazard-box{
    padding: 20px;
    font-size: 16px;
    
    @media screen and (min-width: 768px){
       padding: 40px;
    }
}

.hazard-box__title {
    @media screen and (max-width: 767px){
        font-size: 19px;
    }
}
.hazard-box__title .icon{
    font-size: 22px;
}

.hazard-box h6{
    @media screen and (max-width: 767px){
       font-size: 17px;
    }
}

.hazard-box .list-downloads a{
    text-decoration: underline;
}

.hazard-box .list-downloads a:hover{
    text-decoration: none;
}