.cart-progress {
    border:1px solid var(--color-grey);
    border-radius:calc(3rem/16);
    padding:calc(15rem/16) calc(20rem/16);
}
.cart-progress--yellow .cart-progress__bar-progress {
    background-color:var(--color-primary);
}
.cart-progress--yellow.cart-progress--status-done .cart-progress__bar-icon {
    background-color:var(--color-primary);
}
.cart-progress--yellow.cart-progress--status-done .cart-progress__bar-icon .icon {
    color:var(--color-text-default);
}
.cart-progress--green.cart-progress--status-done .cart-progress__bar-icon {
    background-color:var(--color-green);
}
.cart-progress--green.cart-progress--status-done .cart-progress__bar-icon .icon {
    color:var(--color-white);
}
.cart-progress--align-left .cart-progress__bar-icon, .cart-progress--align-left .cart-progress__bar-text {
    right:-30px;
}
.cart-progress--status-done .cart-progress__end .cart-progress__heading {
    color:var(--color-green);
}
.cart-progress--text .cart-progress__row {
    padding-bottom:calc(15rem/16);
}
.cart-progress__row {
    display:flex;
    align-items:center;
}
.cart-progress__start, .cart-progress__end {
    flex-shrink:0;
}
.cart-progress__end {
    text-align:right;
}
.cart-progress__bar {
    width:100%;
    height:calc(30rem/16);
}
.cart-progress__bar-line {
    height:calc(2rem/16);
    border-radius:calc(2rem/16);
    margin:calc(14rem/16) 0;
    background:var(--color-grey);
}
.cart-progress__bar-progress {
    height:calc(2rem/16);
    border-radius:calc(2rem/16);
    background-color:var(--color-green);
    position:relative;
    transition-property:width, background-color;
    transition-duration:300ms;
    transition-timing-function:cubic-bezier(0.215, 0.610, 0.355, 1.000);
}
.cart-progress__bar-icon {
    width:calc(30rem/16);
    height:calc(30rem/16);
    border-radius:calc(30rem/16);
    background-color:var(--color-grey);
    display:flex;
    align-items:center;
    justify-content:center;
    position:absolute;
    right:0;
    top:50%;
    transform:translateY(-50%);
    transition-property:background-color, right;
    transition-duration:300ms;
    transition-timing-function:cubic-bezier(0.215, 0.610, 0.355, 1.000);
}
.cart-progress__bar-icon .icon {
    font-size:calc(11rem/16);
    color:var(--color-text-default);
    transition-property:color;
    transition-duration:300ms;
    transition-timing-function:cubic-bezier(0.215, 0.610, 0.355, 1.000);
}
.cart-progress__bar-text {
    position:absolute;
    width:calc(150rem/16);
    top:calc(100% + 7px);
    right:0;
    font-size:calc(12rem/16);
    transform:translateX(calc(50% - calc(calc(30rem/16) / 2)));
    text-align:center;
    top:calc(21rem/16);
    height:calc(20rem/16);
    line-height:calc(20rem/16);
}
.cart-progress__bar-text span {
    color:var(--color-green);
}
.cart-progress__heading {
    font-family:var(--font-default-bold);
    font-size:calc(12rem/16);
}
.cart-progress__value {
    font-size:calc(12rem/16);
}
@media screen and (max-width: 767px){
    .cart-progress__row {
        flex-wrap:wrap;
    }
    .cart-progress__start {
        order:1;
        width:50%;
    }
    .cart-progress__bar {
        order:3;
        margin-top:5px;
    }
    .cart-progress__end {
        order:2;
        width:50%;
    }
}
@media screen and (min-width: 768px){
    .cart-progress {
        padding:calc(28rem/16) calc(20rem/16);
    }
    .cart-progress--align-left .cart-progress__bar-icon, .cart-progress--align-left .cart-progress__bar-text {
        right:-50px;
    }
    .cart-progress__heading, .cart-progress__value, .cart-progress__bar-text {
        font-size:calc(14rem/16);
    }
    .cart-progress__bar {
        padding:0 calc(15rem/16);
        height:calc(50rem/16);
    }
    .cart-progress__bar-line {
        height:calc(4rem/16);
        border-radius:calc(4rem/16);
        margin:calc(23rem/16) 0;
    }
    .cart-progress__bar-progress {
        height:calc(4rem/16);
        border-radius:calc(4rem/16);
    }
    .cart-progress__bar-icon {
        width:calc(50rem/16);
        height:calc(50rem/16);
        border-radius:calc(50rem/16);
    }
    .cart-progress__bar-icon .icon {
        font-size:calc(18rem/16);
    }
    .cart-progress__bar-text {
        transform:translateX(calc(50% - calc(calc(50rem/16) / 2)));
        top:calc(32rem/16);
    }
}