.product-topic-teaser{
    text-align: center;
}

.product-topic-teaser__title{
    font-family: var(--font-default);
    font-size: calc(20rem/16);
    margin-top: calc(10rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(24rem/16);
        margin-top: calc(15rem/16);

    }
}
