.list-route li+li{
    margin-top: calc(8rem/16);
}

.list-route li{
    display: flex;
    align-items: center;
}
.store-locator__list-container .custom-scrollbar::-webkit-scrollbar {
    width: 0.5em;
}
.store-locator__list-container .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: var(--color-dark-grey);
    outline: 1px solid var(--color-dark-grey);
}

.store-locator__header-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
    min-height: calc(100rem/16);

    &:has(> .store-locator__header) {
        margin-bottom: 0;
        min-height: fit-content;
    }
}

.store-locator__header {
    background-color: var(--color-primary);
    padding: 1rem;
    border-radius: 0.1875rem;
}
.store-locator__header .btn-search{
    display: flex;
    justify-content: center;
    align-items: center;
}
.store-locator__header-container {
    display: flex;
    align-items: center;
    justify-content: center;
}
.store-locator__body {
    margin-top: calc(16rem/16);
}
.store-locator__map-wrapper {
    min-height: calc(300rem/16);

    &:has(> .store-locator__map-container) {
        min-height: 100%;
    }
}
.store-location__geo-btn {
    height: 2rem;
    background: var(--color-white);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    padding: 0.5rem 0.5rem 0.5rem 0.7rem;
}
.store-locator__header-container .form-control-lg,
.store-locator__header-container .form-control-sm,
.store-locator__header-container .form-control {
    border-radius: 0;
}
.store-locator__header-container .form-control::placeholder{
    color: var(--color-text-default);
}
@media(min-width: 768px){
    .store-locator__list-container {
        height: 70vh;
    }
    .store-locator__gradient {
        position: absolute;
        top: 80%;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgb(255,255,255);
        background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
        z-index: 1;
    }
    .store-locator__header-wrapper {
        min-height: calc(88rem/16);
    }
    .store-locator__header {
        width: 60%;
    }
    .store-locator__header .btn-search{
        /*margin-top: 0rem;*/
        padding: 1.2rem 3rem;
    }
    .store-locator__header-container {
        flex-wrap: nowrap;
    }
    .store-locator__body{
        margin-top: calc(48rem/16);
    }
    .store-locator__map-wrapper {
        height: 100%;
    }
}
@media(max-width: 767px){
    .store-locator__header {
        width: 100%;
    }
    .store-teaser__my-store {
        white-space: nowrap;
        font-size: 0.7rem;
    }
}
#js-store-scroll{
    overflow: auto;
    height: 10rem;
}