.timeline-content__title{
    font-size: calc(24rem/16);
    margin-bottom: calc(20rem/16);
    @media screen and (min-width: 768px){
       margin-bottom: calc(40rem/16);
    }
}
.timeline-img-text-item__img{
    margin-bottom: calc(20rem/16);
}

.timeline-img-text-item__title{
    font-family: var(--font-default-bold);
    font-size: calc(20rem/16);
    line-height: calc(25/20);
    margin-bottom: calc(15rem/16);
}

.timeline-img-text-item__text{
    color: var(--color-text-muted);
    font-size: calc(14rem/16);
    line-height: calc(18/14);
}