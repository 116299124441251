.icon-in-text {
    vertical-align: 0;
    font-size: calc(11rem/16);
    margin-right: calc(5rem/16);
}
.icon-in-text--lg {
    font-size: 1.2em;
    vertical-align: -0.2em;
    margin-right: calc(6rem/16);
}
.icon-in-text--sm {
    font-size: calc(6rem/16);
    vertical-align: 1px;
}

.icon-in-text--md {
    font-size: calc(7rem/16);
    vertical-align: 1px;
}

.icon-in-text-equal{
    width: calc(17rem/16);
    vertical-align: calc(-3rem/16);
}

.icon-in-text.icon-bus{
    font-size: calc(14rem/16);
    vertical-align: calc(-3rem/16);
}

/* Icons specific placement */

/*.icon-in-text.icon-arrow-right {
    vertical-align: -.2em;
}*/