.overlay {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    padding-top: 0;
    transform: translateX(100%);
    transition: transform 180ms ease-out, visibility 0.001ms 180ms, opacity 180ms ease;
    background: #fff;
    z-index: 200;
    overflow: hidden;
    will-change: transform;
    visibility: hidden;
    opacity: 0;
    color: var(--color-default);
}
.overlay.is-open {
    transform: translateX(0);
    visibility: visible;
    opacity: 1;
    transition: transform 180ms ease-out, visibility 0.001ms, opacity 180ms ease;
}
.overlay__header {
    height: calc(60rem/16);
    position: relative;
    z-index: 500;
    background-color: #d4d4d4;
    color:var(--color-default);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(18rem/16);
    flex: none;
    text-align: center;
}
.overlay__header-close{
    position: absolute;
    right: calc(20rem/16);
}

.overlay__content {
    flex: auto;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    font-size: calc(16rem/16);
}
.overlay__container {
    padding: calc(30rem/16) calc(10rem/16);
}
.overlay__footer {
    flex: none;
    background-color: var(--color-default);
    color: #fff;
    text-align: center;
    font-size: calc(14rem/16);
    height: calc(60rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
}
.overlay__container {
    width: 100%;
    margin: 0 auto;
    padding-left: calc(20rem / 16);
    padding-right: calc(20rem / 16);
}


.overlay__footer .btn-icon-text__icon{ 
    font-size: calc(12rem/16);
    vertical-align: calc(-1rem/16);
}

.overlay__footer-btn{
    width: 100%;
    height: 100%;
    text-align: center;
    letter-spacing: 0.5px;
    font-family: var(--font-default-bold);
}

.overlay__footer-results{
    font-size: 14px;
    letter-spacing: 2px;
}