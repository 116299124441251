.address-validation-modal__content {
    text-align: left;
}

.address-validation-modal__close-btn {
    position: absolute;
    top: calc(10rem/16);
    right: calc(10rem/16);
    font-size: calc(16rem/16);

    &:before {
        content: '';
        height: calc(24rem/16);
        width: calc(24rem/16);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    @media (min-width: 768px) {
        top: calc(20rem/16);
        right: calc(20rem/16);
    }
}

.address-validation-modal__suggestion-wrapper {
    padding: calc(12rem/16);
    display: grid;
    gap: calc(48rem/16);
    position: relative;

    &:after {
        content: '';
        width: 100%;
        height: calc(2rem/16);
        background-color: #000;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        pointer-events: none;
    }

    @media (min-width: 768px) {
        margin-top: calc(12rem/16);
        padding: calc(12rem/16) calc(24rem/16);
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto;

        &:after {
            width: calc(2rem/16);
            height: 100%;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

.address-validation-modal__suggestion {
    display: flex;
    flex-direction: column;
    gap: calc(16rem/16);
}