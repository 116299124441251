.custom-checkbox {
    position: relative;
    padding-left: 1.5em;
}

.custom-checkbox:before,
.custom-checkbox:after {
    content: " ";
    display: table;
}

.custom-checkbox:after {
    clear: both;
}

.custom-checkbox>label{
    cursor: pointer;
}

.custom-checkbox__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.custom-checkbox__box {
    position: absolute;
    left: 0;
    margin-right: calc(7rem/16);
    overflow: hidden;
    border: 1px solid var(--color-default);
    border-radius: 2px;
    background: #FFF;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
}
.custom-checkbox__box:before {
    content: var(--icon-check);
    font-family: "iconfont";
    font-size: calc(11rem/16);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--color-default);
    background-color: var(--color-primary);
    visibility: hidden;

    @media screen and (min-width: 768px){

    }
}

.custom-checkbox__input:checked ~ .custom-checkbox__box:before {
    visibility: visible;
}
.custom-checkbox__input:checked ~ .custom-checkbox__box {
    background-color: var(--color-primary);
    border: 1px solid var(--color-primary)              ;
}

.custom-checkbox__input:focus ~ .custom-checkbox__box {
    border-color: var(--color-dark-grey);
}
.has-error .custom-checkbox__box {
    border-color: var(--color-danger)
}
.custom-checkbox__text {
    display: block;
    overflow: hidden;
}

.custom-checkbox__text a{
    text-decoration: underline;
}

.checkbox-image{
    pointer-events: none;
}

.checkbox-image.is-removed{
    opacity: 0.3;
}

.checkbox-image.is-removed .icon-bin:before{
    content: var(--icon-addthis);
    font-size: calc(12rem/16);
}

.checkbox-image.is-removed .checkbox-image__box{
    color: var(--color-default);
}

.checkbox-image{
    display: inline-block;
    position: relative;
    border: 1px solid var(--color-grey);
    border-radius: calc(3rem/16);
    padding: 7px;
    cursor: pointer;
}

.checkbox-image span{
    cursor: pointer;
}

.checkbox-image__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.checkbox-image__box{
    cursor: pointer;
    width: 25px;
    height: 25px;
    border: 1px solid var(--color-grey);
    border-radius: 3px;
    color: var(--color-danger);
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: calc(-18rem/16);
    top: calc(-1rem/16);
    pointer-events: auto;
}

.checkbox-image--document .checkbox-image__box{
    right: calc(-25rem/16);
}

.checkbox-image__img{
    max-height: calc(65rem/16);
    width: auto;
}

.checkbox-image.list-inline-item:not(:last-child){
    margin-right: calc(25rem/16);
}

.custom-checkbox .form-errors{
    left: 0;
    bottom: calc(-5rem/16);
}