.cart-sidebox__pricing {
    margin-bottom: calc(40rem/16);
    padding-left: 0;
    list-style: none;

    @media screen and (min-width: 768px) {
        padding: calc(32rem/16);
        border: var(--color-grey) solid calc(1rem/16);
        border-radius: calc(3rem/16);
        margin-bottom: calc(20rem/16);
    }
}

.cart-sidebox__pricing-item {
    display: flex;
    justify-content: space-between;
    gap: calc(8rem/16);
    font-family: var(--font-default);
    font-size: calc(14rem/16);
    line-height: calc(21/14);
}

.cart-sidebox__pricing-item + .cart-sidebox__pricing-item {
    margin-top: calc(12rem/16);
}

.cart-sidebox__pricing-item:last-child {
    margin-top: calc(18rem/16);
}

.cart-sidebox__pricing-item--border {
    border-bottom: var(--color-grey) solid calc(1rem/16);
    padding-bottom: calc(12rem/16);
}

.cart-sidebox__gross-price {
    font-family: var(--font-default-medium);
    font-size: calc(18rem/16);
    line-height: calc(22/18);
}

.cart-sidebox__info {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: calc(8rem/16);
    margin-top: calc(10rem/16);
    font-family: var(--font-default);
    font-size: calc(14rem/16);
    line-height: calc(21/14);
    color: var(--color-dark-grey);

    @media screen and (min-width: 768px) {
        margin-top: calc(20rem/16);
        font-size: calc(16rem/16);
        line-height: calc(27/16);
    }
}

.cart-sidebox__info .icon {
    font-size: calc(24rem/16);
    line-height: 1;
}