.store-teaser-list-overflow{
    overflow-y: auto;
    overflow-x: hidden;
}

.store-teaser-list-container{
    position: relative;
    height: 100%;
    padding: calc(10rem/16) 0;
    border-radius: 3px;
    background-color: #fff;

    @media screen and (min-width: 768px){
        padding: calc(20rem/16) 0;
    }
}

.store-teaser-list-container:after{
    content: '';
    position: absolute;
    right: 0;
    width: 20px;
    top: 0;
    bottom: 0;
    border-top-right-radius: 3px;
    border-bottom-left-radius: 3px;
    background: #fff;
    transform: translateX(100%);
    z-index: 1;
}

.store-teaser-list{
    position: relative;
    background-color: #fff;
    padding: 0 calc(10rem/16);

    @media screen and (min-width: 768px){
        padding: 0 calc(20rem/16);
    }
}

.store-teaser{
    border: 1px solid var(--color-light-grey);
    border-radius: calc(3rem/16);
    padding: 1rem;
    position: relative;
    margin-right: calc(10rem/16);

    @media screen and (min-width: 768px){
        padding: 1rem;
    }
}

.store-teaser.is-active{
    border-color: var(--color-default);
}

.store-teaser__title{
    @media screen and (max-width: 767px){
       font-size: calc(14rem/16);
    }
}

.store-teaser__address{
    line-height: calc(16/14);
}

.store-teaser__distance{
    font-family: var(--font-default-bold);
    line-height: calc(16/14);
    padding: calc(2rem/16) calc(10rem/16);
    background-color: var(--color-primary);
    border-radius: calc(3rem/16);
    position: absolute;
    right: 0;
    top: 0;
}

.store-teaser__my-store{
    font-family: var(--font-default-bold);
    color: var(--color-default);
    background-color: var(--color-primary);
    border: none;
    padding: calc(6rem/16) calc(10rem/16) ;
    text-transform: uppercase;
    @media screen and (max-width: 767px){
       margin-top: calc(10rem/16);
        display: inline-block;
    }
}

button.store-teaser__my-store:hover{
    text-decoration: none;
}

.store-teaser__my-store .icon{
    vertical-align: calc(-2rem/16);
}
.store-teaser__detail {
    display: flex;
    align-items: center;
    font-family: var(--font-default-bold);
    margin-top: calc(16rem/16);


    @media screen and (min-width: 769px) {
        margin-top:0;
        justify-content: flex-end;
    }
}
.store-teaser__address-item {
    display: flex;
    align-items: center;
    font-family: var(--font-default-bold);
    font-size: 0.8rem;
}
.store-teaser__address-item .icon{
    font-size: 1.2rem;
}
.store-teaser__address-item .icon-phone-store{
    font-size: 1rem;
}
.store-teaser__detail .icon {
    font-size: 0.7rem;
}
@media(max-width: 768px){
    .store-teaser-list {
        margin-top: 1rem;
    }
}
