.btn.delivery-form__remove{
    position: relative;
    margin-left: calc(10rem/16);
    font-size: calc(14rem/16);
    padding: calc(5rem/16);
    text-decoration: underline;

    @media screen and (min-width: 768px){
        margin-left: calc(20rem/16);
    }
}

.delivery-form__remove .icon{
    font-size: calc(15rem/16);
}

.delivery-form__title{
    display: flex;
    align-items: center;
    font-family: var(--font-default-bold);
    font-size: calc(16rem/16);
    white-space: nowrap;
}

.delivery-form__add .icon{
    font-size: calc(11rem/16);
    vertical-align: 0;
}