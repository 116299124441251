.tab-pane{
    padding: calc(20rem/16);
    @media screen and (min-width: 768px){
        padding: calc(40rem/16);
    }
}

.nav-tabs{
    border: none;
}

.nav-tabs .nav-link{
    border: none;
    padding: calc(20rem/16) calc(10rem/16) calc(5rem/16);
    font-size: calc(14rem/16);
    font-family: var(--font-default-bold);
    text-transform: uppercase;
    position: relative;
    overflow: hidden;

    @media screen and (min-width: 768px){
        padding: calc(20rem/16) calc(30rem/16) calc(5rem/16);
    }
}

.nav-tabs .nav-item:first-child .nav-link {
    padding-left: calc(20rem / 16);

    @media screen and (min-width: 768px){
        padding-left: calc(40rem / 16);
    }
}

.nav-tabs.nav-tabs--scroll-mobile .nav-item:first-child .nav-link{
    @media screen and (max-width: 767px){
       padding-left: 0;
    }
}

.nav-tabs .nav-link:after{
    content: '';
    position: absolute;
    height: calc(3rem/16);
    left: calc(10rem/16);
    right: calc(10rem/16);
    bottom: 0;
    background-color: var(--color-primary);
    transform: translateY(100%);
    transition: all 250ms ease;

    @media screen and (min-width: 768px){
        left: calc(30rem/16);
        right: calc(30rem/16);
    }
}

.nav-tabs.nav-tabs--scroll-mobile .nav-item:first-child .nav-link:after {
    left: 0;
    @media screen and (min-width: 768px) {
        left: calc(40rem / 16);
    }
}


.nav-tabs .nav-link.active:after,
.nav-tabs .nav-link:hover:after{
    transform: translateY(0);
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active{
    background-color: transparent;
}

@media screen and (max-width: 767px) {

    .nav-tabs--scroll-mobile {
        overflow-x: auto;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
        display: block;
        padding: 1.25rem;
    }

    .nav-tabs--scroll-mobile .nav-item{
        display: inline-block;
    }

    .nav-tabs--scroll-mobile .nav-link{
        white-space: nowrap;
    }
}

.nav-link__number{
    color: #d4d4d4;
    border: 1px solid #d4d4d4;
    border-radius: calc(3rem/16);
    min-width: calc(22rem/16);
    height: calc(18rem/16);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-default);
    margin-left: calc(3rem/16);
    font-size: calc(11rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(13rem/16);
        min-width: calc(24rem/16);
        height: calc(22rem/16);
    }
}

.nav-tabs.nav-tabs--with-numbers .nav-link:after{
    height: calc(3rem/16);
}


.nav-tabs.nav-tabs--with-numbers .nav-link{
    padding: calc(20rem/16) calc(10rem/16) calc(6rem/16);

    @media screen and (min-width: 768px){
        padding: calc(20rem/16) calc(30rem/16) calc(6rem/16);
    }
}

/* nav tabs links */

.nav-tabs--links .nav-link{
    text-transform: none;
    font-family: var(--font-default);
    font-size: calc(16rem/16);

}

.nav-tabs--links .nav-link.active{
    font-family: var(--font-default-bold);
}

.nav-tabs.nav-tabs--links .nav-item:first-child .nav-link{
    padding-left: 0;
}

.nav-tabs.nav-tabs--links .nav-item:first-child .nav-link:after{
    left: 0;
}

/* timeline navtabs */

.nav-tabs-timeline{
    background-color: var(--color-primary);
    border-radius: calc(3rem/16);
}

.nav-tabs-timeline .nav-link{
    padding: 0;
    height: calc(40rem/16);
    transition: all 150ms ease;

    @media screen and (min-width: 768px){
        height: calc(60rem/16);
    }
}

/* scrolling container */
.nav-tabs-container {
    overflow: hidden;
    padding: 0 calc(35rem/16);
}
/*.nav-tabs-container:before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: calc(3rem / 16);
    left: calc(4rem / 16);
    right: calc(4rem / 16);
    background: var(--color-grey);
    z-index: -1;

    @media screen and (min-width: 768px) {
        height: calc(5rem / 16);
    }
}*/
.nav-tabs-container__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    font-size: calc(12rem / 16);
    z-index: 1;

    height: calc(40rem/16);

    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(35rem/16);
    cursor: pointer;

    @media screen and (min-width: 768px) {
        font-size: calc(12rem / 16);
        height: calc(60rem/16);
    }
}

.nav-tabs-container__arrow.hiding{
    opacity: 0.3;
}

.nav-tabs-container__arrow--right {
    left: auto;
    right: 0;
}

/* scrolling tabs */
.nav-tabs-scrolling {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    justify-content: space-between;
    align-items: center;
    line-height: 1.6;

    @media (hover: none) and (pointer:coarse) {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}
.nav-tabs-scrolling .nav-item {
    flex: 1 0 8%;

    @media screen and (min-width: 1200px){
        flex: 1 0 8%;
        max-width: 8%;
    }
}

.nav-tabs.nav-tabs-scrolling .nav-item:first-child .nav-link{
    padding: calc(10rem/16);
}
.nav-tabs.nav-tabs-scrolling .nav-item .nav-link{

    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;


    @media screen and (max-width: 767px){
        font-size: calc(11rem/16);
    }
}

.nav-tabs.nav-tabs-scrolling .nav-item .nav-link.active,
.nav-tabs.nav-tabs-scrolling .nav-item .nav-link:hover{
    background-color: var(--color-default);
    color: var(--color-primary);
}

.nav-tabs.nav-tabs-scrolling .nav-link:after{
    display: none;
}