.checkout-overview-grid {
    display: flex;
    flex-direction: column;
    gap: calc(8rem/16);

    @media screen and (min-width: 768px) {
        display: grid;
        grid-template-columns: 1.5fr 2fr;
        grid-template-rows: auto 1fr;
        grid-template-areas: 'address address' 'payment aTrust';
        gap: calc(20rem/16);
    }
}

.checkout-overview-grid__address {
    grid-area: address;
}

.checkout-overview-grid__payment {
    grid-area: payment;
}

.checkout-overview-grid__aTrust {
    grid-area: aTrust;
}