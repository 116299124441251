.team-teaser{
    font-size: calc(16rem/16);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.team-teaser__img img{
    border-radius: calc(3rem/16);
}

.team-teaser__title{
    margin-top: calc(10rem/16);
}

.team-teaser__subtitle{
    color: var(--color-dark-grey);
}

.team-teaser__collapse{
    font-size: calc(16rem/16);
    position: relative;
}

.team-teaser__detail-title{
    font-size: calc(20rem/16);
    margin-bottom: calc(10rem/16);
}

.team-teaser__collapse:before {
    content: '';
    position: absolute;
    height: calc(15rem/16);
    left: calc(2rem/16);
    right: calc(2rem/16);
    background: #ffffff;
    top: calc(1rem/16);
    z-index: 1;
}


.team-teaser__link {
    position: relative;
    margin-bottom: 20px;
}

.team-teaser__link__inner::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: -3px;
    right: -4px;
    border-bottom: 3px solid var(--color-primary);
    opacity: 0;
    visibility: hidden;
    transition: bottom 0.25s, opacity 0.2s;
}

.team-teaser__link:hover .team-teaser__link__inner::after {
    bottom: -6px;
    opacity: 1;
    visibility: visible;
}

.team-teaser__link.collapsed .icon{
    transform: rotate(0deg);
}

.team-teaser__link .icon{
    transform: rotate(-90deg);
    transition: transform 0.2s;
}

.team-teaser__link:before {
    content: '';
    position: absolute;
    height: calc(1rem/16);
    width: calc(17rem/16);
    background: white;
    bottom: calc(-22rem/16);
    z-index: 1;
    left: calc(24rem/16);

    @media screen and (min-width: 768px){
        bottom: calc(-21rem/16);
    }
}

.team-teaser__link:after {
    content: '';
    position: absolute;
    width: calc(15rem/16);
    height: calc(15rem/16);
    background-color: #fff;
    border: 1px solid #ddd;
    transform: rotate(45deg);
    bottom: calc(-28rem/16);
    left: calc(25rem/16);
}

.team-teaser__link.collapsed:after{
    display: none;
}


.team-teaser__link.collapsed:before{
    display: none;
}