.vertical-gutter--1 {
    margin-top: -.25rem;
}
.vertical-gutter--1 > .vertical-gutter__item {
    margin-top: .25rem;
}
.vertical-gutter--2 {
    margin-top: -.5rem;
}
.vertical-gutter--2 > .vertical-gutter__item {
    margin-top: .5rem;
}
.vertical-gutter--3 {
    margin-top: -1rem;
}
.vertical-gutter--3 > .vertical-gutter__item {
    margin-top: 1rem;
}
.vertical-gutter--4 {
    margin-top: -1.5rem;
}
.vertical-gutter--4 > .vertical-gutter__item {
    margin-top: 1.5rem;
}
.vertical-gutter--5 {
    margin-top: -3rem;
}
.vertical-gutter--5 > .vertical-gutter__item {
    margin-top: 3rem;
}

.vertical-gutter--10 {
    margin-top: calc(-10rem/16);
}
.vertical-gutter--10 > .vertical-gutter__item {
    margin-top: calc(10rem/16);
}

.vertical-gutter--20 {
    margin-top: calc(-20rem/16);
}
.vertical-gutter--20 > .vertical-gutter__item,
.vertical-gutter--20 > .col {
    margin-top: calc(20rem/16);
}

.vertical-gutter--40 {
    margin-top: calc(-20rem/16);
}
.vertical-gutter--40 > .vertical-gutter__item {
    margin-top: calc(20rem/16);
}

@media screen and (min-width: 768px){
    .vertical-gutter--40 {
        margin-top: calc(-40rem/16);
    }
    .vertical-gutter--40 > .vertical-gutter__item {
        margin-top: calc(40rem/16);
    }
}

.portal-vertical-gutter{
    margin-top: -1.5rem;

    @media screen and (min-width: 768px){
        margin-top: -3rem;
    }
}


.portal-vertical-gutter > .vertical-gutter__item{
    margin-top: 1.5rem;

    @media screen and (min-width: 768px){
        margin-top: 3rem;
    }
}