.payment-group{
    padding: 0;
    margin: 0;
}
.payment-group .form-errors{
    top: calc(-22rem/16);
    bottom: auto;
    left: 0;
}

.list-payments>li+li{
    margin-top: calc(10rem/16);
}

.payment-radio{
    padding: 0;
}

.payment-radio__input {
    position: absolute;
    width: calc(1rem/16);
    height: calc(1rem/16);
    margin: calc(-1rem/16);
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

.payment-radio__box:after {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    position: absolute;
    top: calc(19rem/16);
    left: calc(15rem/16);
    background-color: #fff;
    border: 1px solid var(--color-default);
    content: '';
    display: inline-block;
    visibility: visible;
}
.payment-radio__input:checked ~ .payment-radio__box:after{
    background-color: var(--color-primary);
}
.payment-radio__input:checked ~ .payment-radio__box:before{
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    top: calc(22rem/16);
    left: calc(18rem/16);
    background-color: var(--color-default);
    content: '';
    display: inline-block;
    visibility: visible;
    z-index: 5;
}

.payment-radio__input:checked ~ .payment-radio__body{
    border: 2px solid var(--color-primary);
}

.payment-radio__body{
    background-color: #fff;
    border: 1px solid var(--color-grey);
    border-radius: calc(3rem/16);
    padding: calc(7rem/16) calc(10rem/16) calc(7rem/16) calc(40rem/16);
    height: calc(53rem/16);
    transition: all 250ms ease;
    
    @media screen and (max-width: 767px){
       font-size: calc(12rem/16);
        line-height: 1.2;
    }
}

.payment-radio__body:hover {
    transition: all 250ms ease;
    border: 1px solid var(--color-dark-grey);
}

.payment-radio label{
    display: block;
    cursor: pointer;
}

.sprite-payments {
    background-image: url(/static/img/payments/sprite-payments.png);
    background-repeat: no-repeat;
    display: block;
}

.sprite-payments-dinersclub {
    width: 102px;
    height: 35px;
    background-position: -10px -10px;
}

.sprite-payments-eps {
    width: 57px;
    height: 35px;
    background-position: -10px -65px;
}

.sprite-payments-maestro {
    width: 46px;
    height: 35px;
    background-position: -10px -120px;
}

.sprite-payments-mastercard {
    width: 40px;
    height: 22px;
    background-position: 0 -114px;

    @media screen and (max-width: 767px){
        background-size: 200%;
    }
    @media screen and (min-width: 768px){
        width: 49px;
        height: 35px;
        background-position: -10px -175px;

    }
}

.sprite-payments-visa {
    width: 44px;
    height: 20px;
    background-position: 0 -130px;

    @media screen and (max-width: 767px){
        background-size: 135%;
    }

    @media screen and (min-width: 768px){
        width: 79px;
        height: 25px;
        background-position: -10px -275px;
    }

}

.sprite-payments-nachname {
    width: 48px;
    height: 30px;
    background-position: -10px -225px;
}

.sprite-payments-vorauskasse {
    width: 48px;
    height: 30px;
    background-position: -10px -320px;
}

.sprite-payments-santander {
    width: 95px;
    height: 40px;
    background-position: -10px -370px;
}

.sprite-payments-applepay {
    width: 63px;
    height: 40px;
    background-position: -10px -430px;
}

.sprite-payments-paypal {
    width: 102px;
    height: 28px;
    background-position: -10px -488px;
}

.sprite-payments-amex {
    width: 35px;
    height: 35px;
    background-position: -10px -536px;
}

.checkout-payments .payment-radio__input:checked ~ .payment-radio__body{
    border-color: var(--color-default);
}