
.show-more-item{
    display: inline-block;
    position: relative;
}

.show-more .show-more-item{
    overflow: hidden;
}

.show-more .show-more-item:after{
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: calc(40rem/16);
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, #FFFFFF 100%);
}

.show-more-item__btn{
    display: none;
}

.show-more .show-more-item__btn{
    display: inline-block;
}

.show-more-item__btn .icon{
    width: calc(20rem/16);
    height: calc(20rem/16);
    background-color: var(--color-primary);
    border-radius: 50%;
    color: var(--color-text-default);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: calc(5rem/16);
}

