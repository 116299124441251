.product-teaser--content .price-badge__price{
    @media screen and (min-width: 768px){
       font-size: calc(40rem/16);
    }
}

.product-teaser--content .price-badge__price-cent {
    @media screen and (min-width: 768px){
        font-size: calc(23rem/16);
        top: calc(-12rem/16);
        left: calc(3rem/16);
    }
}


/* slider */

.product-content-teaser-swiper{
    position: relative;
    margin-right: 0;
}

.product-content-teaser-swiper .product-teaser{
    @media screen and (min-width: 768px){
        max-width: calc(340rem/16);
    }
}

.modal .product-content-teaser-swiper .swiper-slide+.swiper-slide{
    display: block;
}

.product-content-teaser-swiper .swiper-container{
    padding-bottom: calc(30rem/16);
/*    padding-right: 30px;
    padding-left: 30px;
!*    margin: 0 -30px;*!*/

    @media screen and (min-width: 768px){
        padding-bottom: calc(40rem/16);
    }
}

.product-content-teaser-swiper .product-content-teaser{
    @media screen and (max-width: 767px){
        height: 100%;
    }
}

.product-content-teaser-swiper .swiper-button{
    @media screen and (max-width: 767px){
        top: calc(95rem/16);
    }
}

.product-content-teaser-swiper .swiper-button--next{
    @media screen and (min-width: 1600px){
       right: calc(-26rem/16);
    }
}

.product-content-teaser-swiper .swiper-button--prev{
    @media screen and (min-width: 1600px){
       left: calc(-40rem/16);
    }
}

/* portal price*/
.product-teaser .price-badge--portal .price-badge__price{
    font-size: calc(36rem/16);
    @media screen and (min-width: 768px){
        font-size: calc(40rem/16);
    }
    @media screen and (min-width: 1200px){
        font-size: calc(48rem/16);
    }
}

.price-badge--portal .price-badge__price-cent {
    font-size:  calc(20rem/16);
    top: calc(-12rem/16);
    @media screen and (min-width: 1200px){
        font-size:  calc(23rem/16);
        top: calc(-18rem/16);
        left: calc(3rem/16);
    }

    @media screen and (min-width: 768px){
        font-size:  calc(20rem/16);
        top: calc(-14rem/16);
        left: calc(3rem/16);
    }
}