.show-more__container {
    overflow: hidden;
}

.show-more__btn {
    display: flex;
    gap: calc(8rem/16);
    align-items: center;
    font-family: var(--font-default-bold);
    font-size: calc(14rem/16);
    line-height: calc(24/14);
    margin-top: calc(12rem/16);

    @media (min-width: 768px) {
        margin-top: calc(16rem/16);
        font-size: calc(16rem/16);
        line-height: calc(24/16);
    }
}

.show-more__btn .icon {
    font-size: calc(10rem/16);
}

.show-more__wrapper:not(.show-more) .show-more__btn .icon {
    transform: rotate(180deg);
}

.show-more__btn-more {
    display: none;
}

.show-more__wrapper.show-more .show-more__btn-more {
    display: block;
}

.show-more__btn-less {
    display: block;
}

.show-more__wrapper.show-more .show-more__btn-less {
    display: none;
}