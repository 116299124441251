.video-js .vjs-big-play-button{
    width: calc(60rem/16);
    height: calc(60rem/16);
    border-radius: 50%;
    background-color: var(--color-primary);
    color: #fff;
    border-color: var(--color-primary);
    font-size: calc(37rem/16);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before{
    display: flex;
    align-items: center;
    justify-content: center;
}

.video-js .vjs-big-play-button:focus,
.video-js:hover .vjs-big-play-button{
    background-color: var(--color-primary);
}