.tt-menu{
    background-color: #fff;
    border: 1px solid var(--color-grey);
    border-top: none;

    padding: calc(12rem/16) calc(15rem/16) calc(15rem/16);


    @media screen and (min-width: 1200px){
        padding: calc(15rem/16) calc(20rem/16) calc(20rem/16);
    }
}

.tt-selectable.tt-cursor{
    color: var(--color-dark-grey);
}

.tt-dataset+.tt-dataset{
    margin-top: calc(15rem/16);
}

.typeahead-heading {
    padding: calc(5rem/16) calc(0rem/16);
    @media screen and (min-width: 1200px){
        padding: calc(5rem/16) calc(15rem/16);
    }
}

.typeahead-heading__icon{
    @media screen and (min-width: 768px) and (max-width: 1199px){
       display: none;
    }
}

.typeahead-suggestion{
    @media screen and (min-width: 768px) and (max-width: 1199px){
        padding-left: calc(10rem/16);
    }
}

.typeahead-suggestion+.typeahead-suggestion{
    margin-top: calc(5rem/16);
}

.typeahead-suggestion__text{
    font-size: 12px;
}

@media screen and (max-width: 767px){
    .typeahead-heading {
        padding: calc(5rem/16) 0 calc(5rem/16) calc(5rem/16);
    }
    
    .typeahead-suggestion {
        padding: calc(2rem/16) 0 calc(2rem/16) calc(5rem/16);
    }
}

.typeahead-heading__icon{
    display: inline-block;
    margin-right: calc(5rem/16);
    vertical-align: calc(-1rem/16);
}