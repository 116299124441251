/* Application Steps */

.application-steps{
    margin: calc(20rem/16) 0;

    @media screen and (min-width: 768px){
        margin: calc(40rem/16) 0;
    }

    @media screen and (max-width: 767px){
        display: flex;
        justify-content: center;
    }
}

.application-steps>li{
    @media screen and (max-width: 767px){
        flex-basis: 0;
        flex-grow: 1;
    }
}

.application-steps-item{
    text-align: center;
    position: relative;
    @media screen and (min-width: 768px){
       width: calc(250rem/16);
    }
}

.application-steps-item.first{
    margin-left: 0;

    @media screen and (min-width: 768px){
        margin-left: calc(-75rem/16);
    }
}

.application-steps>li:not(:last-child) .application-steps-item:after{
    content: '';
    position: absolute;
    background-color: var(--color-grey);
    height: calc(4rem/16);
    width: calc(100% - 14px);
    top: calc(18rem/16);
    left: calc(20rem/16);
    transform: translateX(50%);
}

.application-steps-item__circle{
    width: calc(40rem/16);
    height: calc(40rem/16);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(16rem/16);
    color: var(--color-dark-grey);
    background-color: var(--color-grey);
    font-family: var(--font-default-bold);
    margin: 0 auto;
    position: relative;
    z-index: 1;
}
.application-steps__prev {
    position: absolute;
    inset: 0;
    z-index: 3;
    cursor: pointer;
}
.application-steps-item__icon{
    display: none;
}

.application-steps-item__text{
    font-size: calc(12rem/16);
    margin-top: calc(5rem/16);
}

.is-complete .application-steps-item__circle{
    background-color: var(--color-primary);
    color: var(--color-secondary);
}

.is-complete .application-steps-item__number{
    display: none;
}

.is-complete .application-steps-item__icon{
    display: block;
}

 .application-steps>li.is-complete .application-steps-item:after{
    background-color: var(--color-primary);
}

.is-active .application-steps-item__circle{
    background-color: var(--color-secondary);
    color: var(--color-primary);
}
