.warning {
  display: inline-block;
  background-repeat: no-repeat;
  background-image: url(/static/img/warnings/warnings-sprite.png);
}
.warning-corrosion {
    width: 48px;
    height: 48px;
    background-position: 0 0;
}
.warning-environment {
    width: 48px;
    height: 48px;
    background-position: -58px 0;
}
.warning-exclamation_mark {
    width: 48px;
    height: 48px;
    background-position: -116px 0;
}
.warning-exploding_bomb {
    width: 48px;
    height: 48px;
    background-position: -174px 0;
}
.warning-flame {
    width: 48px;
    height: 48px;
    background-position: -232px 0;
}
.warning-flame_circle {
    width: 48px;
    height: 48px;
    background-position: -290px 0;
}
.warning-gas_cylinder {
    width: 48px;
    height: 48px;
    background-position: -348px 0;
}
.warning-health_hazard {
    width: 48px;
    height: 48px;
    background-position: -406px 0;
}
.warning-skull_crossbones {
    width: 48px;
    height: 48px;
    background-position: -464px 0;
}
.warning-waste_bin {
    width: 48px;
    height: 48px;
    background-position: -522px 0;
}
