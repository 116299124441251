.wysiwyg{
    line-height: calc(24/14);
    font-size: calc(16rem/16);
}

.wysiwyg > p,
.wysiwyg > ol,
.wysiwyg > ul,
.wysiwyg > table {
    margin-bottom: calc(15rem/16);
}
.wysiwyg > p:last-child,
.wysiwyg > ol:last-child,
.wysiwyg > ul:last-child,
.wysiwyg > table:last-child {
    margin-bottom: 0;
}
.wysiwyg img {
    max-width: 100%;
    height: auto !important; /*override inline style from wysiwyg*/
}
.wysiwyg a {
    color: #000;
    text-decoration: underline;
}
.wysiwyg a:hover,
.wysiwyg a:focus,
.wysiwyg a:active {
    color: #000;
    text-decoration: underline;
}

.wysiwyg ul:not(.list-unstyled){
    padding-left: calc(18rem/16);
}

.wysiwyg-with-medium__media{
    @media screen and (min-width: 768px){
       height: 100%;
    }
}

.wysiwyg h1, .wysiwyg h2, .wysiwyg h3, .wysiwyg h4, .wysiwyg h5, .wysiwyg h6,
.wysiwyg .h1, .wysiwyg .h2, .wysiwyg .h3, .wysiwyg .h4, .wysiwyg .h5, .wysiwyg .h6 {
    margin-bottom: calc(7rem/16);
}


.wysiwyg h1 {
    font-size: 1.5625rem;
    font-family: var(--font-default-medium);
}
.wysiwyg h2 {
    font-size: 1.375rem;
    font-family: var(--font-default-medium);
}
.wysiwyg h3 {
    font-size: 1.2rem;
    font-family: var(--font-default);
}

@media screen and (min-width: 768px) {
    .wysiwyg h1 {
        font-size: 2rem;
    }
    .wysiwyg h2 {
        font-size: 1.5rem;
    }
    .wysiwyg h3 {
        font-size: 1.5rem;
    }
}
