.joe-account-teaser {
    font-size:calc(15rem/16);
    padding:calc(20rem/16);
    border: 1px solid var(--color-grey);
    border-radius: calc(3rem/16);

    @media screen and (min-width: 768px){
        height:100%;
        font-size:calc(16rem/16);
        padding:calc(30rem/16);
    }
}
.joe-account-teaser__title {
    position: relative;
    padding-left:calc(55rem/16);
    margin-bottom: calc(20rem/16);
    font-family: var(--font-default-medium);
    font-size:calc(17rem/16);
    @media screen and (min-width: 768px){
        font-size:calc(20rem/16);
    }
}
.joe-account-teaser__title > .icon{
    position: absolute;
    left:0;
    top: -.625rem;
    color:var(--color-joe);
    font-size:calc(40rem/16);
}
.joe-account-teaser__content a:not(.btn) {
    color:var(--color-joe);
    text-decoration: underline;
}
.joe-account-teaser__content a:not(.btn):hover,
.joe-account-teaser__content a:not(.btn):focus {
    text-decoration: none;
}
.joe-account-teaser__subtitle {
    margin-bottom: calc(20rem/16);
}
.joe-account-teaser__small {
    margin-top:calc(20rem/16);
    font-size:calc(12rem/16);
}