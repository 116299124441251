.hero-img-text__title{
    margin-bottom: calc(7rem/16);
    
    @media screen and (max-width: 767px){
       margin-top: calc(15rem/16);
    }
}

.hero-img-text__text{
    font-size: calc(16rem/16);
    color: var(--color-dark-grey);
    margin-top: calc(16rem/16);
    padding-right: calc(8rem/16);
}

.hero-img-text__subtitle{
    font-family: var(--font-default);
    margin-bottom: calc(32rem/16);
}

.hero-img-text__badge{
    margin-top: calc(24rem/16);
    width: 33%;
}