.number-spinner{
    width: calc(110rem/16);
    height: calc(64rem/16);
    background-color: var(--color-grey);
    display: flex;
    align-items: center;
    border-radius: calc(3rem/16);

    @media screen and (min-width: 768px){
       width: calc(100rem/16);
        height: calc(48rem/16);
    }
}

.btn.btn-number-spinner{
    width: calc(30rem/16);
    height: calc(48rem/16);
    padding: 0;
    color: var(--color-white);

    :before {
        content: ' ';
        height: 20px;
        width: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: var(--color-default);
        border-radius: 50%;
        z-index: -1;
        transform: translate(-50%, -43%);
    }

    :disabled:before {
        background-color: var(--color-grey);
    }
}

.number-spinner .form-control{
    font-size: calc(14rem/16);
    text-align: center;
    height: calc(40rem/16);
    border-radius: calc(3rem/16);
    border: none;
    width: 100%;
    color: var(--color-default);
    padding: 0 calc(12rem/16);
    max-width: calc(48rem/16);

    @media screen and (min-width: 768px){
        height: calc(30rem/16);
        padding: 0 .625rem;
        width: 100%;
        max-width: calc(44rem/16);
    }
}

.number-spinner .btn-number-spinner{
    @media screen and (max-width: 767px){
        width: calc(36rem/16);
    }
}

.number-spinner .input-group-prepend .btn-number-spinner{
    @media screen and (max-width: 767px){
        font-size: calc(23rem/16);
    }
}

.number-spinner .input-group-append .btn-number-spinner{
    @media screen and (max-width: 767px){
        font-size: calc(18rem/16);
    }
}

/* Chrome, Safari, Edge, Opera */
.number-spinner input::-webkit-outer-spin-button,
.number-spinner input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.number-spinner input[type=number] {
    -moz-appearance:textfield;
}