.custom-scrollbar::-webkit-scrollbar {
    width: calc(4rem/16);
    border-radius: calc(3rem/16);
}

/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
    background: #e9e9e9;
    border-radius: calc(3rem/16);
}

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
    background: #d4d4d4;
    border-radius: calc(3rem/16);
    transition: all 250ms ease;
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #c0c0c0;
}