.set-item-teaser__wrapper{
    border: 1px solid var(--color-grey);
    border-radius: 3px;
    background-color: #FFF;
    display: flex;
    align-items: center;
    padding: calc(15rem/16) calc(16rem/16);
}

.set-item-teaser__wrapper:hover{
    border: 1px solid var(--color-primary);
    box-shadow: 0 0 0 1px var(--color-primary);
}

.set-item-teaser__img{
}

.set-item-teaser__title{
    font-family: var(--font-default-bold);
}