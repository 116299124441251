.promotion-teaser{
    background-color: var(--color-secondary);
    color: #fff;
    border-radius: calc(3rem/16);
    @media screen and (max-width: 767px){
       margin-bottom: calc(25rem/16);
    }

    transition: all 250ms ease;
}

.promotion-teaser:hover{
    box-shadow: 0 0 40px 0 rgba(42,41,42,0.2);
}

.promotion-teaser__img{
    position: relative;
    top: calc(19rem/16);
    margin-left: calc(14rem/16);
    width: auto;
    @media screen and (min-width: 768px){
        margin-left: calc(30rem/16);
        max-width: calc(240rem/16);

    }
}

.promotion-teaser__img img{
    transition: transform 250ms ease;
    box-shadow: 0 6px 30px #00000091;
}

.promotion-teaser:hover .promotion-teaser__img img{
    transform: translateY(-8px);
}

.promotion-teaser__body{
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: calc(20rem/16) calc(15rem/16) calc(25rem/16) 0;

    @media screen and (min-width: 768px){
        padding: calc(80rem/16) calc(15rem/16) calc(40rem/16) 0;
    }
}

.promotion-teaser__text{
    color: var(--color-dark-grey);
    font-size: calc(12rem/16);
    max-width: calc(175rem/16);
    margin-top: calc(10rem/16);

    @media screen and (min-width: 768px){
        margin-top: calc(20rem/16);
        font-size: calc(14rem/16);
    }

    @media screen and (max-width: 767px){
        line-height: calc(14/12);
    }

}

.promotion-teaser__title{
    font-size: calc(20rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(32rem/16);
    }
}

.promotion-teaser .link-with-arrow{
    color: var(--color-primary);
    position: relative;
    z-index: 1;
    transition: transform 0.15s;
    transform: translateX(0);
    @media screen and (max-width: 767px){
       font-size: calc(14rem/16);
    }
}

.promotion-teaser .link-with-arrow:hover{
    color: #000;
    transform: translateX(13px);
}

.promotion-teaser .link-with-arrow::before {
    content: '';
    background-color: transparent;
    position: absolute;
    top: 51%;
    left: 51%;
    transform: translateX(-50%) translateY(-50%);
    width: 115%;
    height: calc(34rem/16);
    min-height: calc(30rem/16);
    min-width: 120%;
    border-radius: 3px;
    z-index: -1;
    transition: background-color 0.15s;
}

.promotion-teaser .link-with-arrow:hover::before{
    @media (hover: hover) {
        background-color: var(--color-primary);
    }
}