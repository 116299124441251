.article-teaser__img{
    border-radius: 3px;
    overflow: hidden;
}

.article-teaser__category{
    text-transform: uppercase;
    color: var(--color-dark-grey);
    font-family: var(--font-default-bold);
    white-space: nowrap;
}

.article-teaser__category-list .list-inline-item:not(:last-child){
    margin-right: 0;
}

.article-teaser__category-list .list-inline-item{
    margin-left: calc(8rem/16);
}
.article-teaser__top-article{
    font-size: calc(18rem/16);
}

.article-teaser__author{
    font-family: var(--font-default-medium);
    font-size: calc(16rem/16);
    margin-bottom: calc(10rem/16);
}

.article-teaser__title{
    font-size: calc(20rem/16);
    font-family: var(--font-default-medium);
}

.article-teaser__text{
    font-size: calc(16rem/16);
    color: var(--color-dark-grey);
}

.article-teaser__top-bar{
    margin-bottom: calc(20rem/16);
}

.article-teaser__link{
    margin-top: calc(20rem/16);
}

.article-teaser__img{
    position: relative;
    margin-bottom: calc(20rem/16);
}

.article-teaser__img-top{
    position: absolute;
    top: calc(10rem/16);
    left: calc(10rem/16);
    right: calc(10rem/16);
    z-index: 1;
    display: flex;
    justify-content: space-between;
}

.article-teaser__img{
    position: relative;
}

/*.article-teaser__img:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(225deg, rgba(255,237,0,0) 0%, #FFED00 100%);
    z-index: 1;
    opacity: 0;
    transition: all 250ms ease;
}*/

.article-teaser--hover:hover .article-teaser__img:before{
    opacity: 0.6;
}

.article-teaser__img img{
    transition: transform 250ms ease;
}

.article-teaser--hover:hover .article-teaser__img img{
    transform: scale(1.03);
}

/* horizontal */

.article-teaser--horizontal .article-teaser__img{
    @media screen and (min-width: 768px){
        margin-bottom: 0;
    }
}

.article-teaser--horizontal .article-teaser__title{
    margin-bottom: calc(16rem/16);
}

.article-teaser--horizontal .article-teaser__top-bar{
    margin-bottom: calc(20rem/16);

    @media screen and (min-width: 768px){
        margin-bottom: calc(36rem/16);
    }
}

.article-teaser--horizontal .article-teaser__text{
    @media screen and (min-width: 1200px){
        width: 75%;
    }
}

@media screen and (max-width: 767px){
   .swiper-article-teaser .swiper-button{
       top: 20%;
   }
    .article-teaser-row .title-block__title {
        text-align: center;
        font-family: var(--font-default);
        font-size: calc(22rem/16);
        line-height: calc(26/22);
    }
}

.article-teaser--hero{
    margin-bottom: calc(30rem/16);
    
    @media screen and (min-width: 768px){
       margin-bottom: calc(60rem/16);
    }
}