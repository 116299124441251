.ad-teaser{
    border: 1px solid var(--color-grey);
    padding: calc(20rem/16) calc(25rem/16);
    border-radius: calc(3rem/16);
    position: relative;

    @media screen and (min-width: 768px){
        padding: calc(10rem/16) calc(12rem/16);
    }

    @media screen and (min-width: 1200px){
        padding: calc(16rem/16) calc(18rem/16);
    }
}

.ad-teaser--two-col .ad-teaser{
    padding: calc(20rem/16) calc(25rem/16);
}

.ad-teaser::after {
    content: '';
    background-color: var(--color-grey);
    position: absolute;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    top: -1px;
    right: -1px;
    left: -1px;
    bottom: 63%;
    z-index: -1;

    @media screen and (min-width: 768px){
        bottom: 68%;
    }
}

.ad-teaser--two-col .ad-teaser::after{
    bottom: 63%;

    @media screen and (min-width: 768px){
        bottom: 72%;
    }
}

.ad-teaser__body{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ad-teaser__download-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    @media screen and (min-width: 768px){
        display: inline-flex;
    }
}

.ad-teaser__download-btn .icon{
    line-height: calc(21rem/16);
    font-size: calc(22rem/16);
}

.ad-teaser__link{
    margin-right: calc(6rem/16);
    display: block;
    width: 100%;

    @media screen and (min-width: 768px){
        width: auto;
        display: inline-block;
    }
}

.ad-teaser--two-col .ad-teaser__link{
    padding-left: calc(15rem/16);
    padding-right: calc(15rem/16);

    @media screen and (min-width: 768px){
        padding-left: calc(30rem/16);
        padding-right: calc(30rem/16);
    }
}

.ad-teaser__title{
    text-align: center;
    margin-top: calc(22rem/16);
    margin-bottom: calc(16rem/16);

    @media screen and (min-width: 768px){
        text-align: left;
        margin-top: 0;
    }
}

.ad-teaser--two-col .ad-teaser__title{
    font-size: calc(18rem/16);
    margin-top: calc(22rem/16);
    margin-bottom: calc(16rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(22rem/16);
        margin-top: calc(4rem/16);
    }
}

.ad-teaser__img{
    box-shadow: 0 0 6px #c3c3c3;
}

.ad-teaser--three-col .btn{
    padding-right: calc(29rem/16);
    padding-left: calc(29rem/16);

    @media screen and (min-width: 992px){
        padding-right: calc(10rem/16);
        padding-left: calc(10rem/16);
    }

    @media screen and (min-width: 1200px){
        padding-right: calc(14rem/16);
        padding-left: calc(14rem/16);
    }

    @media screen and (min-width: 1400px){
        padding-right: calc(21rem/16);
        padding-left: calc(21rem/16);
    }
}

.ad-teaser--three-col .ad-teaser__body{
    @media screen and (min-width: 992px) and (max-width: 1200px){
        padding: 0;
    }
}