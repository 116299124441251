.job-application-form__error-locations{
    display: flex;
}

.job-application-form__error-locations .form-errors{
    position: relative;
    left: auto;
    bottom: auto;
    margin-left: calc(15rem/16);
}

.job-application-form .form-errors{
    pointer-events: none;
}

.job-application-form__step{
    display: none;
}

.job-application-form__step.is-current{
    display: block;
}

.job-application-form__fieldset-border{
    border: calc(2rem/16) solid #e9e9e9;
    border-radius: calc(3rem/16);
    position: relative;
    height: 100%;
    min-height: calc(280rem/16);
}

.job-application-form__fieldset-border .job-application-form__add-field{
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: 2rem;
    transform: translateY(-50%) translateX(-50%);
    padding: .125rem 1.25rem;
    white-space: nowrap;
}

.job-application-form__arrow-icon{
    font-size: 0.7rem;
}

.job-application-form__btn-white:hover{
    background-color: var(--color-light-grey);
}

.job-application-form__privacy-policy{
    font-size: calc(16rem/16);
}

.job-application-textarea textarea{
    min-height: calc(160rem/16);
}

.job-application-form__calendar-icon,
.profile-form__calendar-icon{
    font-size: 1.2rem;
    margin: 0.4rem 0 0 0;
}

.btn.job-application-form__calendar-button,
.btn.profile-form__calendar-button{
    padding: 0 0.9rem;
    height: calc(100% - 8px);

    @media screen and (min-width: 768px) {
        padding: 0 0.8rem;
    }
}
.input-group__datepicker{
    position: relative;
    width: 100%;
}

.input-group__datepicker input{
    cursor: pointer;
}

.input-group-append__datepicker{
    margin-left: -.125rem;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    z-index: 3;
    pointer-events: none;
}


