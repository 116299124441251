.cart-block+.cart-block {
     margin-top: calc(60rem/16);
 }

.cart-block__title{
    @media screen and (max-width: 767px){
       font-size: calc(16rem/16);
    }
}

@keyframes addToAnim{
    0%   {background-color: var(--color-light-grey); }
    25%  {background-color: var(--color-primary); }
    50%  {background-color: var(--color-light-grey); }
    75%  {background-color: var(--color-primary); }
    100% {background-color: var(--color-light-grey);}
}

.nav-btn__is-animated{
    animation-name: addToAnim;
    animation-duration: 2s;
    animation-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
}

.modal--cart .modal-xl{
    @media (min-width: 576px) {
        max-width: 768px;
    }

    @media (min-width: 992px) {
        max-width: 900px;
    }

    @media (min-width: 1200px) {
        max-width: 1140px;
    }
 }

.cart-empty__img{
    max-width: calc(475rem/16);

}