.form-group{
    @media screen and (min-width: 768px){
       margin-bottom: calc(30rem/16);
    }
}

.form-control{
    @media screen and (max-width: 767px){
       font-size: calc(14rem/16);
    }
}

.form-control--darkgrey{
    color: var(--color-dark-grey);
}

.form-control--no-border{
    border: 0;
}

.form-control--simple label{
    font-family: var(--font-default-bold);
}

.form-control-sm,
select.form-control.form-control-sm:not([size]):not([multiple]){
    height: calc(32rem/16);
}

.label-big{
    font-size: calc(14rem/16);
    font-family: var(--font-default-bold);

    @media screen and (min-width: 768px){
        font-size: calc(20rem/16);
    }
}

.label-strong{
    font-family: var(--font-default-bold);
    font-size: calc(14rem/16);
    margin-bottom: calc(5rem/16);
}

.select {
    position: relative;
}
.select select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}
.select select::-ms-expand {
    display: none;
}
.select:before {
    font-family: "iconfont";
    content: var(--icon-arrow-down);
    color: var(--color-default);
    speak: none;
    position: absolute;
    pointer-events: none;
    font-size: calc(5rem / 16);
    top: 50%;
    right: calc(12rem/16);
    transform: translateY(-50%);
    z-index: 1;
}

.select.custom-select-position:before{
    margin-top: calc(13.5rem/16);
}

select.form-control:not([size]):not([multiple]) {
    height: calc(48rem / 16);
}

.select select.form-control{
    padding-right: calc(30rem/16);
}
.form-group__search .form-control{
    padding-left: calc(35rem/16);
    border-color: var(--color-light-grey);
    height: calc(32rem/16);
}

.form-group__search--lg .form-control{
    height: calc(46rem/16);
}

.form-group__search:before{
    content: var(--icon-magnifier);
    font-family: "iconfont";
    position: absolute;
    left: calc(10rem/16);
    top: 50%;
    transform: translateY(-50%);
    font-size: calc(17rem/16);
    color: var(--color-dark-grey);
}

.has-error .form-control{
    border-color: var(--color-danger);
}


.hide-validation .form-control.is-valid{
    border-color: var(--color-grey)
}

.form-control::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: var(--color-dark-grey);
}
.form-control::-moz-placeholder { /* Firefox 19+ */
    color: var(--color-dark-grey);
}
.form-control:-ms-input-placeholder { /* IE 10+ */
    color: var(--color-dark-grey);
}
.form-control:-moz-placeholder { /* Firefox 18- */
    color: var(--color-dark-grey);
}

.form-control:-internal-autofill-selected{
    background-color: #fff!important;
}

