.swiper-slide+.swiper-slide {
    display: block;
}

.swiper-slide:not(.swiper-slide--no-cursor){
    cursor: pointer;
}


.swiper-container-horizontal>.swiper-scrollbar{
    height: calc(4rem/16);
    background-color: var(--color-light-grey);
    border-radius: calc(2rem/16);
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
}

.swiper-scrollbar-drag{
    background-color: #d4d4d4;
}

.swiper-button{
    width: calc(32rem/16);
    height: calc(32rem/16);
    background-color: var(--color-secondary);
    color: var(--color-primary);
    border-radius: 50%;
    position: absolute;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(12rem/16);
    cursor: pointer;
}

.swiper-button.swiper-button--nav{
    background-color: transparent;
    color: var(--color-dark-grey);
}

.swiper-button--prev{
    left: calc(-16rem/16);
}

.swiper-button--next{
    right: calc(-16rem/16);
}

.swiper-button.swiper-button-disabled{
    display: none;
}

.swiper-same-height .swiper-slide{
    height: auto;
}

/* Teaser Swiper */

.teaser-swiper{
    position: relative;
}

.teaser-swiper::before{
    content: '';
    top: 0;
    bottom: 1.5rem;
    left: 0;
    right: 0;
    position: absolute;
    border-radius: 3px;
    box-shadow: none;
    transition: all 0.25s ease;
}

.teaser-swiper .swiper-wrapper{
    padding-bottom: calc(24rem/16);
}

.teaser-swiper .swiper-button{
    margin-top: calc(-16rem/16);
}

.teaser-swiper:hover::before {
    @media screen and (hover:hover) {
        box-shadow: 0 0 40px 0 rgba(42,41,42,.2);
    }
}
