.cookie-bar {
    display: block;
    top: 50%;
    left: 50%;
    bottom: unset;
    background-color: #fff;
    color: var(--color-text-default);
    padding: 0!important;
    width:95%;
    transform: translateX(-50%) translateY(-50%);

    @media screen and (min-width: 768px){
        width:100%;
        max-width: calc(575rem/16);
        padding: 0 0 1rem 0 !important;
    }
}
.cookie-bar:not(.is-active):after{
    content:'';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: -1;
}

.cookie-bar:before {
    position: fixed;
    content:'';
    top: -100%;
    left: -50vw;
    z-index: -1;
    width: 300vw;
    height: 300vh;
    background-color: #000;
    opacity: 0.5;
}

.cookie-bar.is-active:before{
    z-index: 0;
}

.cookie-bar__headline{
    background-color: var(--color-primary);
    padding: 0.5rem;
    @media screen and (min-width: 768px){
        padding: 1rem 2rem;
    }
}

.cookie-bar__left {
    padding: 0 .5rem;
    @media screen and (min-width: 768px){
        padding: 1rem 2rem 1.5rem;
    }
}
.cookie-bar__detail a {
    padding: 0!important;
}
.cookie-bar__text{
    margin-right: 0!important;
}
.cookie-bar__buttons{
    margin-left: 0!important;
    padding: 0 .5rem;
    text-align: left;
    @media screen and (min-width: 768px){
        padding: 0 2rem;
        text-align: right!important;
    }
}

.is-active .cookie-bar__buttons{
    position: relative;
    z-index: -2;
}

.cookie-bar__buttons button {
    margin-bottom: 0.5rem;
    @media screen and (min-width: 768px){
       margin-bottom: 0;
    }
}

.cookie-bar__settings-btn{
    background-color: #000;
    color: var(--color-primary)!important;
}


.cookie-modal .modal-body.cookie-modal__body{
    padding: calc(15rem/16);

    @media screen and (min-width: 768px){
        padding: calc(35rem/16);
    }
}

.cookie-modal__group-header {
    padding: calc(15rem/16);
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 767px) {
        padding: calc(5rem/16);
    }
}

.cookie-modal__group .cookie-modal__label{
    font-size: calc(19rem/16);

}

.cookie-modal__group-body {
    padding-left: 15px;

    @media screen and (min-width: 768px){
        padding-left: 35px;
    }
}

.cookie-modal .modal-body {
    padding-top: 0;
    @media screen and (max-width: 767px) {
        padding: 0;
    }
}
.cookie-modal__group-header__arrow {
    cursor: pointer;
    @media screen and (max-width: 767px) {
        font-size: calc(12rem/16);
    }
}

.cookie-modal__switch-toggle--is-disabled{
    opacity: 0.5;
}
.btn.cookie-bar__settings-btn{
    border: 4px;
    margin-top: 0;
    margin-left: 0;
    margin-right: calc(15rem/16);
    height: calc(39rem/16);
    color: var(--color-default);
    
    @media screen and (min-width: 768px){
        height: calc(48rem/16);
    }
}

.cookie-bar__buttons{
    @media screen and (max-width: 767px){
       margin-top: calc(15rem/16);
    }
}
/*.btn.cookie-bar__settings-btn{
    background-color: transparent;
    text-decoration: underline;
    color: #fff;
}

.btn.cookie-bar__settings-btn:hover{
    background-color: transparent;
}*/

.btn.cookie-bar__accept-necessary{
    background-color: var(--color-dark-grey);
}

.cookie-bar.hidden{
    display: none;
}