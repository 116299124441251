.compare-item__top {
    min-height: calc(80rem/16);
    margin-top: calc(30rem/16);
}

.compare-item__title{
    @media screen and (max-width: 767px){
       font-size: calc(14rem/16);
    }
}

.btn.compare-item__remove{
    top: calc(10rem/16);
    right: calc(10rem/16);
    position: absolute;
    padding: calc(5rem/16);
}
