.status{
    width: calc(10rem/16);
    height: calc(10rem/16);
    border-radius: 50%;
    background-color: var(--color-default);
    display: inline-block;
    margin-top: calc(-1rem/16);
}

.is-error,
.is-returned{
    background-color: #EF3340;
}

.is-in-progress{
    background-color: #FFED00;
}

.is-new{
    background-color: #2A292A;
}

.is-released,
.is-completed{
    background-color: #04C51E;
}

.state-circle{
    width: calc(20rem/16);
    height: calc(20rem/16);
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: calc(8rem/16);
}

.state-circle.is-done{
    background-color: #04C51E;
}

.state-circle.not-done{
    background-color: var(--color-danger);
}