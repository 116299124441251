.accordion--checkout{
    @media screen and (max-width: 767px){
       font-size: calc(16rem/16);
    }
}
.accordion--checkout .accordion__header-link{
    font-size: calc(16rem/16);
    font-family: var(--font-default-bold);
    
    @media screen and (min-width: 768px){
        font-size: calc(20rem/16);
    }
}

.accordion--checkout .accordion__toggle-circle{
    background-color: #fff;
}

.accordion--checkout>.card+.card{
    margin-top: calc(20rem/16);

    @media screen and (min-width: 768px){
        margin-top: calc(40rem/16);
    }
}


/* Checkout Steps */

.checkout-steps{
    margin-bottom: calc(20rem/16);
    margin-top: calc(-48rem/16);

    @media screen and (min-width: 768px){
        margin-top: calc(-24rem/16);
        margin-bottom: calc(40rem/16);
    }

    @media screen and (max-width: 767px){
       display: flex;
        justify-content: center;
    }
}

.checkout-steps>li{
    @media screen and (max-width: 767px){
        flex-basis: 0;
        flex-grow: 1;
    }
}

.checkout-steps-item{
    text-align: center;
    position: relative;
    @media screen and (min-width: 768px){
       width: calc(250rem/16);
    }
    
    @media screen and (max-width: 767px){
       max-width: calc(120rem/16);
    }
}

.checkout-steps>li:not(:last-child) .checkout-steps-item:after,
.checkout-steps>li:not(:last-child) .checkout-steps-item:before{
    content: '';
    position: absolute;
    background-color: var(--color-grey);
    height: calc(4rem/16);
    width: calc(100% - 14px);
    top: calc(18rem/16);
    left: calc(50% + 20px);
}

.checkout-steps>li:not(:last-child) .checkout-steps-item:after {
    width: 0;
    background-color: var(--color-primary);
}

.checkout-steps-item__circle{
    width: calc(40rem/16);
    height: calc(40rem/16);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(16rem/16);
    color: var(--color-dark-grey);
    background: linear-gradient(to right, var(--color-primary) 50%, var(--color-grey) 50%) bottom right;
    background-size: 200% 100%;
    font-family: var(--font-default-bold);
    margin: 0 auto;
    position: relative;
    z-index: 1;
}

.checkout-steps-item__number{
    color: var(--color-dark-grey);
}

.is-active .checkout-steps-item__number{
    color: inherit;
}

.checkout-steps-item__icon{
    display: none;
}

.checkout-steps-item__text{
    font-size: calc(12rem/16);
    margin-top: calc(5rem/16);
}

.is-complete .checkout-steps-item__circle{
    animation: fillStepCircle 0.25s forwards;
    animation-delay: 0.5s;
    color: var(--color-secondary);
}

.is-complete {
    &:nth-child(2) .checkout-steps-item__circle {
        animation-delay: 1.25s;
    }

    &:nth-child(3) .checkout-steps-item__circle {
        animation-delay: 2s;
    }

    &:nth-child(4) .checkout-steps-item__circle {
        animation-delay: 2.75s;
    }
}

.is-complete .checkout-steps-item__number{
    display: none;
}

.is-complete .checkout-steps-item__icon{
    display: block;
}

.checkout-steps>li.is-complete {
    &:nth-child(1) .checkout-steps-item:after{
        animation: fillStepBar 0.5s forwards;
        animation-delay: 0.75s;
    }

    &:nth-child(2) .checkout-steps-item:after{
        animation: fillStepBar 0.5s forwards;
        animation-delay: 1.5s;
    }

    &:nth-child(3) .checkout-steps-item:after{
        animation: fillStepBar 0.5s forwards;
        animation-delay: 2.25s;
    }
}

@keyframes fillStepBar {
    0% {
        width: 0;
    }
    100% {
        width: calc(100% - 14px);
    }
}

@keyframes fillStepCircle {
    0% {
        background-position: bottom right;
    }
    100% {
        background-position: bottom left;
    }
}

@keyframes fillActiveStepCircle {
    0% {
        background: linear-gradient(to right, var(--color-secondary) 50%, var(--color-grey) 50%) bottom right;
        background-size: 200% 100%;
    }
    75% {
        background: linear-gradient(to right, var(--color-secondary) 50%, var(--color-grey) 50%) bottom left;
        background-size: 200% 100%;
    }
    100% {
        background: linear-gradient(to right, var(--color-secondary) 50%, var(--color-grey) 50%) bottom left;
        background-size: 200% 100%;
        color: var(--color-primary);
    }
}

.list-inline-item {
    &:nth-child(1).is-active .checkout-steps-item__circle{
        animation: fillActiveStepCircle 0.5s forwards;
        animation-delay: 0.5s;
    }
    &:nth-child(2).is-active .checkout-steps-item__circle{
        animation: fillActiveStepCircle 0.5s forwards;
        animation-delay: 1.25s;
    }
    &:nth-child(3).is-active .checkout-steps-item__circle{
        animation: fillActiveStepCircle 0.5s forwards;
        animation-delay: 2s;
    }
    &:nth-child(4).is-active .checkout-steps-item__circle{
        animation: fillActiveStepCircle 0.5s forwards;
        animation-delay: 2.75s;
    }
}

.checkout-cards .card+.card{
    margin-top: calc(20rem/16);

    @media screen and (min-width: 768px){
        margin-top: calc(40rem/16);
    }
}

.checkout-cards .card-body{
    @media screen and (max-width: 767px){
        padding: calc(15rem/16);
    }
}

.checkout-cards .card-lg{
    font-size: calc(14rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(16rem/16);
    }
}

.card-hl{
    @media screen and (max-width: 767px){
        font-size: calc(15rem/16);
    }
}

.summary-total__price{
    font-size: calc(20rem/16);
}

.summary-total{
    padding-top: calc(9rem/16);
    padding-bottom: calc(8rem/16);
    border-top: 1px solid var(--color-grey);
    border-bottom: 1px solid var(--color-grey);
    line-height: 1;
}
.summary-shipping{
    padding-top: calc(9rem/16);
    border-top: 1px solid var(--color-grey);
}

.checkout-address--delivery{
    margin: calc(18rem/16) 0;

    @media screen and (min-width: 768px){
        margin: calc(24rem/16) 0;
    }
}
.checkout-address--delivery.active{
    opacity: 1;
}


.checkout-address__form .form-group{
    margin-bottom: calc(20rem/16);
}

.checkout-address__form--bill{
    display: none;
}

.bill-in-edit .checkout-address__form--bill{
    display: block;
}

.checkout-trust-logo{
    width: calc(60rem/16);
    height: calc(60rem/16);
    margin-right: calc(20rem/16);
}

.checkout-sidebar .cart-product-list{
    @media screen and (min-width: 768px){
        max-height: 41vh;
        overflow-y: auto;
        overflow-x: hidden;
    }
}

.checkout-btn-fixed{
    /* position: fixed;
     bottom: 0;
     left: 0;
     right: 0;*/
    z-index: 10;
    padding: calc(10rem/16) calc(20rem/16);
    background-color: #fff;
}

.checkout-btn-fixed:not(.is-affix){
    display: none;
}

.checkout-address__title {
    line-height: 1.2;
}

.checkout-address__delivery-edit-btn{
    visibility: hidden;
}

.checkout-address--delivery.is-selected .checkout-address__delivery-edit-btn{
    visibility: visible;
}

.checkout-register-banner {
    background-color: var(--color-white);
    padding: calc(20rem/16);
    margin-top: calc(40rem/16);
    margin-bottom: calc(40rem/16);

    @media screen and (min-width: 768px) {
        padding: calc(32rem/16) calc(40rem/16);
    }
}

.checkout-register-banner__title {
    font-size: calc(16rem/16);
    line-height: calc(27/16);
}

.payment-badge {
    background-color: var(--color-white);
    border-radius: calc(3rem/16);
    padding: calc(12rem/16) calc(8rem/16);
}

#registerAddress.collapse.show ~ .register-address-btn__wrapper {
    display: none;
}

.checkout-success-cards {
    padding-bottom: calc(24rem/16);
    border-bottom: var(--color-grey) solid calc(1rem/16);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'card1 card2'
                    'card3 card3'
                    'card4 card4';
    gap: calc(10rem/16);

    @media screen and (min-width: 768px) {
        padding-bottom: calc(32rem/16);
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas: 'card1 card2'
                        'card3 card4';
        gap: calc(20rem/16);
    }

    @media screen and (min-width: 1200px) {
        padding-bottom: calc(40rem/16);
        grid-template-columns: repeat(4, 1fr);
        grid-template-areas: 'card1 card2 card3 card4';
        gap: calc(20rem/16);
    }
}

.checkout-info-card {
    &:nth-child(1) {
        grid-area: card1;
    }

    &:nth-child(2) {
        grid-area: card2;
    }

    &:nth-child(3) {
        grid-area: card3;
    }

    &:nth-child(4) {
        grid-area: card4;
    }
}

.checkout-info-card .card-body {
    @media screen and (max-width: 767px) {
        padding: calc(12rem/16);
    }
}