.my-account-box{
    background-color: var(--color-primary);
    color: var(--color-default);
    border-radius: calc(3rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: calc(30rem/16) calc(20rem/16);
    transition: all 350ms ease;

    @media screen and (min-width: 768px){
        padding: calc(64rem/16) calc(30rem/16);
        height: 100%;
    }
}

.my-account-box__icon{
    font-size: calc(50rem/16);
    margin-bottom: calc(10rem/16);

    @media screen and (min-width: 768px){
        font-size: calc(70rem/16);
    }
}

.my-account-box:hover {
    box-shadow: 0 14px 20px 0 rgba(42,41,42,.15);
    transform: translateY(-7px);
}

/* My account box small */
.my-account-box-small{
    background-color: var(--color-secondary);
    color: var(--color-primary);
    padding: calc(28rem/16) calc(30rem/16);
    font-size: calc(16rem/16);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    transition: all 350ms ease;

    @media screen and (min-width: 768px){
       height: 100%;
    }
}

.my-account-box-small__icon{
    font-size: calc(20rem/16);
    margin-right: calc(8rem/16);
}

.my-account-box-small:hover {
    box-shadow: 0 -8px 20px 0 rgba(42,41,42,.15);
    transform: translateY(7px);
}

/* JÖ Box */
.joe-account-box {
    color:#fff;
    font-size:calc(16rem/16);
    border-radius: calc(3rem/16);
    background: var(--color-joe);
    padding:calc(20rem/16);
    position:relative;
    text-align: center;
    transition: all 350ms ease;
    border: calc(3rem/16) solid var(--color-joe);
    @media screen and (max-width: 767px){
        margin-top: calc(20rem/16);
    }
    @media screen and (min-width: 768px){
        height:100%;
        padding:calc(64rem/16) calc(20rem/16) 0 calc(20rem/16);
    }
    @media screen and (min-width: 1366px){
        padding:calc(64rem/16) calc(32rem/16) 0 calc(32rem/16);
    }
    @media screen and (min-width: 1600px){
        padding:calc(64rem/16) calc(56rem/16) 0 calc(56rem/16);
    }
}
.joe-account-box--connected {
    padding:calc(20rem/16) 0 0 0;
    @media screen and (min-width: 768px){
        padding:calc(64rem/16) 0 0 0;
    }
    @media screen and (min-width: 1366px){
        padding:calc(64rem/16) 0 0 0;
    }
    @media screen and (min-width: 1600px){
        padding:calc(64rem/16) 0 0 0;
    }

}
.joe-account-box:hover {
    box-shadow: 0 14px 20px 0 rgba(42,41,42,.15);
    transform: translateY(-7px);
}

.joe-account-box__icon {
    font-size:calc(45rem/16);
    @media screen and (min-width: 768px){
        font-size:calc(78rem/16);
    }
}
.joe-account-box__title {
    margin: calc(18rem/16) 0 calc(24rem/16) 0;
    @media screen and (min-width: 768px){
        margin: calc(24rem/16) 0 calc(24rem/16) 0;
    }
}
.joe-account-box--connected .joe-account-box__title {
    padding:0 1rem;
}
.joe-account-box--not-connected .joe-account-box__title {
    @media screen and (min-width: 768px) and (max-width: 1199px){
        font-size:calc(18rem/16);
    }
}
.joe-account-box__bottom {
    padding:calc(20rem/16) calc(14rem/16) calc(16rem/16) calc(14rem/16);
    background: #fff;
    border-bottom-left-radius: calc(3rem/16);
    border-bottom-right-radius: calc(3rem/16);
    @media screen and (min-width: 768px){
        position: absolute;
        left:0;
        right:0;
        bottom:0;
        padding:calc(28rem/16) calc(14rem/16);
    }
}
.joe-account-box__oes {
    display: inline-block;
    margin:0 auto;
    position: relative;
    text-align: left;
    padding-left: calc(58rem/16);
    line-height:1;
    font-family: var(--font-default-medium);
    @media screen and (min-width: 768px){
        font-size:calc(20rem/16);
    }
}
.joe-account-box__oes-small {
    font-size:calc(14rem/16);
    font-family: var(--font-default);
    color:var(--color-dark-grey)
}
.joe-account-box__oes .icon {
    position: absolute;
    left:0;
    top:-.25rem;
    font-size:calc(36rem/16);
    @media screen and (min-width: 768px){
        font-size:calc(46rem/16);
    }
}