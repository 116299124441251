:root {
    --color-water: #00a7ea; /* Wasser / Pool, Pumpen */
    --color-kfz: #ec008c; /* KFZ */
    --color-machines: #fff200; /* Geräte und Maschinen / Forst */
    --color-electric: #ed1c24; /* Elektro / Elektrowerkzeug */
    --color-garden: #00a651; /* Garten / Rasenmäher */
    --color-metal-and-house: #2e3192; /* Metall / Bau, Haus & Strom */
    --color-tool: #a3238e; /* Werkzeuge / Handwerkzeug */
    --color-building: #f58220; /* Bau */
    --color-theme-default: #BEC2C6; /* Default */
}

/* Text Color */

.text-water{ color: var(--color-water) }
.text-kfz{ color: var(--color-kfz) }
.text-machines{ color: var(--color-machines) }
.text-electric{ color: var(--color-electric) }
.text-garden{ color: var(--color-garden) }
.text-metal-and-house{ color: var(--color-metal-and-house) }
.text-tool{ color: var(--color-tool) }
.text-building{ color: var(--color-building) }
.text-theme-default{ color: var(--color-theme-default) }


/* Backgrounds */
.bg-water{ background-color: var(--color-water) }
.bg-kfz{ background-color: var(--color-kfz) }
.bg-machines{ background-color: var(--color-machines) }
.bg-electric{ background-color: var(--color-electric) }
.bg-garden{ background-color: var(--color-garden) }
.bg-metal-and-house{ background-color: var(--color-metal-and-house) }
.bg-tool{ background-color: var(--color-tool) }
.bg-building{ background-color: var(--color-building) }
.bg-theme-default{ background-color: var(--color-theme-default) }
