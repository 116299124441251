.content-teaser{
    border-radius: calc(3rem/16);
    overflow: hidden;
    cursor: auto;
}

.content-teaser__body{
    padding: calc(20rem/16) 0;
}

.content-teaser__title{
    margin-bottom: calc(20rem/16);
}

.content-teaser__text{
    font-size: calc(16rem/16);
    line-height: calc(24rem/16);
}

.content-teaser__img{
    position: relative;
}

/*.content-teaser__img:before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(225deg, rgba(255,237,0,0) 0%, #FFED00 100%);
    z-index: 1;
    opacity: 0;
    transition: all 250ms ease;
}*/

.content-teaser__img--without-hover:before {
    background: none;
}

.content-teaser.stretch-link:hover .content-teaser__img:before{
    opacity: 0.6;
}

.content-teaser__img img{
    transition: transform 250ms ease;
}

.content-teaser.stretch-link:hover .content-teaser__img img{
    transform: scale(1.03);
}

/* slider */

.content-teaser-swiper{
    position: relative;
}

.content-teaser-swiper .swiper-button{
    @media screen and (max-width: 767px){
        top: calc(135rem/16);
    }
}

.content-teaser-swiper-big .swiper-button{

    @media screen and (min-width: 768px){
        top: calc(189rem/16);
        transform: none;
    }
}

.content-teaser .link-with-arrow{
    border-radius: calc(3rem/16);
    background-color: transparent;
    padding: calc(12.5rem/16) calc(16rem/16);
    transition: background-color 0.15s ease-in-out, transform 0.15s;
    transform: translateX(-16px);
}

.content-teaser.stretch-link .link-with-arrow:hover{
    @media (hover: hover) {
        background-color: var(--color-primary);
        transform: translateX(0);
    }
}
