.breadcrumb-wrapper{
    padding-top: calc(15rem/16);
    @media screen and (min-width: 768px){
        padding-top: calc(48rem/16);
    }
}
.breadcrumb{
    display: flex;
    margin-top: 0;
    opacity: 1;
    @media screen and (min-width: 768px){
        margin-top: calc(-20rem/16);
    }
    @media screen and (max-width: 767px){
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
    }
}
.breadcrumb-item{
    font-size: calc(12rem/16);
    transition: color 0.2s;
    @media screen and (min-width: 768px){
        font-size: calc(14rem/16);
    }
    @media screen and (max-width: 767px){
        white-space: nowrap;
    }
}

li.breadcrumb-item:hover{
    color: #999999;
}

.breadcrumb-item+.breadcrumb-item:before{
    content: var(--icon-arrow-right);
    font-family: "iconfont";
    font-size: calc(8rem/16);
}

