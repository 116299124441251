.product-detail-swiper{
    border: 1px solid var(--color-grey);
    border-radius: calc(3rem/16);
    padding: calc(25rem/16);
    position: relative;
    
    @media screen and (min-width: 768px){
        padding: calc(70rem/16) calc(70rem/16) calc(40rem/16);
    }
}

.product-detail-swiper__main .swiper-slide {
    width: 100% !important;
}

.product-detail-swiper__nav{
    width: calc(250rem/16);
    margin: 0 auto;
    margin-top: calc(15rem/16);

    @media screen and (min-width: 768px){
        width: calc(300rem/16);
    }
    @media screen and (min-width: 1300px){
        width: calc(470rem/16);
    }
}

.product-detail-swiper__nav .swiper-slide{
    padding: calc(10rem/16);
    border: 1px solid var(--color-grey);
    cursor: pointer;
    display: block;
}

.product-detail-swiper__nav .swiper-slide-thumb-active,
.product-detail-swiper__nav .swiper-slide:hover{
    border-color: var(--color-primary);
}

.product-detail-swiper .swiper-button--next{
    right: calc(-40rem/16);
}

.product-detail-swiper .swiper-button--prev{
    left: calc(-40rem/16);
}

.product-detail-swiper .swiper-pagination{
    left: 50%;
    transform: translateX(-50%);
    
    @media screen and (max-width: 767px){
       bottom: calc(3rem/16);
    }
}

.product-detail-swiper .swiper-pagination-bullet{
    margin: 0 calc(2rem/16);
}

/*.manufacturer-tab{
    display: none;
}

.manufacturer-tab.show{
    display: list-item;
}*/

/*
.product-detail-swiper__iframe-wrapper{
    @media screen and (max-width: 767px){
        width: 710px;
        height: 290px;
        padding: 0;
        overflow: hidden;
    }
}

.product-detail-swiper__iframe{
    @media screen and (max-width: 767px){
        width: 1000px!important;
        height: 860px!important;
        border: 0;

        zoom: 0.6;
        -moz-transform: scale(0.45);
        -moz-transform-origin: 0 0;
        -o-transform: scale(0.45);
        -o-transform-origin: 0 0;
        -webkit-transform: scale(0.45);
        -webkit-transform-origin: 0 0;
    }
}*/
