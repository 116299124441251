.brands__item{
    padding: 1rem;
    border-radius: 3px;
    border: 1px solid #efefef;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: border-color 250ms ease;
    height: calc(65rem/16);

    @media screen and (min-width: 768px){
        max-height: calc(99rem/16);
        height: calc(74rem/16);
    }
}

/*.brands__item img{
    @media screen and (max-width: 767px){
        height: calc(33rem/16);
        width: auto;
    }

}*/

.brands--swiper{
    padding: 0 calc(20rem/16);
}
.brands--swiper.brands--swiper-centered .swiper-slide:last-child{
    margin-right: 0 !important;
}

.brands__item:hover{
    border-color: var(--color-primary);
}

.brands--swiper .brands__item{
    margin: 0 1px;
}