.modal{
    z-index: 2147483649!important; /* because of trust badge */
}

.modal-body__title{
    font-size: calc(18rem/16);
    
    @media screen and (min-width: 768px){
       font-size: calc(30rem/16);
    }
}

.modal-header{
    @media screen and (min-width: 768px){
        padding: calc(20rem/16) calc(40rem/16);
    }
}

.modal-body{
    @media screen and (min-width: 768px){
        padding: calc(30rem/16) calc(40rem/16);
    }
}

.modal--cart .modal-body{
    @media screen and (min-width: 768px){
       padding: calc(30rem/16) calc(60rem/16);
    }
}

.modal--popin .modal-body__close{
    position: absolute;
    top: calc(15rem/16);
    right: calc(20rem/16);
    font-size: calc(16rem/16);
    opacity: 1;
}

.modal--popin .modal-body{
    @media screen and (min-width: 768px){
       padding: 0 calc(40rem/16) calc(40rem/16) calc(40rem/16);
    }
    padding-top: 0;
}

.modal--popin__title{
    background: #ffed00;
    display: block;

    margin: 0 -16px 32px;
    padding: 10px 16px;
    position: relative;

    @media screen and (min-width: 768px){
        margin: 0 -40px 32px;
        padding: 10px 40px;
    }
}

.modal--popin-horizontal .modal-body__close{
    position: absolute;
    @media screen and (min-width: 768px) {
        top: calc(-20rem / 16);
        right: calc(-20rem / 16);
    }
    top: -40px;
    right: 0;
    font-size: calc(10rem/16);
    opacity: 1;
    z-index: 1;
}
.modal--popin-horizontal .modal-body{
    @media screen and (min-width: 768px){
        padding: calc(40rem/16);
    }
    padding: calc(60rem/16) calc(20rem/16) calc(20rem/16) calc(20rem/16);
}
.modal-popin-horizontal__background{
    position: relative;
}
.modal-popin-horizontal__background:before {
    content: "";
    position: absolute;
    background: var(--color-light-grey);
    @media screen and (min-width: 768px){
        inset: calc(-40rem/16) calc(-40rem/16) 0 calc(-40rem/16);
    }
    inset: calc(-60rem/16) calc(-20rem/16) 0 calc(-20rem/16);
}
.modal-popin-horizontal__title-container{
    position: relative;
    z-index: 1;
    @media screen and (min-width: 768px){
        padding: calc(40rem/16);
    }
    padding: calc(20rem/16) 0;
}
.modal--popin-horizontal__title{
    @media screen and (min-width: 768px){
        font-size: calc(32rem/16);
        line-height: calc(39/32);
    }
    font-size: calc(25rem/16);
    line-height: calc(30/25);
    font-family: var(--font-default-medium);
    margin-bottom: calc(8rem/16);
}
.modal--popin-horizontal__subtitle{
    font-size: calc(18rem/16);
    line-height: calc(22/18);
    font-family: var(--font-default);
}
.modal-popin-horizontal__content{
    @media screen and (min-width: 768px) {
        padding: calc(20rem/16) calc(40rem/16) calc(40rem/16) calc(40rem/16);
    }
    padding: calc(20rem/16) 0;
}
.modal-popin-horizontal__img-container {
    position: relative;
}
.modal-popin__badge{
    @media screen and (min-width: 768px) {
        font-size: calc(20rem/16);
        line-height: calc(20/20);
        padding: calc(7rem/16) calc(10rem/16);
    }
    background-color: var(--color-primary);
    text-transform: uppercase;
    font-size: calc(12rem/16);
    line-height: calc(12/12);
    font-family: var(--font-block);
    letter-spacing: 0.1px;
    border-radius: 3px;
    position: absolute;
    padding: calc(4rem/16) calc(6rem/16);
    width: fit-content;
}
.modal-popin-horizontal__badge.modal-popin__badge {
    @media screen and (min-width: 768px) {
        left: calc(40rem / 16);
        bottom: -1rem;
    }
    left: 0;
    bottom: -0.5rem;
    z-index: 9;
}
.modal-popin__img{
    border-radius: 3px;
}

.modal--popin-vertical .modal-body__close{
    position: absolute;
    font-size: calc(10rem/16);
    opacity: 1;
    z-index: 1;

    @media screen and (min-width: 768px) {
        top: calc(20rem / 16);
        right: calc(20rem / 16);
    }

    @media screen and (max-width: 767px) {
        right: 0;
        top: 0;
        background-color: var(--color-white);
        padding: calc(8rem/16);
        border-radius: 2px;
    }
}
.modal--popin-vertical .modal-body{
    padding: 0;
}
.modal-popin-vertical__grid {
    @media screen and (min-width: 768px) {
        display: grid;
        grid-template-columns: 3fr 7fr;
        gap: 40px;
    }
}

.modal-popin-vertical__grid-content {
    @media screen and (min-width: 768px) {
        padding: calc(60rem / 16) calc(128rem / 16) calc(40rem / 16) calc(40rem / 16);
    }
    padding: calc(20rem / 16) calc(24rem / 16) calc(24rem / 16) calc(24rem / 16);
}
.modal-popin-vertical__content-container {
    border-top: 1px solid grey;
    padding-top: 20px;
    margin-top: 20px;
}
.modal-popin-vertical__badge {
    top: calc(20rem / 16);
    left: calc(20rem / 16);
}